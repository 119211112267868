import './shimmer.css'
import { ShimmerThumbnail, ShimmerPostList, ShimmerSimpleGallery, ShimmerPostDetails, ShimmerTitle, ShimmerCircularImage } from 'react-shimmer-effects'
import styled from 'styled-components'

const AnnaShimmerWrap = styled.div`
padding: 15px;
// margin: 10px 0px;
display: flex !important;
flex-direction: row !important;
gap: 10px !important;
// flex: 1;
width: 100%;

div{
    flex: 50%;
    height: 500px;

}

@media screen and (max-width: 458px) {
    flex-direction: column !important;
    gap: 0px;
    div{
        flex: 1;
        height: 300px !important;

    }
}
`
const AnnasShimmer = () => {
    return (
        <AnnaShimmerWrap>
            <ShimmerThumbnail className='anna' />
            <ShimmerThumbnail className='anna' />
        </AnnaShimmerWrap>
    )
}



export {
    AnnasShimmer
}