import styled, { keyframes } from "styled-components";

const spinAnimation = keyframes`
0% { transform: rotate(0deg); }
100% { transform: rotate(360deg); }
`;

const LoaderContainer = styled.div`
border: 16px solid #f3f3f3;
border-radius: 50%;
border-top: 16px solid green;
width: 120px;
height: 120px;
animation: ${spinAnimation} 2s linear infinite;
`;

export default function Loader() {
    return <LoaderContainer />;
};

