import React, { useEffect, useState } from 'react'
import { Container, Content, GridContainer, Text, Img, ImgWrapper, FlexEnd, InnerCard, Main, Paragraph, Row, StyledCard, Title, IconDiv, InputDiv, StickyDivContainer, Input, End, PaginationDiv, Textarea } from './blogs-comp'
import { BlogShimmer, Footer, Header, Icon, Paginate, PrimaryButn, Toast } from '../../components'
import { blogComment, getBlogs, searchBlogs } from '../../@services';
import parse from 'html-react-parser'
import { EmptyDiiv, Hline, Hspace } from '../../styles';
import { capitalize, useNavigate, userDetails } from '../../utils';
import { toast } from 'react-toastify';
import { BlogMeta } from "../../utils";


const Blogs = () => {
    const { navigate } = useNavigate();
    const [load, setLoad] = useState<boolean>(false);
    const [blogs, setBlogs] = useState<any>([]);
    const [inputVal, setInputVal] = useState<string>('')
    const [searched, setSearched] = useState<boolean>(false)
    const [page, setPage] = useState<number>(1)
    const [commenting, setCommenting] = useState<boolean>(false);
    const [selcetedBlog, setSelectedBlog] = useState<any>();
    const [comment, setComment] = useState<string>("")



    const blogsList = async () => {
        setLoad(true);
        let params: any = {
            page: page
        }

        await getBlogs(params).then((res: any) => {
            if (res && res?.status) {
                setBlogs(res?.data?.data)
                setLoad(false);
            } else {
                // console.log(res?.response?.data)
                setLoad(false);
            }
        })
    }


    const onSearch = async () => {
        setLoad(true)
        setBlogs([])
        let prevData = blogs
        let params = {
            "keyword": inputVal
        }
        await searchBlogs(params).then((res: any) => {
            if (res && res?.status) {
                setBlogs(res?.data?.data)
                setLoad(false)
                setSearched(true)
            } else {
                setInputVal('')
                setSearched(false)
                toast(res?.response?.data?.message);
                setBlogs(prevData)
                setLoad(false);
            }
        })
    }

    const onSearchClicked = async () => {
        if (!searched && inputVal?.length <= 0) {
            setLoad(true)
            await blogsList()
            setLoad(false)
        } else if (!searched && inputVal?.length > 0) {
            setLoad(true)
            await onSearch();
            setLoad(false)
        } else {
            setBlogs([])
            setSearched(false);
            setInputVal('');
            await blogsList();
            setLoad(false)
        }
    }
    const handlePageChange = async (e: any, pageNo: number) => {
        setLoad(true)
        setBlogs([])
        setPage(pageNo);
        let params: any = {
            page: pageNo
        }
        await getBlogs(params).then((res: any) => {
            if (res && res?.status) {
                // console.log(res?.data?.data)
                setBlogs(res?.data?.data);
                setLoad(false)
            } else {
                console.log(res?.response?.data)
                setLoad(false)
            }
        })
    }

    const putComments = async () => {
        if (commenting) {
            toast('Already processing...')
            return;
        }
        setCommenting(true);
        let params: any = {
            user_id: userDetails()?.user_id,
            blog_id: selcetedBlog,
            comments: comment
        }
        await blogComment(params).then((res: any) => {
            if (res && res?.status) {
                toast(res?.data?.message)
                setSelectedBlog(null)
                setComment('')
                setCommenting(false)
            } else {
                toast(res?.response?.data?.message)
                setCommenting(false)
            }
        })

    }
    const selectBlog = (e, item) => {
        e.stopPropagation();
        setSelectedBlog(item?.id)
    }
    const onSearchChanged = (e) => {
        setInputVal(e.target.value)
        setSearched(false)
    }

    useEffect(() => {
        blogsList();
    }, [])

    // if (!loading && (!comments?.result?.data || comments?.result?.data?.length <= 0)) {
    //     return <div></div>
    // } else {

    return (
        <Main>
            <BlogMeta />
            <Header />
            {(blogs?.result?.data || blogs?.result?.data?.length > 0) && <StickyDivContainer>
                <End>
                    <Row className="center search">
                        <Text className='med cl-blk search'>Search Blog</Text>
                        <InputDiv>
                            <Input value={inputVal} onChange={onSearchChanged} placeholder="Search" />
                            <IconDiv onClick={onSearchClicked}><Icon name={searched ? "cross" : "search"} /></IconDiv>
                        </InputDiv>
                    </Row>
                </End>
                <Hspace ht={8} />
                <Hline />
            </StickyDivContainer>}
            {!load && (!blogs?.result?.data || blogs?.result?.data?.length <= 0) ?
                <Container className='empty'>Blogs Not Available.</Container> :
                <Container>
                    <GridContainer>
                        {blogs?.result?.data.map((item, index) => (
                            <StyledCard key={item?.id}>
                                <InnerCard>
                                    <EmptyDiiv onClick={() => navigate(`blog/${item?.id}/${item?.slug}`)}>
                                        <ImgWrapper>
                                            <Img src={item?.image} alt="" />
                                        </ImgWrapper>
                                        <Content>
                                            <Content>
                                                <Title>{item?.title}</Title>
                                                {/* <Text className='med slug'>{capitalize(item?.slug)}</Text> */}
                                            </Content>
                                            <Paragraph>{parse(item?.description)}</Paragraph>
                                            <Hline />
                                            <Row className='j-sb m-t'>
                                                <Text className='sm grey pad'>{item?.created_at}</Text>
                                                <Row>
                                                    <Text className="sm green pad" onClick={(e) => selectBlog(e, item)}>Comment</Text>
                                                    <Text className="sm green pad">Views {item?.total_views}</Text>
                                                </Row>
                                            </Row>
                                        </Content>
                                    </EmptyDiiv>
                                    {selcetedBlog == item?.id && <>
                                        <Textarea value={comment} onChange={(e) => setComment(e.target.value)} />
                                        <FlexEnd>
                                            <PrimaryButn name={commenting ? "Loading.." : 'Submit'} wd={100} padding={4} onClick={putComments} />
                                        </FlexEnd>
                                    </>}
                                </InnerCard>
                            </StyledCard>
                        ))}
                    </GridContainer>
                    {load && <BlogShimmer />}
                    {!load && <PaginationDiv>
                        <Paginate totalPage={Math.ceil(blogs?.total / 9)}
                            page={page} setPage={setPage} onPageChange={handlePageChange} />
                    </PaginationDiv>}
                </Container>
            }
            <Toast />
            <Footer />
        </Main>
    )
}

export default Blogs
