import React from 'react'
import { EmptyDiiv, Hspace } from '../../styles';
import Icon from '../icon';
import ReactStars from "react-rating-stars-component";
import { ColumnEnd, LineInner, LineOuter, Row, Text } from './cust-reviews-comp';

type Props = {
    ratings?: any
}
const CustomersReview = ({ ratings }: Props) => {
    const foodStar = {
        size: 20,
        count: 5,
        a11y: true,
        isHalf: true,
        emptyIcon: <Icon name="emptyStar" />,
        halfIcon: <Icon name="halfStar" />,
        filledIcon: <Icon name="filledStar" />,
        edit: false,
        onChange: (newValue) => { }
    };

    const start = 5
    return (
        <ColumnEnd className="rating">
            <Text className='heavy'>Customer's Review <Text className='heavy f700'>({ratings?.total})</Text></Text>
            <Hspace ht={5} />
            {Array.from({ length: 5 }).map((_: any, index: number) => {
                return (
                    <Row className="gap-10" key={index.toString()}>
                        <ReactStars {...foodStar} value={start - index} />
                        <LineOuter>
                            <LineInner wd={Math.floor(ratings?.reviews_by_rating[`${start - index}`] / ratings?.total) + ratings?.reviews_by_rating[`${start - index}`]} />
                        </LineOuter>
                        <Text>{ratings?.reviews_by_rating[start - index]}</Text>
                    </Row>
                )
            })}
        </ColumnEnd>
    )
}

export default CustomersReview