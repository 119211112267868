import { whiteColor } from "../../styles";
import styled from "styled-components";


export const Main = styled.div`
  overflow: auto;
  max-height: calc(100dvh - 0px);
`;

export const Container = styled.div`
  margin: 40px 100px;
  height: 100%;
  line-height: 20px;
  letter-spacing: 1px;
  @media only screen and (max-width: 458px) {
    margin: 20px 20px;
  }
`;

export const CastleText = styled.div`
font-size: 25px;
font-weight: 500;
`

export const Castle = styled.div`
display: flex;
flex-direction: column;
align-items: center;
`

export const IconDiv = styled.div`
border-radius: 40px;
width: 29px;
background-color: #0000004f !important;
position: absolute;
top: 50%px;
left: 0px;
height: 40px;
width: 40px;
display: flex;
align-items: center;
justify-content: center;
z-index: 50;
// opacity: 0.7;
background-color: rgb(92 92 92);

&.right {
    right: 0px;
    left: unset;
    // margin: 0px 10px 0px 0px;
    svg {
        transform: rotate(180deg);
    }
};


&:disabled {
    background: unset;
    font-style: normal;
    opacity: 1;
    color: black;
    border-color: rgb(207, 207, 207);
    cursor: default;
};

&:focus {
    background: unset;
};

&:hover {
    // background-color: ${whiteColor};
    background-color: rgb(92 92 92);
    opacity: 0.8;
    // svg {
    //     path {
    //         fill: blue;
    //         opacity: 2;
    //     }
    // }
};

&::before {
    content: "\f104";
    font-size: 19px;
    opacity: 1;
    // color: black;
    color: #222;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0%);
};


svg {
  stroke: white;
    path {
        fill: white;
        opacity: 2;
    }
}
`
export const Column = styled.div`
display: flex;
flex-direction: column;
align-items: flex-start;
flex: 1;
gap: 30px;
`

export const EmptyDiv = styled.div`
&.line {
  line-height: 25px;
  letter-spacing: 1px;
}
`


export const Row = styled.div`
display: flex;
flex-direction: row;
// flex: 1;
gap:20px;

&.center {
    align-items: center;
}

&.noGap {
    gap: 0;
}

&.item-head {
    justify-content: space-between;
    align-items: center;
}
`

export const SlickContainer = styled.div`
// padding: 10px 60px;
// background: red;
border-radius: 8px;
// display: inline-block;

@media only screen and (max-width: 457px) {
  padding: 10px 10px;
}
`

export const Item = styled.div`
width: 100%;
display: flex !important;
flex-direction: column;
align-item: center;
justify-content: space-between;
box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
// height: 650px;
height: 100%;
`

export const MoreButton = styled.div`
  background-color: #ff0000;
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  box-shadow: 2px 3px 4px -2px #000;
  font-weight: 600;
max-width: 80px;
  &:hover {
    cursor: pointer;
  }
`;

export const AboutMem = styled.div`
font-size: 12px;
height: 70px;
overflow: scroll;
`


