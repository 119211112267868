import React, { useState } from 'react'
import { toast } from 'react-toastify'
import { notify } from '../../@services'
import { isLoggedIn } from '../../utils'
import Primary from '../Buttons/primary'
import Modal from '../modal'
import Overlay from '../overlay'
import Toast from '../toast'
import { Column, Error, Input, Label, Seperator } from './notification-copm'

type Props = {
    data: any;
    open: boolean;
    setOpen?: any;
}

const Notification = (Props) => {
    const [parms, setParams] = useState<any>({ name: '', email: '', mobile: '' })
    const [loading, setLoading] = useState<boolean>(false);

    const emailRegex = /^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/;
    const mobileRegex = /^[0-9]{10}$/;

    const handleChange = (e) => {
        const { name, value } = e.target;
        setParams((prevState: object) => ({
            ...prevState,
            [name]: value
        }))
    }

    const isValidate = () => {
        if (emailRegex.test(parms.email) &&
            mobileRegex.test(parms.mobile) &&
            parms.name.length > 4) {
            return true
        } else return false;
    }

    const Noftify = async () => {
        if (loading) return;
        setLoading(true)
        let params: any = {
            "product_id": Props.data?.id,
            "name": "Siddiq Barbhuiya",
            "email_id": "siddiqbarbhuiya@gmail.com",
            "mobile": "7002357790"
        }

        await notify(params).then((res: any) => {
            if (res && res?.status == 200) {
                setParams({ name: '', email: "", mobile: ''})
                toast(res?.data?.message)
                setLoading(false)
                Props.setOpen(false)
            } else {
                toast(res?.response?.data?.message)
                setLoading(false)
            }
        })

    }
    return (
        <Overlay>
            <Modal title="Notify Me" onClose={() => Props.setOpen(false)}>
                <Column>
                    <Seperator>
                        <Label>Name</Label>
                        <Input name='name' placeholder='John Smith' onChange={handleChange} />
                    </Seperator>
                    <Seperator>
                        <Label>Email</Label>
                        <Input name='email' placeholder='eg: you@yourdomain.com' onChange={handleChange} />
                        {parms?.email?.includes('@') && !emailRegex.test(parms.email) ?
                            <Error className="red">Email must be valid.</Error> : ""}
                    </Seperator>
                    <Seperator>
                        <Label>Phone</Label>
                        <Input name='mobile' placeholder='778899####' onChange={handleChange} />
                        {parms.mobile && !mobileRegex.test(parms.mobile) ?
                            <Error className="red">Mobile number must be valid.</Error> : ""}
                    </Seperator>
                    <Primary name='Submit' isDisabled={!isValidate()} padding={5} wd="100%" onClick={Noftify} />
                </Column>
                <Toast />
            </Modal>
        </Overlay>
    )
}

export default Notification