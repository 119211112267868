import { createContext, useContext } from "react";


export const CartContext = createContext<any>({
    cartItems: [],
    setCartItems: () => {},
    cartProducts: [],
    setCartProducts: () => {},
    totalAmount: () => {},
    cartAmount: 0,
    setCartAmount: () => {},
    annaValue: 0,
    setAnnaValue: () => {},
})

export const useCartContext = () => useContext(CartContext);