
import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import { Container, IconDiv, Image, Item, OutOfStock, Product, ProductName, ProductRow, Add, CrouselItem, ImgDiv, Row } from "./checkout-crousel-components";
import { Hline, Hspace } from "../../styles";
import Img from '../../assets/mini.webp';
import Icon from '../icon'
import { CrouselBoxShimmer } from "../shimmers";
import { suggestions } from "../../@services";
import { toast } from "react-toastify";
import { useNavigate } from "../../utils";
import { useHistory } from "react-router-dom";

type SlideProps = {
    onClicked?: (e: any) => void;
    tabs?: any;
    onAddtoCart?: any;
    isItemInCart?: any;
    notify?: any;
    notifyLoader?: boolean
};
const CheckoutCrousel = ({ onAddtoCart, isItemInCart, notify, notifyLoader }: SlideProps) => {
    const [activeIdx, setActiveIdx] = useState<any>(0);
    const [load, setLoad] = useState<boolean>(false);
    const [data, setData] = useState<any>([])
    const history = useHistory();
    const defaultSku = (item?: any) => {
        return item?.product_sku.find(elem => elem.is_default === 1)
    }



    const Prev: any = ({ className, style, onClick, disabled }) => {
        return (
            <IconDiv className={className} onClick={onClick}>
                <Icon name="backArrow" />
            </IconDiv>
        )
    }

    const Next: any = ({ className, style, onClick }) => {
        return (
            <IconDiv className={`${className} right`} onClick={() => {
                onClick();
            }}>
                <Icon name="backArrow" />
            </IconDiv>
        )
    }

    const settings = {
        slidesToShow: 3,
        infinite: true,
        prevArrow: <Prev />,
        nextArrow: <Next onClick={() => console.log(activeIdx + 1)} />,
        initialSlide: 0,
        beforeChange: (current, next) => setActiveIdx(next),
        responsive: [
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 2,
                    infinite: true,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    infinite: true,
                }
            },
        ],
    };

    const getSuggestions = async () => {
        setLoad(true)
        await suggestions().then((res: any) => {
            if (res && res?.status) {
                setData(res?.data?.data)
                setLoad(false)
            } else {
                toast(res?.response?.data?.message)
            }
        })
    }

    const onCardClick = (item) => {
        history.push({
            pathname: `/product/${item.slug}`, state: item
        })
    }

    useEffect(() => {
        getSuggestions()
    }, [])


    return (
        <Container>
            <Slider {...settings} style={{ display: "flex", gap: "20px", width: "100%" }}>
                {!load && data.map((item: any, i: number) => {
                    return (
                        <Item key={i.toString()} onClick={() => onCardClick(item)}>
                            <ImgDiv mgL={activeIdx != i}>
                                {item?.quantity === 0 && <OutOfStock>Out of Stock</OutOfStock>}
                                <Image src={item?.image || Img} alt={item?.name} />
                            </ImgDiv>
                            <Product>
                                <ProductRow>
                                    <ProductName>{item?.name}</ProductName>
                                </ProductRow>
                                <ProductRow>
                                    <Row className="gap5">
                                        <Icon name={item?.type == 'Veg' ? "veg" : "nonVeg"} />
                                        <ProductName className="price">
                                            <s>₹{defaultSku(item)?.mrp} </s>
                                            ₹{defaultSku(item)?.amount} </ProductName>
                                    </Row>
                                    {!item?.out_of_stock ? <Add disabl={isItemInCart(item?.id)} onClick={(e) => onAddtoCart(e, item)}>{isItemInCart(item?.id) ? "Added" : "Add"}</Add> :
                                        <Add onClick={(e) => notify(e, item)}>{notifyLoader ? "Loading..." : "Notify"} </Add>}
                                </ProductRow>
                            </Product>
                        </Item>
                    )
                })}

                {load && Array.from({ length: 3 }).map((_, ind) => (
                    <CrouselItem key={ind.toString()}>
                        <CrouselBoxShimmer />
                    </CrouselItem>
                ))}
            </Slider>
        </Container>
    );
};

export default CheckoutCrousel

