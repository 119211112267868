import { service } from "../service";

export const ContactCompany = async (params: any) => {
    let url = `/api/v1/contact-us`;

    return service
        .post(url, params)
        .then((res: any) => res)
        .catch((err: any) => err)
}

export const getTeams = async () => {
    let url = `/api/v1/get-all-team`;

    return service
        .get(url)
        .then((res: any) => res)
        .catch((err: any) => err)
}