import axios from "axios";

const baseURL = process.env.REACT_APP_API_URL;
const service = axios.create({ baseURL });
const loginService = axios.create({ baseURL });

service.interceptors.request.use(async (config: any) => {
    const access_token = localStorage.getItem('hmd_access_token')
    const bearer = `Bearer ${access_token}`;
    config.headers.Authorization = bearer;
    config.headers["Content-Type"] = "application/json";
    config.headers["Accept"] = "application/json";

    return config;
},
    (error) => {
        return Promise.reject(error);
    }
);



export { service, loginService };
