import { greenTheme, themeBackground, whiteColor } from "../../styles";
import styled from "styled-components";

export const Container = styled.div`
  background: ${themeBackground};
  // position: fixed;
  // bottom: 0;
  // left: 0;
  // right: 0;
  color: ${whiteColor};

  svg {
    height: 1em;
    path{
      fill: white;
    }
  }
`;

export const Column = styled.div`
display: flex;
flex-direction: column;
gap: 15px;
`

export const Row = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 14px;
  gap: 10px;

  &.flex-1 {
    flex: 1;
  }

  &.j-space {
    justify-content: space-between;
  }

  div {
    cursor: pointer;
  }

  &.flex40 {
    flex: 40%;
  }

  &.flex60 {
    flex: 60%;
  }

  @media only screen and (max-width: 458px) {
  &.grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap:20px;
    grid-row-gap: 10px;
    width: 100%;
    }

    &.grid div {
      width: 100%;
    }
  }
`;

export const MediaContainer = styled.div`
padding: 20px 8%;
display: flex;
flex-direction: row;
// align-items: center;
justify-content: space-between;
gap: 20%;

@media only screen and (max-width: 760px) {
  flex-direction: column;
  gap: 10px;

  div {
    // margin-right: 15px;
  }
}

`



export const Text = styled.div`
  font-size: small;
  color: ${whiteColor};
  text-align: center;
`;

export const NarrowFooter = styled.div`
box-shadow: -3px -9px 12px -9px #0c0c0c;
    // padding: 5px 0px 5px 0px;
    padding: 8px 8%;
`
export const ItemWrapper = styled.div`
// flex: 1 1 0%;
// flex: 1;
    display: flex;
    align-items: center;
    gap: 5px;
    margin-right: 10px;
}
`