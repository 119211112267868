import { useNavigate, toggleLogin } from '../utils';
import styled from 'styled-components';
import PrimaryBtn from './Buttons/primary/primary-button';
import Header from './header';

const StyledErrorHandler = styled.div`
  position: absolute;
  top: 0px;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: white;
  z-index: 54;

`;

const Center = styled.div`
display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-self: center;
    height: calc(100% - 200px);
    gap: 10px;
`

const StyledErrorMessage = styled.div`
  color: red;
`;

function ErrorHandler({ error }) {
    const { navigate }: any = useNavigate()
    return (
        <>
            <StyledErrorHandler role="alert">
                <Header />
                <Center>
                    {/* <p>Your Session has Expired:</p> */}
                    <StyledErrorMessage>{error}</StyledErrorMessage>
                    <PrimaryBtn name='Login' padding={7} wd={200} onClick={toggleLogin} />
                    <PrimaryBtn name='Home' padding={7} wd={200} onClick={() => window.location.href = `${process.env.REACT_APP_HOST}`} />
                </Center>
            </StyledErrorHandler>
        </>
    );
}

export default ErrorHandler;
