import { brown, grey, lightGrey, redish } from "../../styles";
import styled from "styled-components";

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  &.flex-1 {
    flex: 1;
  }

  &.flex-2 {
    flex: 2;
  }

  &.gap10 {
    gap: 10px;
  }

  &.gap30 {
    gap: 30px;
  }

  &.flex-end {
    justify-content: flex-end;
  }
`;

export const Text = styled.div`
  font-weight: 500;
  font-size: 0.875rem;
  color: ${brown};

  &.brown {
    color: ${brown};
    cursor: pointer;
  }

  &.cursor {
    cursor: pointer;
  }
`;

export const Input = styled.input`
  background-color: #eee !important;
  outline-width: thin;
  // border: transparent;
  border: 1px solid ${grey};
  padding: 5px 8px;
  border-radius: 4px;
  color: black;
  font-size: 12px;

  &:active,
  &:hover {
    border: 1px solid ${lightGrey};
  }
  &:focus {
    outline: none;
  }
`;

export const Label = styled.label`
  width: 100%;
  text-align: left;
  font-size: 12px;
  margin-bottom: 2px;
  color: ${lightGrey};
`;

export const Seperator = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Error = styled.div`
  color: ${redish};
  font-family: Nunito Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  max-width: 600px;
  overflow-wrap: break-word;
`;
