
import React, { useState } from 'react';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';

type Props = {
  totalPage?: number
  onPageChange?: any;
  page?: number;
  setPage?: any
}

export default function PaginationRounded({ totalPage, onPageChange, page, setPage }: Props) {
  return (
    <Stack spacing={1}>
      <Pagination count={totalPage ? totalPage : 5}
        page={page}
        onChange={onPageChange}
        siblingCount={0}
        variant="outlined" shape="circular" color='primary' />
    </Stack>
  );
}