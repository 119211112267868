import { userDetails } from "../../utils";
import { service } from "../service";

export const getAllAddress = async (params: any) => {
    let url = `/api/v1/get-all-user-address`;

    return service
        .post(url, params)
        .then((res: any) => res)
        .catch((err: any) => err);
}

export const addUserAddress = async (params: any) => {
    let url = `/api/v1/add-user-address`

    return service
        .post(url, params)
        .then((res: any) => res)
        .catch((err: any) => err);
}

export const editUserAddress = async (params: any) => {
    let url = `/api/v1/edit-user-address`;

    return service
        .post(url, params)
        .then((res: any) => res)
        .catch((err: any) => err);
}

export const deleteUserAddress = async (params) => {
    let url = `/api/v1/delete-user-address`

    return service
        .post(url, params)
        .then((res: any) => res)
        .catch((err: any) => err);
}

export const onBoard = async (params: any) => {
    let url = `/api/v1/almost-done`

    return service
        .post(url, params)
        .then((res: any) => res)
        .catch((err: any) => err);
}

export const register = async (params: any) => {
    let url = `/api/v1/register`

    // return service
    //     .post(url, params)
    //     .then((res: any) => res)
    //     .catch((err: any) => err);

    return service
        .post(url, { mobile: params })
        .then((res: any) => res)
        .catch((err: any) => err);
}

export const verifyRegisterOtp = async (params: any) => {
    let url = `/api/v1/verify-otp`

    return service
        .post(url, params)
        .then((res: any) => res)
        .catch((err: any) => err);
}

export const resendRegisterOtp = async (params: any) => {
    let url = `/api/v1/resend-otp`

    return service
        .post(url, { mobile: params })
        .then((res: any) => res)
        .catch((err: any) => err);
}

export const userlogin = async (params: any) => {
    let url = `/api/v1/login`

    return service
        .post(url, params)
        .then((res: any) => res)
        .catch((err: any) => err);
}

export const forgotPassword = async (params: any) => {
    let url = `/api/v1/forgot-pass`

    return service
        .post(url, params)
        .then((res: any) => res)
        .catch((err: any) => err);
}

export const verifyOtp = async (params: any) => {
    let url = `/api/v1/verify-forgot-otp`;

    return service
        .post(url, params)
        .then((res: any) => res)
        .catch((err: any) => err);
}


export const resetPassword = async (params: any) => {
    let url = `/api/v1/forgot-password-change`;

    return service
        .post(url, params)
        .then((res: any) => res)
        .catch((err: any) => err);
}

export const fetchProfile = async () => {
    let url = '/api/v1/get-user-profile';

    return service
        .post(url, { user_id: userDetails()?.user_id })
        .then((res: any) => res)
        .catch((err: any) => err);
}

export const changePassword = async (params: any) => {
    let url = '/api/v1/change-password';

    return service
        .post(url, params)
        .then((res: any) => res)
        .catch((err: any) => err);
}

export const resentEmail = async (params: any) => {
    let url = '/api/v1/resend-verification-email'

    return service
        .post(url, params)
        .then((res: any) => res)
        .catch((err: any) => err);
}
export const verifyEmail = async (params: any) => {
    let url = '/api/v1/verify-email';

    return service
        .post(url, params)
        .then((res: any) => res)
        .catch((err: any) => err);
}
