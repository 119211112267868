import { AddressBoxShimmer, AddressDelte, EditForm, Cart, CheckoutCrousel, CheckoutForm, Footer, Header, Icon, Overlay, PrimaryButn, Toast, AddressCard, Loader, Modal, Notification } from "../../components"
import { Hline, Hspace, Vline } from "../../styles";
import React, { useEffect, useMemo, useState } from "react";
import { Container, Row, Column, Text, FormContainer, FormLeft, FormRight, Main, DeliveryContainer, Address, ChkInput, Checkbox, Label, DottedLine, Badge, PaymentCOntainer, Selected, PaymentRight, WalletItem, BadgeDiv, Wallet, Cash, TabContainer, Image, WhatsAppContainer, WhatsappText, AuthContainer, AddNewDIv, LikeText, SuggestionHead, AddressContainer, AddressShow, AddressCardHolder, WappWrap, EmptyDIv, PaymentOverlay, LoaderText, ProductName, OutOfBox } from "./checkout-components";
import PaymentImg from '../../assets/payment.png'
import { capitalize, generateTransactionNumber, isLoggedIn, notification, useNavigate, userDetails } from "../../utils";
import { EditContainer } from "../address/address-components";
import { addUserAddress, checkPaymentStatus, createOrder, deleteUserAddress, editUserAddress, getAllAddress, getCupons } from "../../@services";
import { useCartContext } from "../../context";
import { toast } from "react-toastify";
import Whatsapp from '../../assets/svgs/whats-app.svg'
import { BestSeller } from "../homescreen/homescreen-components";
import { CheckoutMeta } from "../../utils";
// import './checkbox.css'


type UserAddress = {
    user_id: number;
    flat_house_no: string;
    address1: string;
    address2: string;
    street: string;
    landmark: string;
    city: string;
    state: string;
    country: string;
    zip: string;
    location: string;
};


const PaymentPopup = () => {
    return (
        <PaymentOverlay id="messageContainer">
            <Loader />
            <LoaderText>
                Please wait, while we generate the payment for you.
                Don't go back or refresh the page.
            </LoaderText>
        </PaymentOverlay>
    )
}

const Checkout = () => {
    const { navigate } = useNavigate();
    const { cartItems, setCartItems, cartProducts, setCartProducts, cartAmount, annaValue, totalAmount } = useCartContext()
    const [payment, setPayment] = useState(["Online", "Cash"]);
    const [selectedMethod, setSelectedMethod] = useState<string>('Online')
    const [isPayment, setIsPayment] = useState<boolean>(false);
    const [isCoupon, setIsCoupon] = useState<boolean>(false)
    const [isAuth, setIsAuth] = useState<boolean>(isLoggedIn());
    const [isAddress, setIsAddress] = useState<boolean>(isAuth);
    const [addressLoad, setAddressLoad] = useState<boolean>(false);
    const [addressList, setAddressList] = useState<any>([]);
    const [selectedAddress, setSelectedAddress] = useState<any>();
    const [showAddressList, setShowAddressList] = useState<boolean>(false);
    const [isOpenOverlay, setIsOpenOverlay] = useState<any>({ show: false, component: null, loading: false, items: null })
    const [isAdd, setIsAdd] = useState<boolean>(false)
    const [isDelete, setIsDelete] = useState<boolean>(false)
    const [isEdit, setIsEdit] = useState<boolean>(false);
    const [locationType, setLocationType] = useState<string>("")
    const [selectedCoupon, setSelecetdCoupon] = useState<any>();
    const [isOrder, setIsOrder] = useState<boolean>(false)
    const [userAddress, setUserAddress] = useState<UserAddress>({
        user_id: userDetails()?.user_id,
        flat_house_no: '',
        address1: '',
        address2: '',
        street: '',
        landmark: '',
        city: '',
        state: '',
        country: '',
        zip: '',
        location: locationType?.toLowerCase(),
    });
    const [orderNo, setOrderNo] = useState<string>("");
    const [selectedProd, setselctedProd] = useState<any>(null);
    const [selectSku, setSelectedSku] = useState<any>(null);
    const [tranxId, setTranxId] = useState<string>("");
    const [openNotify, setopenNotify] = useState<boolean>(false);
    const [notifyProd, setnotifyProd] = useState<any>()
    const [notifyLoad, setnotifyLoad] = useState<boolean>(false)

    const getUserAddress = async () => {
        setAddressLoad(true)
        let params = {
            user_id: userDetails().user_id
        }
        await getAllAddress(params).then((res: any) => {
            if (res && res.status) {
                const filteredAddresses = res?.data?.data?.filter(item => item.is_default === 1)[0] ?? res.data.data[0];
                setSelectedAddress(filteredAddresses)
                setAddressList(res.data.data)
                setAddressLoad(false)
                if (cartItems?.length >= 1) {
                    setIsPayment(true)
                }
            } else if (res && res?.response?.status == 401) {

            } else if (res && res?.response?.status == 404) {
                setAddressLoad(false)
            } else {
                setIsPayment(false)
                toast(res?.response?.data?.message)
                setAddressLoad(false)
                setAddressList([])
            }
        })
    }


    const addAddress = async () => {
        setIsAdd(true)
        let params = {
            ...userAddress,
            location: locationType.toLowerCase()
        }

        await addUserAddress(params)
            .then((res: any) => {
                if (res && res.status) {
                    setIsOpenOverlay({ show: false })
                    setUserAddress({
                        user_id: userAddress.user_id,
                        flat_house_no: '',
                        address1: '',
                        address2: '',
                        street: '',
                        landmark: '',
                        city: '',
                        state: '',
                        country: '',
                        zip: '',
                        location: '',
                    })
                    setLocationType("")
                    setIsAdd(false)
                    setShowAddressList(false)
                    getUserAddress();
                    toast(res?.data?.message)
                } else {
                    toast(res?.response?.data?.message)
                    // setIsOpenOverlay({ show: false })
                    setIsAdd(false)
                }
            })
    }


    const onDelete = (item: any) => {
        setIsOpenOverlay({ show: true, component: 'delete', loading: false, items: item })
    }

    const onDeleted = async () => {
        setIsDelete(true)

        let params = {
            address_id: isOpenOverlay.items.id,
            user_id: userAddress?.user_id
        }

        await deleteUserAddress(params).then((res: any) => {
            if (res && res.status) {
                console.log(res.data.data)
                setIsOpenOverlay({ show: false })
                setIsDelete(false)
                getUserAddress();
                toast(res.data.message)
            } else {
                toast(res?.response?.data?.message)
                getUserAddress();
                setIsOpenOverlay({ show: false })
                setIsDelete(false)
            }
        })
    }

    const onEdit = (item: any) => {
        setLocationType(item.location)
        setUserAddress({
            user_id: userAddress.user_id,
            flat_house_no: item.flat_house_no,
            address1: item.address1,
            address2: item.address2,
            street: item.street,
            landmark: item.landmark,
            city: item.city,
            state: item.state,
            country: item.country,
            zip: item.zip,
            location: item.location

        })
        setIsOpenOverlay({ show: true, component: 'edit', loading: false, items: item })
    }

    const onEdited = async () => {
        setIsEdit(true)
        setIsPayment(false)

        let params = {
            address_id: isOpenOverlay?.items?.id,
            ...userAddress,
            location: locationType.toLowerCase(),
        }

        await editUserAddress(params).then((res: any) => {
            if (res && res.status) {
                console.log(res.data.data)
                setIsOpenOverlay({ show: false })
                setIsEdit(false)
                toast(res.data.message)
                getUserAddress();
                setUserAddress({
                    user_id: userAddress.user_id,
                    flat_house_no: '',
                    address1: '',
                    address2: '',
                    street: '',
                    landmark: '',
                    city: '',
                    state: '',
                    country: '',
                    zip: '',
                    location: '',
                })
                setIsPayment(true)
            } else {
                // console.log(res.data)
                setIsPayment(true)
                toast(res?.response?.data?.message)
                setIsOpenOverlay({ show: false })
                setIsEdit(false)
            }
        })
    }


    // const onAddtoCart = (e, newProd: any) => {
    //     e.stopPropagation();
    //     const isItemInCart = cartItems.some(item => item.id === newProd.id);
    //     if (!isItemInCart) {
    //         setCartItems((prev) => ([newProd, ...prev]));
    //     } else {
    //         toast('Item already exits on cart')
    //     }
    // }

    const isItemInCart = (prodId: number) => {
        return cartItems.some(item => item.id === prodId);
    }

    const openModal = (e: MouseEvent, prod: any) => {
        e.stopPropagation();
        const isItemInCart = cartItems.some(item => item.id === prod.id);
        if (isItemInCart) {
            toast('Item already exits on cart')
            return;
        }
        setselctedProd(prod)
    }

    //new method
    const onAddtoCart = (sku: any, parentProd: any) => {
        const isItemInCart = cartItems.some(item => item.id === sku.id);
        if (!isItemInCart) {
            const updatedSku = { ...sku, name: parentProd?.name, type: parentProd?.type, id: parentProd?.id };
            setCartItems((prev) => ([updatedSku, ...prev]));
            setselctedProd(null)
            setSelectedSku(null)
            setSelecetdCoupon([])
        } else {
            toast('Item already exits on cart')
        }
    }

    const closeModal = () => {
        setselctedProd(null);
        setSelectedSku(null)
    }
    const handleSku = (elem: any) => {
        setSelectedSku(elem)
    }


    const onSelectedAddress = (item: any) => {
        setSelectedAddress(item);
        setShowAddressList(false)
    }

    let intervalId: NodeJS.Timeout;
    const getPaymentStatus = async () => {
        console.log("transaction", tranxId)
        let params: any = {
            user_id: userDetails()?.user_id,
            transaction_id: sessionStorage.getItem("transactionId")
        };
        try {
            const res = await checkPaymentStatus(params);
            if (res && res.status === 200) {
                clearInterval(intervalId);
                toast.success(res?.data?.message, { autoClose: false });
                createNewOrder()
            } else if (res && res.status === 201) {
            } else {
                clearInterval(intervalId);
                toast.error(res.data?.message, { autoClose: false, theme: "colored" })
                setIsOrder(false);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const startCheckingStatus = async () => {
        clearInterval(intervalId);
        intervalId = setInterval(async () => {
            await getPaymentStatus();
        }, 6000);
    };

    const paymentRedirect = async () => {
        const transactionId = generateTransactionNumber()
        console.log(" transactionId:", transactionId)
        sessionStorage.setItem("transactionId", transactionId)

        let encodedData = encodeURIComponent(
            btoa(`amount=${totatCost}&user_id=${userDetails()?.user_id}&txn_hash=${transactionId}`)
        )
        let paymentUrl = `${process.env.REACT_APP_PAYMENT_URL}=${encodedData}`;
        window.open(paymentUrl, "_blank", "height=700,width=600");
        setIsOrder(true);

        let t = setTimeout(async () => {
            await startCheckingStatus();
        }, 20000);

        return () => {
            clearTimeout(t);
        };
    };

    const createNewOrder = async () => {
        if (isOrder) {
            toast("Already in process")
            return;
        }
        setIsOrder(true)
        let params: any = {
            user_id: userDetails()?.user_id,
            address_id: selectedAddress?.id,
            coupon_id: selectedCoupon?.length ? selectedCoupon[0]?.id : null,
            is_coupon: selectedCoupon?.length ? "1" : "0",
            payment_mode: selectedMethod == "Cash" ? "cod" : "online",
            all_product: cartProducts,
            rate: btoa(JSON.stringify(payHistory))
        };

        if (annaValue > 0) {
            params.is_annas_used = "1";
            params.annas_amount = annaValue;
        }

        if (selectedMethod == 'Online') {
            params.transaction_id = sessionStorage.getItem("transactionId")
        }

        await createOrder(params).then(async (res: any) => {
            if (res && res?.status) {
                setIsOrder(false)
                toast(res?.data?.message, { autoClose: 3000 });
                await new Promise((resolve) => setTimeout(resolve, 3000));
                localStorage.removeItem('cartItems');
                setCartItems([]);
                setCartProducts([]);
                sessionStorage.removeItem("transactionId")
                navigate('/orders');
                setIsOrder(false);
            } else {
                toast(res?.response?.data?.message);
                setIsOrder(false)
            }
        })
    }


    const calcDeliveryFee = (): number => {
        const minAmount: any = process.env.REACT_APP_MIN_ORDER_VALUE
        const delivery_fee: any = process.env.REACT_APP_DELIVERY_FEE
        if (parseInt(minAmount) >= cartAmount) {
            return parseInt(delivery_fee);
        } else {
            return 0;
        }
    }
    const delivery_fee = calcDeliveryFee();
    const tax: number = parseFloat((18 / 100 * cartAmount).toFixed(2));
    const totatCost: number = (parseFloat((cartAmount + delivery_fee)?.toFixed(2)));

    const addressChnage = () => {
        setShowAddressList(!showAddressList);
        setSelectedAddress('')
    }

    let payHistory = [{
        tax: tax,
        discount: Math.abs(cartAmount - totalAmount),
        sub_total: cartAmount,
        pay_amount: totatCost,
        delivery_fee
    }]

    const handleConfirmOnlinePayment = async () => {
        // setPayment((prev) => {
        //     return prev.slice(0, 1);
        // })
        // await createNewOrder();
        const transactionId = generateTransactionNumber()
        setTranxId(transactionId)
    }

    const Noftify = async (e: MouseEvent, data: any) => {
        e.stopPropagation();
        if (!isLoggedIn()) {
            setopenNotify(true)
            setnotifyProd(data)
        } else {
            if (notifyLoad) return;
            setnotifyProd(data)
            setnotifyLoad(true)
            let res = await notification(data?.id);
            if (res.status === 200) {
                toast(res?.data?.message);
                setnotifyLoad(false)
            } else {
                toast(res?.data?.message)
                setnotifyLoad(false)
            }

        }
    }

    useEffect(() => {
        if (isAuth && isAddress) {
            getUserAddress();
        }
    }, [isAuth, isAddress])

    useEffect(() => {
        if (cartItems?.length > 0 && selectedAddress) {
            setIsPayment(true)
        } else {
            setIsPayment(false)
        }
    }, [cartItems, selectedAddress])

    return (
        <Main>
            <CheckoutMeta />
            {isOrder && <PaymentPopup />}
            <Header />
            <Container>
                <SuggestionHead>
                    <LikeText>YOU MIGHT ALSO LIKE</LikeText>
                    <CheckoutCrousel
                        isItemInCart={(id) => isItemInCart(id)}
                        onAddtoCart={(e: any, newProd) => openModal(e, newProd)}
                        notify={(e: any, item: any) => Noftify(e, item)}
                        notifyLoader={notifyLoad}
                    />
                </SuggestionHead>
                <DottedLine>
                    <FormContainer>
                        <FormLeft>
                            <BadgeDiv>
                                <Badge><Icon name="person" />Login</Badge>
                                <AuthContainer maxHeight={isAuth} >
                                    {isAuth ?
                                        <Row className="resend svgs">
                                            <Icon name="person" /> <Text className="medium">Logged in as {capitalize(userDetails()?.name)}</Text>
                                        </Row> : <CheckoutForm />
                                    }
                                </AuthContainer>
                            </BadgeDiv>

                            <BadgeDiv>
                                <Badge><Icon name="location" />Address</Badge>
                                <DeliveryContainer maxHeight={!isAddress}>
                                    {addressLoad && <AddressBoxShimmer />}
                                    {!isAddress && <Row className="gap10 svgs"><Icon name="location" />  Choose Delivery Address</Row>}
                                    {isAddress && !showAddressList && !addressLoad && addressList?.length ?
                                        <>
                                            <Column className="flex-1">
                                                <Row>
                                                    <div>Delivery at</div>
                                                    <Checkbox className="round">
                                                        <ChkInput type="checkbox" id="checkbox" checked={selectedAddress} onChange={addressChnage} />
                                                        <Label htmlFor="checkbox"></Label>
                                                    </Checkbox>
                                                </Row>
                                                <Hspace ht={2} />
                                                <Address>
                                                    <Column>
                                                        <div>{capitalize(selectedAddress?.location)}</div>
                                                        <div>{selectedAddress?.flat_house_no}, {capitalize(selectedAddress?.address1)}, {capitalize(selectedAddress?.address2)}, {capitalize(selectedAddress?.street)} </div>
                                                        <div>{capitalize(selectedAddress?.city)} {capitalize(selectedAddress?.state)} {selectedAddress?.zip}</div>
                                                        <div>Landmark - {capitalize(selectedAddress?.landmark)}</div>
                                                    </Column>
                                                    <PrimaryButn name="CHANGE" onClick={addressChnage} />
                                                </Address>
                                            </Column>
                                        </> :
                                        isAddress && !showAddressList && !addressLoad &&
                                        <AddNewDIv onClick={() => setIsOpenOverlay({ show: true, component: 'new-add', loading: false })}>
                                            <Icon name="plus" />Add New Address
                                        </AddNewDIv>
                                    }
                                    {showAddressList && !addressLoad &&
                                        <>
                                            <AddressShow>
                                                <Text className="large">Select an Address</Text>
                                                <AddNewDIv onClick={() => setIsOpenOverlay({ show: true, component: 'new-add', loading: false })}>
                                                    <Icon name="plus" />Add New Address
                                                </AddNewDIv>
                                                <AddressContainer>
                                                    {addressList?.map((item: any, index: number) => (
                                                        <AddressCardHolder key={index.toString()} onClick={() => onSelectedAddress(item)}>
                                                            <AddressCard
                                                                key={index.toString()}
                                                                data={item}
                                                                onEditClicked={() => onEdit(item)}
                                                                onDeleteCliked={() => onDelete(item)}
                                                            />
                                                        </AddressCardHolder>
                                                    ))}
                                                </AddressContainer>
                                            </AddressShow>
                                        </>}
                                </DeliveryContainer>
                            </BadgeDiv>

                            <BadgeDiv bd={false}>
                                <Badge onClick={() => { }}><Icon name="wallet" />Payment</Badge>
                                <PaymentCOntainer maxHeight={!isPayment && !selectedAddress && !isAddress}>
                                    {!isPayment ?
                                        <Row className="gap10 svgs"><Icon name="wallet" />Payment Methods</Row> :
                                        <>
                                            <TabContainer>
                                                {payment.map((item: string) => {
                                                    return <Selected key={item} active={selectedMethod == item} onClick={() => setSelectedMethod(item)}>{item}</Selected>
                                                })}
                                            </TabContainer>
                                            <PaymentRight>
                                                {selectedMethod == 'Online' ?
                                                    <Wallet>
                                                        <WalletItem>
                                                            <Image height="100%" wd="100%" src={PaymentImg} />
                                                        </WalletItem>
                                                        <PrimaryButn name="" wd={220} isDisabled={isOrder} padding={8} onClick={paymentRedirect}>{isOrder ? "Processing" : `PROCEED TO PAY Rs.${totatCost}`}</PrimaryButn>
                                                    </Wallet> :
                                                    <React.Fragment>
                                                        <div>Please proceed with your order</div>
                                                        <Cash>
                                                        </Cash>
                                                        <PrimaryButn name="" wd={200} isDisabled={isOrder} padding={8} onClick={createNewOrder}>{isOrder ? "Processing" : `PLACE ORDER Rs.${totatCost}`}</PrimaryButn>
                                                        {isOrder && <Text>Please wait, while the order is getting generated</Text>}
                                                    </React.Fragment>
                                                }
                                            </PaymentRight>
                                        </>
                                    }
                                </PaymentCOntainer>
                            </BadgeDiv>
                        </FormLeft>

                        <FormRight>
                            {cartItems?.length ?
                                <Cart
                                    isCheckout={false}
                                    border={false}
                                    selectedCoupon={selectedCoupon}
                                    setSelecetdCoupon={setSelecetdCoupon}
                                    tax={tax}
                                    totatCost={totatCost}
                                    paymentMode={selectedMethod}
                                    deliveryFee={delivery_fee}
                                    onProceed={() => { }} />
                                : null
                            }
                        </FormRight>
                    </FormContainer>
                </DottedLine>
            </Container>
            <Toast />
            <Footer />
            {isOpenOverlay.show &&
                <React.Fragment>
                    <Overlay>
                        <EditContainer>
                            {isOpenOverlay.component == 'new-add' &&
                                <EditForm
                                    type='add'
                                    userAddress={userAddress}
                                    setUserAddress={setUserAddress}
                                    locationType={locationType}
                                    setLocationType={setLocationType}
                                    onSubmit={addAddress}
                                    onDelete={() => { }}
                                    isAdding={isAdd}
                                    // isDeleting={isDelete}
                                    onClose={() => setIsOpenOverlay({ show: false, component: 'new-add', loading: false })}
                                />
                            }
                            {isOpenOverlay.component == 'edit' &&
                                <EditForm
                                    type='edit'
                                    userAddress={userAddress}
                                    setUserAddress={setUserAddress}
                                    locationType={locationType}
                                    setLocationType={setLocationType}
                                    onSubmit={onEdited}
                                    onDelete={onDeleted}
                                    isEditing={isEdit}
                                    isDeleting={isDelete}
                                    onClose={() => setIsOpenOverlay({ show: false, component: 'edit', loading: false })}
                                />
                            }
                            {isOpenOverlay.component == 'delete' &&
                                <AddressDelte
                                    onCancel={() => setIsOpenOverlay({ show: false, component: 'delete', loading: false })}
                                    onSubmit={onDeleted}
                                    isLoading={isDelete}
                                    onClose={() => setIsOpenOverlay({ show: false, component: 'delete', loading: false })} />
                            }
                        </EditContainer>
                    </Overlay>
                </React.Fragment>
            }
            {selectedProd ?
                <Overlay>
                    <Modal title="Select item net weight" onClose={closeModal}>
                        <Row>
                            <ProductName>{selectedProd?.name}</ProductName>
                            <Icon name={selectedProd?.type == 'Veg' ? "veg" : "nonVeg"} />
                        </Row>
                        <Hspace />
                        <Column>
                            {selectedProd?.product_sku?.map((elm: any, i: number) => (
                                <Row className="j-sb noGap">
                                    <Row className="gap10">
                                        <input onClick={() => handleSku(elm)} name={elm?.sku_name} type="checkbox" value={elm?.sku_name} id={elm?.sku_name} checked={elm?.sku_id === selectSku?.sku_id} disabled={elm?.quantity === 0} onChange={() => { }} />
                                        <div style={{fontSize: 12}}>{elm?.sku_name} {elm?.units?.name}</div>
                                        <OutOfBox>{elm?.quantity === 0 ? "Out of stock" : elm?.is_best_seller === 1 ? <BestSeller><Icon name="tick" />Best Selling Item</BestSeller> : null}</OutOfBox>
                                    </Row>
                                    <div style={{fontSize: 12}}>₹{elm?.amount}</div>
                                </Row>
                            ))}
                            <Hline />
                            <PrimaryButn wd="100%" name="Add to cart" padding={10} fSize={16} isDisabled={selectSku === null} onClick={() => onAddtoCart(selectSku, selectedProd)} />
                        </Column>
                    </Modal>
                </Overlay> : null}

            {openNotify ? <Notification data={notifyProd} open={openNotify} setOpen={setopenNotify} /> : null}
        </Main >
    )
}

export default Checkout