import React, { useEffect, useRef, useState } from 'react';
import Logo from '../../assets/logo.png'
import { Column, Container, Item, Row, EmptyDiv, SideNav, Img, LoginButton, LoginContainer } from './header-components';
import { isLoggedIn, useNavigate } from '../../utils';
import Overlay from '../overlay';
import { Login } from '../../modules';

const Header = () => {
    const navRef = useRef<HTMLDivElement>(null);
    const [sidenavWidth, setSidenavWidth] = useState(0);
    const { navigate } = useNavigate();
    const [login, setLogin] = useState<boolean>(false)

    const openNav = () => {
        setSidenavWidth(300);
    };

    const closeNav = () => {
        setSidenavWidth(0);
    };

    const navigateHome = () => navigate('/')

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (navRef.current && !navRef.current.contains(event.target)) {
                closeNav();
            }
        };
        if (sidenavWidth) {
            document.addEventListener('click', handleClickOutside);
        } else {
            document.removeEventListener('click', handleClickOutside);
        }

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [sidenavWidth]);

    const logout = () => {
        localStorage.removeItem('hmd_access_token');
        localStorage.removeItem('hmd_user_details');
        localStorage.removeItem("cartItems");
        window.location.replace((process.env.REACT_APP_HOST as any));

    }

    return (
        <Container>
            <Row>
                <Row ref={navRef}>
                    <EmptyDiv className="open-btn" onClick={openNav}>&#9776;</EmptyDiv>
                    <SideNav className="sidenav" wd={sidenavWidth}>
                        <EmptyDiv className="closebtn" onClick={closeNav}>&times;</EmptyDiv>
                        <Column>
                            <Item onClick={() => window.location.href = `${process.env.REACT_APP_HOST}`}>Menu</Item>
                            {isLoggedIn() &&
                                <>
                                    <Item onClick={() => navigate('/orders')}>Your Order</Item>
                                    <Item onClick={() => navigate('/address')}>Your Address</Item>
                                    <Item onClick={() => navigate('/annas')}>Your Coin</Item>
                                    <Item onClick={() => navigate('/profile')}>My Profile</Item>
                                </>
                            }
                            <Item onClick={() => navigate('/blogs')}>Blogs</Item>
                            <Item onClick={() => navigate('/about')}>About Us</Item>
                            <Item onClick={() => navigate('/contact')}>Contact Us</Item>
                            <Item onClick={() => navigate('/policy')}>Privacy Policy</Item>
                            <Item onClick={() => navigate('/return')}>Return & Ship Policy</Item>
                            <Item onClick={() => navigate('/terms')}>Terms</Item>
                            {isLoggedIn() && <Item onClick={logout}>Logout</Item>}
                        </Column>
                    </SideNav>
                    <Img src="/assets/logo192.png" alt="Logo" className="hide" onClick={navigateHome} />
                </Row>
                <Img src="/assets/logo192.png" alt="Logo" className="show" onClick={navigateHome} />
                {!isLoggedIn() ? <LoginButton id="login" onClick={() => setLogin(true)}>LogIn</LoginButton> : <div></div>}
                {/* <LoginButton onClick={() => setLogin(true)}>LogIn</LoginButton>  */}
            </Row>
            {login &&
                <Overlay>
                    <Login login={login} setLogin={setLogin} onClose={() => setLogin(false)} type='login' />
                </Overlay>
            }
        </Container >
    );
}

export default Header;
