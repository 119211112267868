import { BackArrow, Person, Location, FillArrow, FilledStar, EmptyStar, HalfSTar, Plus, Minus, Chilly, Plane, WhiteArrow, Facebook, Instagram, Twitter, Youtube, FacebookFilled, ShareUrl, NonVeg, Veg, Eye, EyeStrike, Arrow, Search, Cross, Wallet, GreyChilly, Tick, SmallCross, Info } from "./icons";

export const iconNames = {
    backArrow: BackArrow,
    person: Person,
    location: Location,
    fillArrow: FillArrow,
    filledStar: FilledStar,
    emptyStar: EmptyStar,
    halfStar: HalfSTar,
    plus: Plus,
    minus: Minus,
    chilly: Chilly,
    greyChilly: GreyChilly,
    plane: Plane,
    whiteArrow: WhiteArrow,
    facebook: Facebook,
    instagram: Instagram,
    twitter: Twitter,
    youtube: Youtube,
    facebookFilled: FacebookFilled,
    shareUrl: ShareUrl,
    nonVeg: NonVeg,
    veg: Veg,
    eye: Eye,
    eyeStrike: EyeStrike,
    arrow: Arrow,
    search: Search,
    cross: Cross,
    wallet: Wallet,
    tick: Tick,
    smallCross: SmallCross,
    info: Info,

};

const Icon = ({ name, params }: any) => {
    let args: object = { params: params };
    const IconComponent = iconNames[name];
    return <IconComponent />;
}

export default Icon