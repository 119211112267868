import { CustomerReviews, DescriptionShimmer, Footer, Header, Icon, Modal, Notification, Overlay, PrimaryButn, Share, Toast } from "../../components"
import { Container, Row, Image, Description, TopTitle, SubTitle, ProductDiv, SmallDescription, Price, CartControls, ProductDetail, Main, OutOfStock, Offer, EmptyDiv, FLex1, SpecilityBox, SpHead, SpList, FlexEnd, ColumnEnd, New, CartCount, IconBack, CartIcon, Count, PriceBox, ProductName, Column, OutOfBox, ImgBox } from "./product-descption-components"
import Img from '../../assets/mini.webp';
import PrimaryBtn from "../../components/Buttons/primary/primary-button";
import { Hline, Hspace } from "../../styles";
import { useHistory, useLocation, useParams } from "react-router";
import React, { useEffect, useState } from "react";
import { getProductDetails } from "../../@services";
import ReactStars from "react-rating-stars-component";
import { Helmet } from "react-helmet";
import { useCartContext } from "../../context";
import { toast } from "react-toastify";
import { isLoggedIn, notification, ProductDescriptionMeta, useNavigate } from "../../utils";
import ProdReviews from "../prod-reviews";
import { BestSeller } from "../homescreen/homescreen-components";
import ProdRecom from "../prod-recom";


const CartDetails = ({ isItemInCart, checkItemOnCart, filterTotalCount, description, setselctedProd, id }: any) => {
    const { state }: any = useLocation();
    const { cartItems, setCartItems, cartProducts, setCartProducts } = useCartContext();

    const incrementItem = (productId) => {
        setCartProducts((prevCart) => {
            const updatedCart = prevCart.map((item) =>
                item.id === productId && item.count < item.quantity
                    ? { ...item, count: item.count + 1 }
                    : item
            );
            localStorage.setItem('cartItems', JSON.stringify(updatedCart));

            return updatedCart;
        });
    };

    // const onAddtoCart = (newProd: any) => {
    //     const isItemInCart = cartItems.some(item => item.id === newProd.id);
    //     if (!isItemInCart) {
    //         setCartItems((prev) => ([newProd, ...prev]));
    //     } else {
    //         toast('Item already exits on cart')
    //     }
    // }

    const openModal = (prod: any) => {
        const isItemInCart = cartItems.some(item => item.id === prod.id);
        if (isItemInCart) {
            toast('Item already exits on cart')
            return;
        }
        setselctedProd(description)
    }


    const decrementItem = (productId) => {
        setCartProducts((prevCart) => {
            if (prevCart.some((item) => item.id === productId && item.count <= 1)) {
                const filteredItem = prevCart.filter((item) => item.id !== productId)
                setCartItems(filteredItem);
                setCartProducts(filteredItem)
            }

            if (cartProducts?.length == 1 && cartProducts[0].count == 1) {
                return localStorage.removeItem('cartItems')
            }

            localStorage.setItem('cartItems', JSON.stringify(prevCart));

            return prevCart.map((item) =>
                item.id === productId && item.count > 1
                    ? { ...item, count: item.count - 1 }
                    : item
            );
        });
    };

    const checkSkuSize = cartProducts?.filter(item => item?.id === id)[0]

    useEffect(() => {
        const updatedProducts = cartItems?.map((cartItem: any) => {
            const existingProduct = cartProducts?.find((product: any) => product.id === cartItem.id);
            const count = existingProduct ? existingProduct.count : 1;
            return { ...cartItem, count };
        });
        localStorage.setItem("cartItems", JSON.stringify(updatedProducts))
        setCartProducts(updatedProducts);
    }, [cartItems]);

    return (
        <CartControls>
            {checkItemOnCart &&
                <Row className="gap10">
                    <CartIcon onClick={() => decrementItem(description?.id)}><Icon name="minus" /></CartIcon>
                    <Count> {filterTotalCount}</Count>
                    <CartIcon onClick={() => incrementItem(description?.id)}><Icon name="plus" /></CartIcon>
                </Row>
            }
            {!description?.out_of_stock &&
                <EmptyDiv>
                    <PrimaryBtn name={`${checkItemOnCart ? "ADDED" : "ADD"} TO CART`} padding={7} wd={140} onClick={() => openModal(description)} />
                    <Hspace />
                    {checkItemOnCart && <Row className="sku">~Net Weight: {checkSkuSize?.sku_name} {checkSkuSize?.units?.name} added.</Row>}
                </EmptyDiv>
            }
        </CartControls>
    )
}

const ProductDescription = () => {
    const { state }: any = useLocation();
    const { navigate } = useNavigate();
    const { id, slug }: any = useParams();
    const history = useHistory()
    const [loading, setLoading] = useState<boolean>(false);
    const [data, setData] = useState<any>({});
    const { cartItems, setCartItems, cartProducts, setCartProducts } = useCartContext();
    const [selectedProd, setselctedProd] = useState<any>(null);
    const [selectSku, setSelectedSku] = useState<any>(null);
    const [rating, setRating] = useState<any>(null);
    const [image, setImage] = useState<string>("");
    const [openNotify, setopenNotify] = useState<boolean>(false);
    const [notifyProd, setnotifyProd] = useState<any>()
    const [notifyLoad, setnotifyLoad] = useState<boolean>(false)
    const description = data?.description;
    const defaultSku = data?.description?.product_sku?.find(elem => elem?.is_default === 1)

    const foodStar = {
        size: 20,
        count: 5,
        color: "black",
        activeColor: "red",
        value: rating?.average_rating ?? 0,
        a11y: true,
        isHalf: true,
        emptyIcon: <Icon name="emptyStar" />,
        halfIcon: <Icon name="halfStar" />,
        filledIcon: <Icon name="filledStar" />,
        edit: false,
        onChange: (newValue) => {
            // console.log(`Example 2: new value is ${newValue}`);
        }
    };

    const getProdDetails = async () => {
        setLoading(true)
        let p_id: any = {
            p_id: slug
            // product_id: 1
        }
        await getProductDetails(p_id)
            .then((res) => {
                if (res && res.status == 200) {
                    setData(res.data.data)
                    setImage(res?.data?.data?.description?.images[0].image)
                    setLoading(false)
                } else {
                    setLoading(false)
                }
            })
    }

    const NoOfChillies = ({ level }: any) => {
        let numberOfChillies = level === 'Full' ? 3 : level === 'Medium' ? 2 : 1;
        return (
            <Row className="gap2">
                {Array.from({ length: 3 - numberOfChillies }).map((_, index) => <Icon key={index} name="greyChilly" />)}
                {Array.from({ length: numberOfChillies }).map((_, index) => <Icon key={index} name="chilly" />)}
            </Row>
        );
    }

    // const onAddtoCart = (newProd: any) => {
    //     const isItemInCart = cartItems.some(item => item.id === newProd.id);

    //     if (!isItemInCart) {
    //         setCartItems((prev) => ([newProd, ...prev]));
    //     } else {
    //         toast('Item already exits on cart')
    //     }
    // }

    const isItemInCart = (prodId: number) => {
        return cartItems.some(item => item.id === prodId);
    }
    const checkItemOnCart = isItemInCart(description?.id);
    const filterTotalCount = cartProducts?.filter(item => item?.id === data?.description?.id)[0]?.count;

    const openModal = (prod: any) => {
        const isItemInCart = cartItems.some(item => item.id === prod.id);
        if (isItemInCart) {
            toast('Item already exits on cart')
            return;
        }
        setselctedProd(description)
    }

    //new method
    const onAddtoCart = (sku: any, parentProd: any) => {
        const isItemInCart = cartItems.some(item => item.id === sku.id);
        if (!isItemInCart) {
            const updatedSku = { ...sku, name: parentProd?.name, type: parentProd?.type, id: parentProd?.id };
            setCartItems((prev) => ([updatedSku, ...prev]));
            setselctedProd(null)
            setSelectedSku(null)
        } else {
            toast('Item already exits on cart')
        }
    }

    const closeModal = () => {
        setselctedProd(null);
        setSelectedSku(null)
    }
    const handleSku = (elem: any) => {
        setSelectedSku(elem)
    }

    function Copy() {
        const input: any = document.createElement('input');
        document.body.appendChild(input);
        let text = window.location.href;
        input.value = text
        input.select();
        document.execCommand('copy');
        document.body.removeChild(input);
        toast.success('Link copied')
    }
    const shareData = {
        title: "Share",
        text: "Share message",
        url: "https://www.brannen.dev"
    }

    const handleRating = (e: any) => {
        setRating(e)
    }

    const Noftify = async (data: any) => {
        if (!isLoggedIn()) {
            setopenNotify(true)
            setnotifyProd(data)
        } else {
            if (notifyLoad) return;
            setnotifyProd(data)
            setnotifyLoad(true)
            let res = await notification(data?.id);
            if (res.status === 200) {
                toast(res?.data?.message);
                setnotifyLoad(false)
            } else {
                toast(res?.data?.message)
                setnotifyLoad(false)
            }
        }
    }



    useEffect(() => {
        getProdDetails()
    }, [])
    return (
        <Main>
            <ProductDescriptionMeta description={description} />
            <Header />
            {!loading && data ?
                <Container>
                    <ProductDiv>
                        <Row className="gap10"><CartIcon onClick={() => history.goBack()}>
                            <Icon name='arrow' /></CartIcon><TopTitle>BACK TO MENU</TopTitle>
                        </Row>
                        <Hspace ht={10} />
                        <ProductDetail>
                            <Image>
                                <img src={image || Img} />
                                <Hspace ht={10} />
                                <Row className="gap10">
                                    {description?.images.map((item: any) => (
                                        <ImgBox key={item?.id} onClick={() => setImage(item?.image)}><img src={item?.image || Img} alt="Prod" /></ImgBox>
                                    ))}
                                </Row>
                                {description?.out_of_stock && <OutOfStock>Out of Stock</OutOfStock>}
                                {checkItemOnCart && <CartCount>{filterTotalCount} item added to cart</CartCount>}
                                {/*description?.discount.discount_value > 0 ? <Offer>{description?.discount.type == 'Percent' ? `${description?.discount.discount_value}%` : `Rs. ${description?.discount.discount_value}`} off</Offer> : null*/}
                            </Image>
                            <FLex1>
                                <Description className="space-betn ">
                                    <EmptyDiv>
                                        {/* <TopTitle>SHAHI (SERVES 1)</TopTitle> */}
                                        <SubTitle>
                                            {description?.name}
                                        </SubTitle>
                                        <SmallDescription>{description?.description}</SmallDescription>
                                    </EmptyDiv>
                                    <ColumnEnd>
                                        {/* <Row><Icon name="filledStar" /></Row> */}
                                        {rating !== null ? <ReactStars {...foodStar} /> : <EmptyDiv>Loading...</EmptyDiv>}
                                        <EmptyDiv>Out of five</EmptyDiv>
                                    </ColumnEnd>
                                    <Hspace ht={50} />
                                    <PriceBox>
                                        <Price><Icon name={description?.type == 'Veg' ? "veg" : "nonVeg"} /> {description?.discount.discount_value > 0 ? <s>₹{defaultSku?.mrp}</s> : null}   ₹{defaultSku?.amount}</Price>
                                        {cartItems?.length ? <CartDetails description={description} isItemInCart={isItemInCart} checkItemOnCart={checkItemOnCart} filterTotalCount={filterTotalCount} setselctedProd={setselctedProd} id={data?.description?.id} /> :
                                            !description?.out_of_stock ?
                                                <PrimaryBtn name="ADD TO CART" padding={7} wd={140} onClick={() => openModal(selectedProd)} /> :
                                                <PrimaryBtn name={notifyLoad ? "Loading..." : "Notify"} padding={7} wd={140} onClick={() => Noftify(data?.description)} />
                                        }
                                    </PriceBox>
                                </Description>
                                <SpecilityBox>
                                    <SpHead>Size</SpHead>
                                    {description?.product_sku.map((item) => (
                                        <React.Fragment key={item?.sku_id}>
                                            <Row className="j-sb m10 noGap">
                                                <EmptyDiv key={item}>{item.sku_name}  {item?.units?.name}</EmptyDiv>
                                                <OutOfBox>{item?.quantity === 0 ? "Out of stock" : item?.is_best_seller === 1 ? <BestSeller><Icon name="tick" />Best Selling Item</BestSeller> : null}</OutOfBox>
                                                <Column className="gap2">
                                                    <EmptyDiv><s>₹{item?.mrp}</s> ₹{item?.amount}</EmptyDiv>
                                                    <BestSeller className="simple">(₹ {item?.per_gram})</BestSeller>
                                                </Column>
                                            </Row>
                                        </React.Fragment>
                                    ))}
                                </SpecilityBox>
                                <FlexEnd>
                                    <PrimaryBtn name="" padding={8} wd={230} onClick={() => navigate('/checkout')} >PROCEED TO CHECKOUT</PrimaryBtn>
                                </FlexEnd>
                                <Hspace ht={10} />
                                <SpecilityBox>
                                    <Row className="center noGap">
                                        <SpHead>Max Shelf Life:-</SpHead>
                                        <EmptyDiv>{description?.max_shelf_life} {description?.shelf_duration}</EmptyDiv>
                                    </Row>
                                </SpecilityBox>


                                <SpecilityBox>
                                    <SpHead>Highlight</SpHead>
                                    <SpList>
                                        {description?.highlight.map((item) => <li key={item}>{item}</li>)}
                                    </SpList>
                                </SpecilityBox>

                                <SpecilityBox>
                                    <SpHead>Dietary Preference</SpHead>
                                    <SpList>
                                        {description?.dietary_preference.map((item) => <li key={item}>{item}</li>)}
                                    </SpList>
                                </SpecilityBox>

                                <SpecilityBox>
                                    <SpHead>Other Feature</SpHead>
                                    <SpList>
                                        {description?.other_feature.map((item) => <li key={item}>{item}</li>)}
                                    </SpList>
                                </SpecilityBox>

                                <SpecilityBox>
                                    <SpHead>Ingredients</SpHead>
                                    <SpList>
                                        {description?.ingredient.map((item) => <li key={item}>{item}</li>)}
                                    </SpList>
                                </SpecilityBox>

                                <Row>
                                    <EmptyDiv>Nutient Content:-</EmptyDiv>
                                    <EmptyDiv>
                                        {description?.nutritional_information.map((item) => <div key={item}>{item}</div>)}
                                    </EmptyDiv>
                                </Row>

                                <FlexEnd className="space-btn">
                                    {description?.spice_level != 'No' &&
                                        <ColumnEnd>
                                            <Row className="noGap">
                                                <NoOfChillies level={description?.spice_level} />
                                            </Row>
                                            <EmptyDiv>Spice Level</EmptyDiv>
                                        </ColumnEnd>
                                    }
                                    {/* <ColumnEnd>
                                        {rating !== null ? <ReactStars {...foodStar} /> : <EmptyDiv>Loading...</EmptyDiv>}
                                        <EmptyDiv>Out of five</EmptyDiv>
                                    </ColumnEnd> */}
                                    <ColumnEnd>
                                        <New>New</New>
                                        <EmptyDiv>Must try</EmptyDiv>
                                    </ColumnEnd>
                                </FlexEnd>
                                <FlexEnd>
                                    <EmptyDiv>
                                        <EmptyDiv>Share with friends</EmptyDiv>
                                        <Hspace />
                                        <>{navigator.share}</>
                                        <Row className="gap10">
                                            <IconBack className="clip share-button" onClick={Copy}><Icon name="shareUrl" /></IconBack>
                                            {/* <Share /> */}
                                        </Row>
                                    </EmptyDiv>
                                </FlexEnd>
                                <Hspace ht={20} />
                                {rating !== null ? <CustomerReviews ratings={rating} /> : <EmptyDiv>Loading...</EmptyDiv>}
                            </FLex1>
                        </ProductDetail>
                        <ProductDetail>
                        </ProductDetail>
                    </ProductDiv>
                </Container>
                : <DescriptionShimmer />
            }
            {selectedProd ?
                <Overlay>
                    <Modal title="Select item net weight" onClose={closeModal}>
                        <Row>
                            <ProductName>{selectedProd?.name}</ProductName>
                            <Icon name={selectedProd?.type == 'Veg' ? "veg" : "nonVeg"} />
                        </Row>
                        <Hspace />
                        <Column>
                            {selectedProd?.product_sku?.map((elm: any, i: number) => (
                                <Row className="j-sb noGap">
                                    <Row className="gap10">
                                        <input onClick={() => handleSku(elm)} name={elm?.sku_name} type="checkbox" value={elm?.sku_name} id={elm?.sku_name} checked={elm?.sku_id === selectSku?.sku_id} disabled={elm?.quantity === 0} onChange={() => { }} />
                                        <div style={{ fontSize: 12 }}>{elm?.sku_name} {elm?.units?.name}</div>
                                        <OutOfBox>{elm?.quantity === 0 ? "Out of stock" : elm?.is_best_seller === 1 ? <BestSeller><Icon name="tick" />Best Selling Item</BestSeller> : null}</OutOfBox>
                                    </Row>
                                    <div style={{ fontSize: 12 }}>{selectedProd?.discount.discount_value > 0 ? <s>₹{elm?.mrp}</s> : null} ₹{elm?.amount}</div>
                                </Row>
                            ))}
                            <Hline />
                            <PrimaryButn wd="100%" name="Add to cart" padding={10} fSize={16} isDisabled={selectSku === null} onClick={() => onAddtoCart(selectSku, selectedProd)} />
                        </Column>
                    </Modal>
                </Overlay> : null}

            {openNotify ? <Notification data={notifyProd} open={openNotify} setOpen={setopenNotify} /> : null}
            {data?.description?.id ? <ProdReviews productId={data?.description?.id} handleRating={handleRating} /> : null}
            {data?.description?.id ? <ProdRecom productId={data?.description?.id}/> : null}
            <Toast />
            <Footer />
        </Main >
    )
}

export default ProductDescription