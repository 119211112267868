import styled from 'styled-components';
import { whiteColor } from '../../styles';

export const ShareDialogContainer = styled.div`
  display: none;
  width: 95%;
  max-width: 500px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
  z-index: -1;
  border: 1px solid #ddd;
  padding: 20px;
  border-radius: 4px;
  background-color: #fff;

  &.is-open {
    display: block;
    z-index: 2;
  }
`;

export const Header = styled.header`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;

export const Targets = styled.div`
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  margin-bottom: 20px;
`;

export const CloseButton = styled.button`
  background-color: transparent;
  border: none;
  padding: 0;
`;

export const Link = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border-radius: 4px;
  background-color: #eee;
`;

export const PenUrl = styled.div`
  margin-right: 15px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const StyledButton = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: auto;
  padding-top: 8px;
  padding-bottom: 8px;
  color: #777;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.1;
  letter-spacing: 2px;
  text-transform: capitalize;
  text-decoration: none;
  white-space: nowrap;
  border-radius: 4px;
  border: 1px solid #ddd;
  cursor: pointer;

  &:hover {
    border-color: #cdd;
  }

  &.share-button,
  &.copy-link {
    padding-left: 30px;
    padding-right: 30px;
  }
`;


export const IconBack = styled.div`
display: flex;
align-items: center;
justify-content: center;
padding: 7px 13px;
border-radius: 4px;
cursor: pointer;

&.facebook {
    background-color: #3a5696;
}

&.twitter {
    background-color: #51a3de;
}

&.clip {
    background-color: #847e7e;
}

svg {
    height: 1em;
    path {
        fill: ${whiteColor};
    }
}
`