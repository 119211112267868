import moment from 'moment';
import { notify } from '../@services';

export const getFoodCategories = () => {
    let data: any = sessionStorage.getItem('food-categories')
    return JSON.parse(data)
}


export const capitalize = (param: string) => {
    if (!param) {
        return;
    } else {
        return param[0].toUpperCase() + param.slice(1);
    }
};


export const isLoggedIn = () => {
    if (localStorage.getItem('hmd_user_details') && localStorage.getItem('hmd_access_token')) {
        return true;
    } else {
        return false;
    }
}

export const userDetails = () => {
    if (localStorage.getItem('hmd_user_details')) {
        return JSON.parse(localStorage.getItem('hmd_user_details') as any)
    }
}

//time format
export const orderTimeAndDate = (dateStr?: string) => {
    return moment(dateStr, "DD-MMM-YYYY hh:mm:ss A").format("Do MMMM YYYY, hh:mm A");
}

export const annaAndDate = (dateStr?: string) => {
    return moment(dateStr, "DD-MMM-YYYY hh:mm:ss A").format("Do MMMM YYYY");
}

function generateRandomString(length: number): string {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
    let randomString = '';
    for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        randomString += characters.charAt(randomIndex);
    }
    return randomString;
}

function generateRandomDigits(length: number): string {
    const max = Math.pow(10, length) - 1;
    const randomNumber = Math.floor(Math.random() * max);
    return randomNumber.toString().padStart(length, '0');
}

export function generateTransactionNumber(): string {
    const randomChars = generateRandomString(2);
    const randomDigits = generateRandomDigits(16);
    const generatedString = randomChars.toUpperCase() + randomDigits;
    return generatedString;
}

export const toggleLogin = () => {
    document.getElementById('login')?.click();
}

export const notification = async (id?: number) => {
    if (isLoggedIn()) {
        let params: any = {
            "product_id": id,
            "name": userDetails()?.name,
            "email_id": userDetails()?.email,
            "mobile": userDetails()?.mobile
        }
        let response = await notify(params).then(async (res: any) => {
            if (res && res?.status == 200) {
                return res
            } else {
                return res?.response
            }
        })
        return response;
    }
}
