import { Helmet } from "react-helmet";

export const HomescreenMeta = ({ descption }) => {
    return (
        <Helmet>
            {/*<title>{descption?.title}</title>*/}
            
            {/*
            <meta name="og:title" content={descption?.meta_title} />
            <meta name="og:description" content={descption?.meta_description} />
            <meta name="og:type" content={descption?.type}/>
            <meta name="og:url" content={descption?.meta_site_url} />
            <meta name="og:site_name" content={descption?.meta_site_name} />
            <meta name="og:image" content={descption?.img_url} />

            <meta name="title" content={descption?.meta_title} />
            <meta name="description" content={descption?.meta_description} />
            <meta name="keyword" content={descption?.meta_keywords} />
            <meta name="author" content="HMD INDIA, hello@hmdindia.in"/>
            <meta name="url" content={descption?.meta_site_url} />

            <meta property="og:title" content={descption?.meta_title}/>
            <meta property="og:site_name" content={descption?.meta_site_name}/>
            <meta property="og:url" content={descption?.meta_site_url}/>
            <meta property="og:description" content={descption?.meta_description}/>
            <meta property="og:type" content={descption?.type}/>
            <meta property="og:image" content={descption?.img_url}/>
*/}

            {/*<script type="application/ld+json">
                {JSON.stringify({
                    "@context": "https://schema.org/",
                    "@type": "WebSite",
                    "name": "HMD INDIA",
                    "url": "https://www.hmdindia.in",
                    "potentialAction": {
                        "@type": "SearchAction",
                        "target": "https://www.hmdindia.in/product/hmd-red-chilli-pickle-pure-vegetarian-pure-organic-fssai-certified-long-durable-made-with-traditional-hand-made{search_term_string}",
                        "query-input": "required name=search_term_string"
                    }
                })}
            </script>*/}

        </Helmet>
    )
}

export const ProductDescriptionMeta = ({ description }) => {
    return (
        <Helmet>
            <title>{description?.meta_title}</title>
            
            <meta name="og:title" content={description?.meta_title} />
            <meta name="og:description" content={description?.meta_description} />
            <meta name="og:keyword" content={description?.meta_keyword} />
            <meta name="og_image" content={description?.image} />

            <meta name="title" content={description?.meta_title} />
            <meta name="description" content={description?.meta_description} />
            <meta name="keyword" content={description?.meta_keyword} />

            <meta property="og:title" content={description?.meta_title} />
            <meta property="og:image" content={description?.image} />
            <meta property="og:description" content={description?.meta_description} />
            <meta property="og:keyword" content={description?.meta_keyword} />



            <script type="application/ld+json">
                {JSON.stringify({
                    "@context": "https://schema.org/",
                    "@type": "Product",
                    "name": description?.meta_title,
                    "image": description?.image,
                    "description": description?.meta_description,
                    "brand": {
                        "@type": "Brand",
                        "name": "HMD"
                    },
                    "sku": description?.id
                })}
            </script>


        </Helmet>
    )
}

export const ContactMeta = () => {
    return (
        <Helmet>
            <title>Get in Touch for Exceptional Customer Support | HMD INDIA</title>

            <meta name="og:title" content="Contact Us | HMD India - Leading Supplier of Pure Vegetarian, Organic Products" />
            <meta name="og:description" content="Get in touch with HMD India, a trusted supplier of pure vegetarian and organic products. Reach out to us for inquiries, orders, or collaborations. Your source for FSSAI certified, long-lasting, traditional hand-made goods." />
            <meta name="og:keyword" content="Contact HMD India, contact us, customer support, reach out, assistance, contact information, customer service" />


            <meta name="title" content="Contact Us | HMD India - Leading Supplier of Pure Vegetarian, Organic Products" />
            <meta name="description" content="Get in touch with HMD India, a trusted supplier of pure vegetarian and organic products. Reach out to us for inquiries, orders, or collaborations. Your source for FSSAI certified, long-lasting, traditional hand-made goods." />
            <meta name="keyword" content="Contact HMD India, contact us, customer support, reach out, assistance, contact information, customer service" />

        </Helmet>
    )
}


export const AboutMeta = () => {
    return (
        <Helmet>
            <title>About Us | HMD INDIA</title>

            <meta name="og:title" content="About Us - Pure Organic, Handmade Excellence with Traditional Craftsmanship" />
            <meta name="og:description" content="Discover the essence of Hand-Made India, where tradition meets innovation. Learn about our commitment to preserving cultural heritage through exquisite handcrafted creations. Explore our story and passion for celebrating artisanal craftsmanship at www.hmdindia.in/about" />
            <meta name="og:keyword" content="Hand-Made India, about hmdindia, pure organic, handmade, traditional process, artisanal craftsmanship, organic products, handcrafted excellence" />


            <meta name="title" content="About Us - Pure Organic, Handmade Excellence with Traditional Craftsmanship" />
            <meta name="description" content="Discover the essence of Hand-Made India, where tradition meets innovation. Learn about our commitment to preserving cultural heritage through exquisite handcrafted creations. Explore our story and passion for celebrating artisanal craftsmanship at www.hmdindia.in/about" />
            <meta name="keyword" content="Hand-Made India, about hmdindia, pure organic, handmade, traditional process, artisanal craftsmanship, organic products, handcrafted excellence" />

        </Helmet>



    )
}

export const AddressMeta = () => {
    return (
        <Helmet>
            <title>Manage Your Addresses | HMD INDIA</title>

            <meta name="og:title" content="Address Management - Create, Edit, and Delete Multiple Addresses Effortlessly" />
            <meta name="og:description" content="Explore our address management page for a seamless experience in creating, editing, and deleting multiple addresses. Take control of your account and personalize your shipping destinations with ease." />
            <meta name="og:keyword" content="address management, multiple addresses, edit addresses, delete addresses, shipping destinations, personalized addresses" />


            <meta name="title" content="Address Management - Create, Edit, and Delete Multiple Addresses Effortlessly" />
            <meta name="description" content="Explore our address management page for a seamless experience in creating, editing, and deleting multiple addresses. Take control of your account and personalize your shipping destinations with ease." />
            <meta name="keyword" content="address management, multiple addresses, edit addresses, delete addresses, shipping destinations, personalized addresses" />


        </Helmet>


        
    )
}

export const BlogMeta = () => {
    return (
        <Helmet>
            <title>Blog and Latest News Updates | HMD INDIA</title>

            <meta name="og:title" content="Blog and Latest News - Stay Updated with Trends and Insights" />
            <meta name="og:description" content="Explore our blog for insightful articles and stay informed with the latest news in our industry. Read about trends, innovations, and valuable information to enhance your knowledge and stay ahead. Stay connected with our updates!" />
            <meta name="og:keyword" content="blog, latest news, industry insights, trends, news updates, informative articles" />

            <meta name="title" content="Blog and Latest News - Stay Updated with Trends and Insights" />
            <meta name="description" content="Explore our blog for insightful articles and stay informed with the latest news in our industry. Read about trends, innovations, and valuable information to enhance your knowledge and stay ahead. Stay connected with our updates!" />
            <meta name="keyword" content="blog, latest news, industry insights, trends, news updates, informative articles" />

        </Helmet>


        
    )
}



export const AnnasMeta = () => {
    return (
        <Helmet>
            <title>Track Your Coins | HMD INDIA</title>

            <meta name="og:title" content="My Coin History - Monitor Coin Additions and Expiry Dates" />
            <meta name="og:description" content="Visit our 'My Coin' page to view the comprehensive history of your coins, including when they were added and their expiry dates. Stay informed about your loyalty rewards with ease." />
            <meta name="og:keyword" content="my coin, loyalty rewards, coin history, expiry dates, reward program, loyalty program" />

            <meta name="title" content="My Coin History - Monitor Coin Additions and Expiry Dates" />
            <meta name="description" content="Visit our 'My Coin' page to view the comprehensive history of your coins, including when they were added and their expiry dates. Stay informed about your loyalty rewards with ease." />
            <meta name="keyword" content="my coin, loyalty rewards, coin history, expiry dates, reward program, loyalty program" />

        </Helmet>


        
    )
}



export const OrderMeta = () => {
    return (
        <Helmet>
            <title>Manage Your Orders | HMD INDIA</title>

            <meta name="og:title" content="Order Display - Track and Manage Your Orders with Ease" />
            <meta name="og:description" content="Visit our order display page to effortlessly track and manage your orders. Get detailed insights into your purchase history, delivery status, and more. Take control of your shopping experience." />
            <meta name="og:keyword" content="order display, order tracking, manage orders, purchase history, order status, tracking information" />

            <meta name="title" content="Order Display - Track and Manage Your Orders with Ease" />
            <meta name="description" content="Visit our order display page to effortlessly track and manage your orders. Get detailed insights into your purchase history, delivery status, and more. Take control of your shopping experience." />
            <meta name="keyword" content="order display, order tracking, manage orders, purchase history, order status, tracking information" />

        </Helmet>


        
    )
}



export const PolicyMeta = () => {
    return (
        <Helmet>
            <title>Explore Our Privacy Policy for Peace of Mind | HMD INDIA</title>

            <meta name="og:title" content="Privacy Policy - Your Guide to Data Protection and Security" />
            <meta name="og:description" content="Read our privacy policy to understand how we safeguard your information. Your privacy matters to us, and our policies ensure a secure and transparent interaction. Explore for peace of mind." />
            <meta name="og:keyword" content="privacy policy, data protection, information security, user privacy, data safety, privacy agreement" />

            <meta name="title" content="Privacy Policy - Your Guide to Data Protection and Security" />
            <meta name="description" content="Read our privacy policy to understand how we safeguard your information. Your privacy matters to us, and our policies ensure a secure and transparent interaction. Explore for peace of mind." />
            <meta name="keyword" content="privacy policy, data protection, information security, user privacy, data safety, privacy agreement" />

            
        </Helmet>


        
    )
}



export const MyProfileMeta = () => {
    return (
        <Helmet>
            <title>My Profile | HMD INDIA</title>

            <meta name="og:title" content="My Profile - Display and Update Your Information with Convenience" />
            <meta name="og:description" content="Visit our 'My Profile' page to effortlessly display and update your personal information. Take control of your account details and ensure they reflect your latest preferences. Personalization made easy!" />
            <meta name="og:keyword" content="my profile, update information, account details, personalized settings, user profile, account customization" />

            <meta name="title" content="My Profile - Display and Update Your Information with Convenience" />
            <meta name="description" content="Visit our 'My Profile' page to effortlessly display and update your personal information. Take control of your account details and ensure they reflect your latest preferences. Personalization made easy!" />
            <meta name="keyword" content="my profile, update information, account details, personalized settings, user profile, account customization" />

            
        </Helmet>


        
    )
}



export const ReturnPolicyMeta = () => {
    return (
        <Helmet>
            <title>Explore Our Return and Shipping Policies | HMD INDIA</title>

            <meta name="og:title" content="Return and Shipping Policies - Your Guide to a Hassle-Free Shopping Journey" />
            <meta name="og:description" content=" Familiarize yourself with our return and shipping policies for a worry-free shopping experience. Learn about our processes, timelines, and conditions to make informed decisions. Your satisfaction is our priority." />
            <meta name="og:keyword" content=" return policy, shipping policy, return guidelines, shipping information, hassle-free returns, customer satisfaction" />

            <meta name="title" content="Return and Shipping Policies - Your Guide to a Hassle-Free Shopping Journey" />
            <meta name="description" content=" Familiarize yourself with our return and shipping policies for a worry-free shopping experience. Learn about our processes, timelines, and conditions to make informed decisions. Your satisfaction is our priority." />
            <meta name="keyword" content=" return policy, shipping policy, return guidelines, shipping information, hassle-free returns, customer satisfaction" />
            
        </Helmet>


        
    )
}


export const TermsMeta = () => {
    return (
        <Helmet>
            <title>Read Our Terms and Conditions | HMD INDIA</title>

            <meta name="og:title" content="Terms and Conditions - Read Our Policies for a Smooth Interaction" />
            <meta name="og:description" content="Explore our terms and conditions page to ensure a seamless experience. Familiarize yourself with our policies to understand the guidelines that govern your interaction with us. A must-read for informed customers." />
            <meta name="og:keyword" content="terms and conditions, policies, guidelines, customer agreement, terms of service, user agreement" />

            <meta name="title" content="Terms and Conditions - Read Our Policies for a Smooth Interaction" />
            <meta name="description" content="Explore our terms and conditions page to ensure a seamless experience. Familiarize yourself with our policies to understand the guidelines that govern your interaction with us. A must-read for informed customers." />
            <meta name="keyword" content="terms and conditions, policies, guidelines, customer agreement, terms of service, user agreement" />

        </Helmet>


        
    )
}



export const CheckoutMeta = () => {
    return (
        <Helmet>
            <title>Complete Your Order With Secure Checkout | HMD INDIA</title>

            


        </Helmet>


        
    )
}