import { darkBorder, grey, lightGrey, mediumBorde } from "../../styles";
import styled from "styled-components";

export const Card = styled.div`
display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px;
    border-radius: 8px;

    ${({ bdrStyl }: { bdrStyl?: string }) => {
        if (bdrStyl) {
            return `border: 1px ${bdrStyl} ${mediumBorde};`
        } else {
            return `border: 1px solid ${mediumBorde};`
        }
    }}

    &.add {
        align-items: center;
        justify-content: center;
    }
`

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
//   justify-content: space-between;

&.gap20 {
    gap: 20px;
}
  `

export const Address = styled.div`
  margin-bottom: 1rem;
    font-style: normal;
    line-height: 25px;
  `

export const NewBoxAlign = styled.div`
  text-align: center;
  `

export const AddText = styled.div`
  color: rgb(67, 67, 67);
    font-size: 0.875rem;
    letter-spacing: 0.1875rem;
    font-weight: 500;
    text-transform: uppercase;
    margin: 0.875rem 0px 0;
  `

export const AddButton = styled.div`
  display: inline-block;
    text-transform: uppercase;
    font-size: 0.875rem;
    font-weight: 500;
    cursor: pointer;
    padding: 0.375rem 1.125rem;
    border-width: 0.0625rem;
    border-style: dashed;
    border-color: rgb(45, 40, 46);
    border-image: initial;
    color: #777;
  `