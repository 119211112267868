import { EmptyDiiv, green, Hspace } from "../../styles"
import { Conteiner, Product, ProductRow, Image, ProductName, Add, Offer, OutOfStock, Relative, Popup, Text } from "./product-card-components"
import Img from '../../assets/mini.webp';
import { Icon, PrimaryButn } from '../../components'
import React, { useState } from "react";
import ReactStars from "react-rating-stars-component";

type Props = {
    name?: string;
    onAddtoCart?: any;
    offer?: any;
    stock?: boolean;
    onCardClick: any;
    image?: string;
    discount?: any;
    quantity?: any;
    pType?: any;
    isItemInCart?: boolean;
    item?: any;
    reviews?: any
    notify?: any;
    notifyLoader?: boolean
}

const ProductCard = ({ name, onAddtoCart, offer, stock, onCardClick, image, discount, quantity, pType, isItemInCart, item, reviews, notify, notifyLoader }: Props) => {
    const defaultSku = item?.product_sku.find(elem => elem.is_default === 1)

    const starProps = {
        size: 10,
        count: 1,
        color: "black",
        activeColor: `green`,
        a11y: true,
        isHalf: true,
        emptyIcon: <Icon name="emptyStar" />,
        halfIcon: <Icon name="halfStar" />,
        filledIcon: <Icon name="filledStar" />,
        classNames: '',
        edit: false,
    };

    return (
        <React.Fragment>
            <Conteiner onClick={onCardClick}>
                <Relative>
                    <Image src={image || Img} />
                    <Product>
                        <ProductRow>
                            <ProductName>{name}</ProductName>
                            <Icon name={pType == 'Veg' ? "veg" : "nonVeg"} />
                        </ProductRow>
                        <Hspace ht={5} />
                        <ProductRow className="a-fs">
                            <Text>{reviews[0]?.rating}
                                <ReactStars {...starProps}
                                    value={4}
                                    onChange={(e: any) => { }}>
                                </ReactStars>
                            </Text>
                            <Text>{reviews[0]?.total_reviews} Ratings</Text>
                        </ProductRow>
                        <Hspace ht={10} />
                        <ProductRow>
                            <ProductName className="price">
                                {discount.discount_value > 0 ? <s>₹{defaultSku?.mrp}</s> : null} ₹{defaultSku?.amount}
                            </ProductName>
                            {!item?.out_of_stock ?
                                <Add disabl={isItemInCart} onClick={(e) => onAddtoCart(e)}>{isItemInCart ? "Added" : "Add"}</Add> :
                                <Add onClick={(e) => notify(e)}>{notifyLoader ? "Loading.." : "Notify"}</Add>
                            }
                        </ProductRow>
                    </Product>
                </Relative>
                {/*{!item?.out_of_stock && discount.discount_value > 0 ? <Offer>{discount.type == 'Percent' ? `${discount.discount_value}%` : `Rs. ${discount.discount_value}`} off</Offer> : null}*/}
                {item?.out_of_stock && <OutOfStock>Out of Stock</OutOfStock>}
            </Conteiner>
        </React.Fragment>
    )
}

export default ProductCard