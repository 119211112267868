import { brown, darkBorder, lightFont, redish } from "../../styles";
import styled from "styled-components";

export const FormCOntainer = styled.div`
  // transform: translate(-50%, 3%);
  position: relative;
  background: #ffffff;
  border-radius: 5px;
  box-shadow: 0px 4px 8px 2px #111;
  padding: 8px;
  max-width: 600px;
  width: 100%;
  margin: 0px 20px;

  @media only screen and (max-width: 767px) {
    max-width: 400px;
  }
`;

export const InnerContainer = styled.div`
  border: 3px solid rgb(181 33 33);
  padding: 8px;
  width: 100%;
`;

export const Inner = styled.div`
  border: 3px solid rgb(181 33 33);
  padding: 0px 20px;

  @media only screen and (max-width: 767px) {
    padding: 0px 6px;
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 2px 10px;

  &.flex-1 {
    flex: 1;
  }

  &.flex-2 {
    flex: 2;
  }

  &.gap10 {
    gap: 10px;
  }

  &.gap30 {
    gap: 30px;
  }
`;

export const Label = styled.label`
  width: 100%;
  text-align: left;
  font-size: 12px;
  margin-bottom: 5px;
  color: ${lightFont};
`;

export const Input = styled.input`
width: 100%;
    border-radius: 5px;
    box-shadow: none;
    border: 1px solid rgb(220, 220, 220);
    padding: 8px 6px;
    font-size 15px;
    outline: none;
    // background-color: rgb(250, 250, 250);

    &:focus {
        border: 1px solid ${darkBorder};
    }
`;

export const Div = styled.div``;

export const Close = styled.div`
  font-weight: bold;
  font-size: 20px;
  position: absolute;
  background-color: black;
  padding: 4px 8px;
  border-radius: 35px;
  color: white;
  top: -5px;
  right: -5px;
  border: 2px solid ${brown};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &:hover {
    color: ${redish};
  }
`;
export const Center = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  &.gap15 {
    gap: 15px;
  }
`;

export const AddressLocation = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  width: 100%;
`;

export const Location = styled.div`
  padding: 10px 10px;
  border: 1px solid grey;
  text-align: center;

  ${({ active }: { active?: boolean }) => {
    if (active) {
      return `background-color: ${redish};
            color: white;
            border: none;`;
    }
  }}
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 20px;
`;

export const EditTextContainer = styled.div`
  // max-width: 200px;
  /* width: 100%; */
  font-size: 22px;
  font-weight: 500;
  text-align: center;
  img {
    vertical-align: top;
    width: 100%;
  }
`;
