export const inline = { marginLeft: 60, display: "inline" };
export const data = [
  {
    id: 0,
    title: "Online Store Terms",
    text: (
      <div>
        <br />
        <div style={inline}>By</div> agreeing to these Terms of Service, you
        represent that you are at least the legal age of majority in your state
        or province of residence, or that you are the legal age of majority in
        your state or province of residence and have given us permission to
        allow any of your minor dependents to use this site.
        <br />
        <div style={inline}>You</div> may not use our products for any illegal
        or unauthorized purpose, and you may not violate any laws in your
        jurisdiction by using the Service (including but not limited to
        copyright laws). You are not permitted to send any worms, viruses, or
        destructive code. Any violation of the Terms will result in the
        immediate termination of your Services.
      </div>
    ),
  },
  {
    id: 1,
    title: "General Conditions",
    text: (
      <div>
        <br />
        <div style={inline}>We</div> reserve the right to refuse service to
        anyone at any time for any reason. You acknowledge that your content
        (excluding credit card information) may be transferred unencrypted and
        may involve Transmissions over various networks and Changes to conform
        and adapt to the technical requirements of connecting networks or
        devices. Credit card information is always encrypted during network
        transfers.
        <br />
        <div style={inline}>&#8226; Transmissions</div> over various networks
        and
        <br />
        <div style={inline}>&#8226; Changes </div> to conform and adapt to the
        technical requirements of
        <br />
        connecting networks or devices. Credit card information is always
        encrypted during network transfers. Without our express written
        permission, you agree not to reproduce, duplicate, copy, sell, resell,
        or exploit any portion of the service, use the service, or access the
        service or any contact on the website through which the service is
        provided. The headings in this agreement are included for convenience
        only and have no bearing on the terms.
      </div>
    ),
  },
  {
    id: 2,
    title: `Accuracy, Completeness, and Timeliness Of Information`,
    text: (
      <div>
        <br />
        <div style={inline}>We</div> are not liable if the information on this
        site is not accurate, complete, or up to date. The information on this
        website is for general information purposes only and should not be
        relied on or used as the sole basis for making decisions without
        consulting primary, more accurate, more complete, or more timely sources
        of information. Any reliance on the information on this website is
        entirely at your own risk.
        <br />
        <div style={inline}>This website</div> may contain historical
        information. Historical information is, by definition, out of date and
        is provided solely for your convenience. We reserve the right to change
        the contents of this site at any time, but we are under no obligation to
        keep any information on our site up to date. You acknowledge that it is
        your responsibility to keep track of changes to our website.
      </div>
    ),
  },
  {
    id: 3,
    title: "Modifications Of The Service And Prices",
    text: (
      <div>
        <br />
        Our product prices are subject to change without notice. We reserve the
        right to modify or discontinue the Service (or any part or content
        thereof) at any time and without notice. We will not be liable to you or
        any third party if the service is modified, priced, suspended, or
        discontinued.
      </div>
    ),
  },
  {
    id: 4,
    title: "Products For Services (If Applicable)",
    text: (
      <div>
        <br />
        <div style={inline}>Certain</div> products or services may only be
        available through the website. These products or services may be in
        limited supply and are only returnable or exchangeable in accordance
        with our return policy.
        <br />
        <div style={inline}>We have</div> made every effort to display the
        colors and images of our products that appear in the store as accurately
        as possible. We cannot guarantee that the colors displayed on your
        computer monitor will be accurate.
        <br />
        <div style={inline}>We</div> reserve the right, but are under no
        obligation, to limit the sale of our products or services to any
        individual, geographic region, or jurisdiction. We may exercise this
        right on an individual basis. We reserve the right to limit the quantity
        of any of our products or services. All product descriptions and pricing
        are subject to change at any time without notice, at our sole
        discretion. At any time, we reserve the right to discontinue any
        product. Any offer made on this site for any product or service is void
        where prohibited.
        <br />
        <div style={inline}>We</div> make no guarantee that the quality of any
        products, services, information, or other material purchased or obtained
        by you will meet your expectations, nor do we guarantee that any errors
        in the Service will be corrected.
      </div>
    ),
  },
  {
    id: 5,
    title: "Accuracy Of Billing And Account Information",
    text: (
      <div>
        <br />
        <div style={inline}>We</div> reserve the right to reject any order
        placed with us. We reserve the right, at our sole discretion, to limit
        or cancel the number of items purchased per person, per household, or
        per order. Orders placed by or under the same customer account, credit
        card, and/or the billing and/or shipping address may be subject to these
        restrictions.
        <br />
        <div style={inline}>If we</div> change or cancel an order, we may
        attempt to notify you by contacting the email address and/or billing
        address/phone number provided at the time the order was placed. We
        reserve the right to limit or prohibit orders that appear to be placed
        by dealers, resellers, or distributors, at our sole discretion.
        <br />
        <div style={inline}>For</div> all purchases made at our store, you agree
        to provide current, complete, and accurate purchase and account
        information. You agree to keep your account and other information up to
        date, including your email address, credit card numbers, and expiration
        dates so that we can complete transactions and contact you as needed.
        Please see our Returns Policy for more information.
      </div>
    ),
  },
  {
    id: 6,
    title: "Optional Tools",
    text: (
      <div>
        <br />
        <div style={inline}>We</div> may provide you with access to third-party
        tools over which we have no control or input. You acknowledge and agree
        that we provide such tools "as is" and "as available," with no
        warranties, representations, or conditions of any kind, and without any
        endorsement. We accept no liability arising from or related to your use
        of optional third-party tools.
        <br />
        <div style={inline}>Any</div> use of optional tools offered through the
        site is entirely at your own risk and discretion, and you should ensure
        that you are familiar with and approve of the terms under which the
        relevant third-party provider provides the tool(s). We may also offer
        new services and/or features on the website in the future (including,
        the release of new tools and resources). These Terms of Service will
        apply to any new features and/or services.
      </div>
    ),
  },
  {
    id: 7,
    title: "Third-Party Links",
    text: (
      <div>
        <br />
        <div style={inline}>Third-party</div> materials may be included in
        certain content, products, and services available through our service.
        Third-party links on this site may take you to websites that are not
        associated with us. We are not responsible for examining or evaluating
        the content or accuracy of third-party materials or websites, nor do we
        warrant or accept liability or responsibility for any third-party
        materials, products, or services.
        <br />
        <div style={inline}>We</div> are not liable for any harm or damages
        resulting from the purchase or use of goods, services, resources,
        content, or any other transactions made through any third-party
        websites. Please carefully review and understand the third party's
        policies and practices before engaging in any transaction. Third-party
        product complaints, claims, concerns, or questions should be directed to
        the third party.
      </div>
    ),
  },
  {
    id: 8,
    title: "User Comments, Feedback, And Other Submissions",
    text: (
      <div>
        <br />
        <div style={inline}>If</div> you send us creative ideas, suggestions,
        proposals, plans, or other materials (collectively, "comments"), whether
        online, by email, by postal mail, or otherwise (collectively,
        "comments"), you agree that we may, at any time, without restriction,
        edit, copy, publish, distribute, translate, and otherwise use any
        comments that you forward to us in any medium. We are and shall be under
        no obligation to:
        <br />
        <div style={inline}>&#8226; Keep</div> any comments confidential,
        <br />
        <div style={inline}>&#8226; Compensate</div> for any comments or
        <br />
        <div style={inline}>&#8226; Respond</div> to any comments.
        <br />
        <div style={inline}>We</div> may, but are under no obligation to,
        monitor, edit, or remove any content that we deem to be unlawful,
        offensive, threatening, libelous, defamatory, pornographic, obscene, or
        otherwise objectionable, or that violates any party's intellectual
        property or these Terms of Service.
        <br />
        <div style={inline}>You</div> agree that your comments will not infringe
        on any third-party rights, including copyright, trademark, privacy,
        personality, or any other personal or proprietary right. You also agree
        that your comments will not contain libelous or otherwise unlawful
        material, nor will they be abusive or obscene, nor will they contain any
        computer virus or other malware that could interfere with the operation
        of the service or any related website.
        <br />
        <div style={inline}>You</div> are not permitted to use a false email
        address, pretend to be someone other than yourself, or otherwise mislead
        us or third-parties as to the origin of any comments. You are solely
        responsible for the accuracy of any comments you make. We accept no
        responsibility or liability for any comments made by you or any third
        party.
      </div>
    ),
  },
  {
    id: 9,
    title: "Personal Information",
    text: (
      <div>
        <br />
        <div style={inline}>Our</div> Privacy Policy governs your submission of
        personal information through the store. View our{" "}
        <a href="/policy">Privacy Policy.</a>
      </div>
    ),
  },

  {
    id: 10,
    title: "Errors, Inaccuracies, And Omissions",
    text: (
      <div>
        <br />
        <div style={inline}>On</div> occasion, information on our website or in
        the Service may contain typographical errors, inaccuracies, or omissions
        relating to product descriptions, pricing, promotions, offers, product
        shipping charges, transit times, and availability. We reserve the right,
        at any time and without prior notice, to correct any errors,
        inaccuracies, or omissions, and to change or update information or
        cancel orders if any information in the Service or on any related
        website is inaccurate (including after you have submitted your order).
        <br />
        <div style={inline}>Except</div> as required by law, we make no
        commitment to update, amend, or clarify information in the Service or on
        any related website, including pricing information. There is no specific
        update or refresh date applied in the Service or on any related website
        that should be interpreted as indicating that all information in the
        Service or on any related website has been modified or updated.
      </div>
    ),
  },

  {
    id: 11,
    title: "Prohibited Sites",
    text: (
      <div>
        <br />
        <div style={inline}>You</div> are not permitted to use the site or its
        content, in addition to the other prohibitions stated in the Terms of
        Service:.
        <br />
        <div style={inline}>&#8226; For</div> any unlawful purpose.
        <br />
        <div style={inline}>&#8226; To</div> solicit others to perform or
        participate in any unlawful acts.
        <br />
        <div style={inline}>&#8226; To</div> violate any international, federal,
        provincial, or state regulations, rules, laws, or local ordinances.
        <br />
        <div style={inline}>&#8226; To</div> infringe or violate our
        intellectual property rights or the intellectual property rights of
        others; and
        <br />
        <div style={inline}>&#8226; To</div> harass, abuse, insult, harm,
        defame, slander, disparage, intimidate
        <br />
        <div style={inline}>&#8226; Or</div> discriminate on the basis of
        gender, sexual orientation, religion, ethnic
        <br />
        <div style={inline}>&#8226; To</div> submit false or misleading data
        <br />
        <div style={inline}>&#8226; To</div> upload or transmit viruses or other
        types of malicious code that will or may be used in any way that will or
        may be used to affect the functionality or operation of the Service or
        any related website, other websites, or the Internet
        <br />
        <div style={inline}>&#8226; To</div> collect or track the personal
        information of others
        <br />
        <div style={inline}>&#8226; To</div> spam, phish, pharm, pretext,
        spider, crawl, or scrape
        <br />
        <div style={inline}>&#8226; For</div> any obscene or immoral purpose
        <br />
        <div style={inline}>&#8226; To</div> interfere with or circumvent or
        violate any of the prohibited uses, we reserve the right to terminate
        your use of the Service or any related website.
        <br />
      </div>
    ),
  },

  {
    id: 12,
    title: "Indemnification",
    text: (
      <div>
        <br />
        <div style={inline}>You</div> agree to indemnify, defend, and hold
        Hmd India and our parent, subsidiaries, affiliates, partners, officers,
        directors, agents, contractors, licensors, service providers,
        subcontractors, suppliers, interns, and employees harmless from any
        claim or demand made by any third party due to or arising out of your
        breach of these Terms of Service or the documents they incorporate by
        reference or your violation of any law.
      </div>
    ),
  },
  {
    id: 13,
    title: "Severability",
    text: (
      <div>
        <br />
        <div style={inline}>If</div> any provision of these Terms of Service is
        found to be unlawful, void, or unenforceable, such provision shall still
        be enforceable to the fullest extent permitted by applicable law, and
        the unenforceable portion shall be deemed to be severed from these Terms
        of Service; however, such determination shall not affect the validity
        and enforceability of any other remaining provisions.
      </div>
    ),
  },
  {
    id: 14,
    title: "Termination",
    text: (
      <div>
        <br />
        <div style={inline}>For</div> all practical purposes, the parties'
        obligations and liabilities incurred prior to the termination date will
        survive the termination of this agreement. These Terms of Service are in
        effect until either you or we terminate them. You may terminate these
        Terms of Service at any time by notifying us of your desire to no longer
        use our services or by discontinuing the use of our website.
        <br />
        <div style={inline}>If</div> you fail to comply with any term or
        provision of these Terms of Service, or we suspect you have failed to
        comply, we may terminate this agreement at any time without notice, and
        you will remain liable for all amounts due up to and including the date
        of termination; and/or we may deny you access to our Services (or any
        part thereof).
      </div>
    ),
  },

  {
    id: 15,
    title: "Entire Agreement ",
    text: (
      <div>
        <br />
        <div style={inline}>We</div> shall not be deemed to have waived any
        right or provision of these Terms of Service if we fail to exercise or
        enforce such right or provision.
        <br />
        <div style={inline}>These</div> Terms of Service, together with any
        policies or operating rules posted by us on this site or in relation to
        The Service, constitute the entire agreement and understanding between
        you and us and govern your use of the Service, superseding any prior or
        contemporaneous agreements, communications, and proposals, whether oral
        or written, between you and us (including, but not limited to, any prior
        versions of the Terms of Service).
        <br />
        <div style={inline}>Any</div> ambiguities in the interpretation of these
        Terms of Service shall not be held against the party who drafted them.
      </div>
    ),
  },

  {
    id: 16,
    title: "Governing Law ",
    text: (
      <div>
        <br />
        <div style={inline}>We</div> These Terms of Service, as well as any
        separate agreements under which we provide you Services, will be
        governed and construed in accordance with Indian law.
      </div>
    ),
  },

  {
    id: 17,
    title: "Changes To Terms Of Service",
    text: (
      <div>
        <br />
        <div style={inline}>The</div> most recent version of the Terms of
        Service can be found on this page at any time. We reserve the right to
        update, change, or replace any part of these Terms of Service at our
        sole discretion by posting updates and changes to our website. It is
        your responsibility to check our website for changes on a regular basis.
        Your continued use or access to our website or the Service after any
        changes to these Terms of Service are posted constitutes acceptance of
        those changes.
      </div>
    ),
  },

  {
    id: 18,
    title: "Contact Information ",
    text: (
      <div>
        <br />
        <div style={inline}>All</div> queries about the Terms Of Service will be
        sent to <a href="mailto:hello@hmdindia.in">hello@hmdindia.in</a>
      </div>
    ),
  },
];
