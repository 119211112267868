import React, { useEffect, useState } from 'react'
import { getCommentsById, getProductReviews } from '../../@services'
import { Card, CardHolder, Comment, Container, Heading, PaginationDiv, Row, Text } from './blogs-comments-components'
import ReactStars from "react-rating-stars-component";
import { Icon, Paginate, ReviewsShimmer } from '../../components';
import { orderTimeAndDate, userDetails } from '../../utils';
import { Hline } from '../../styles';
import { capitalize } from '../../utils';
import { toast } from 'react-toastify';

const BlogComment = ({ blogId }: any) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [comments, setComments] = useState<any>([]);
    const [page, setPage] = useState<number>(1)

    const getComments = async () => {
        setLoading(true)
        let param: any = {
            "blog_id": blogId,
            page: page
        }
        await getCommentsById(param).then((res: any) => {
            if (res && res?.status) {
                // console.log(res?.data?.data)
                setComments(res?.data?.data);
                setLoading(false)
            } else {
                console.log(res?.response?.data)
                setLoading(false)
            }
        })
    }

    const handlePageChange = async (e: any, pageNo: number) => {
        setLoading(true)
        setPage(pageNo);
        let params: any = {
            product_id: blogId,
            page: pageNo
        }
        await getProductReviews(params).then((res: any) => {
            if (res && res?.status) {
                // console.log(res?.data?.data)
                setComments(res?.data?.data);
                setLoading(false)
            } else {
                toast(res?.response?.data?.message)
                setLoading(false)
            }
        })
    }

    useEffect(() => {
        if (blogId) {
            getComments();
        }
    }, [])

    if (!loading && (!comments?.result?.data || comments?.result?.data?.length <= 0)) {
        return <div></div>
    } else {
        return (
            <Container>
                <Heading>Blog Comments</Heading>
                {!loading ? <CardHolder>
                    {comments?.result?.data?.map((item: any, index: number) => (
                        <Card>
                            <Text>{capitalize(item?.user?.name)}</Text>
                            <Comment>{item?.comment}</Comment>
                            <Hline />
                            <Row className='j-sb'>
                                <Text className='sm grey'>{orderTimeAndDate(item?.created_at)}</Text>
                            </Row>
                        </Card>
                    ))}
                </CardHolder> : <ReviewsShimmer />}
                {!loading && <PaginationDiv>
                    <Paginate totalPage={Math.ceil(comments?.total / 10)}
                        page={page} setPage={setPage} onPageChange={handlePageChange} />
                </PaginationDiv>}
            </Container>
        )
    }

}

export default BlogComment