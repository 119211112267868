import { darkBorder, lightFont } from "../../styles";
import styled from "styled-components";
export const Container = styled.div`
  height: 100%;
  overflow: hidden;
`;
export const PageContainer = styled.div`
  height: 100%;
`;
export const PageContent = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
`;

export const FlexedContainer = styled.div`
  flex: 1;
  overflow: hidden;
  .memberSelect {
    padding-bottom: 30px;
  }
`;

export const PageContentWrapper = styled.div`
  height: calc(100vh - 200px);
  overflow: auto;
`;

//auth
export const Input = styled.input`
width: 100%;
    border-radius: 5px;
    box-shadow: none;
    border: 1px solid rgb(220, 220, 220);
    padding: 8px 6px;
    font-size 15px;
    outline: none;
    // background-color: rgb(250, 250, 250);

    &:focus {
        border: 1px solid ${darkBorder};
    }
`

export const Label = styled.label`
width: 100%;
    text-align: left;
    font-size: 12px;
    margin-bottom: 5px;
    color: ${lightFont};
`

export const Div = styled.div`
display: flex;
flex-direction: column;
gap: 20px;
margin: 20px;
// align-items: flex-end;
`