import { whiteColor } from "../../styles";
import styled from "styled-components";

export const IconDiv = styled.div`
border-radius: 40px;
width: 29px;
background-color: ${whiteColor};
height: 32px;
width: 32px;
display: flex;
align-items: center;
justify-content: center;
z-index: 50;

&.right {
    // margin: 0px 10px 0px 0px;
    svg {
        transform: rotate(180deg);
    }
};


&:disabled {
    background: unset;
    font-style: normal;
    opacity: 1;
    color: black;
    border-color: rgb(207, 207, 207);
    cursor: default;
};

&:focus {
    background: unset;
};

&:hover {
    background-color: ${whiteColor};
};

&::before {
    content: "\f104";
    font-size: 19px;
    opacity: 1;
    color: #222;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0%);
};

// &:disabled {
//     background: black !important;
//     cursor: default !important;
// }
`