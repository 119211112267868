//@ts-nocheck
import { AboutShimmer, Footer, Header, Icon } from "../../components";
import { AboutMem, Castle, CastleText, Column, Container, EmptyDiv, IconDiv, Item, Main, MoreButton, Row, SlickContainer } from "./about-componets";
import Line from '../../assets/line.png'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Job from '../../assets/Job_Categories-s.jpg'
import './about.css'
import { Hspace } from "../../styles";
import { jobs_array } from "./data";
import { getTeams } from "../../@services";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import parse from 'html-react-parser';
import { AboutMeta } from "../../utils";




const About = () => {
    const [data, setData] = useState<any>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [currentSlide, setCurrentSlide] = useState(0);
    const Prev = ({ className, style, onClick, disabled }) => {
        return (
            <IconDiv style={{ display: currentSlide == 0 ? "none" : "" }} className={className} onClick={onClick}>
                <Icon name="backArrow" />
            </IconDiv>
        )
    }

    const Next = ({ className, style, onClick, disabled }) => {
        const totalSlides = data?.length
        return (
            <IconDiv style={{ display: currentSlide === totalSlides - 1 ? "none" : "" }} className={`${className} right`} onClick={onClick}>
                <Icon name="backArrow" />
            </IconDiv>
        );
    };

    const settings = {
        className: "slick-about",
        dots: false,
        slidesToShow: 4,
        slidesToScroll: 1,
        arrow: true,
        infinite: false,
        prevArrow: <Prev onClick={() => setCurrentSlide(currentSlide - 1)} />,
        nextArrow: <Next onClick={() => setCurrentSlide(currentSlide + 1)} />,
        afterChange: (index) => setCurrentSlide(index),
        focusOnSelect: false,
        responsive: [
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    autoplay: false,
                }
            }
        ]
    };

    const getTeamMember = async () => {
        setLoading(true)
        await getTeams().then((res: any) => {
            if (res && res.status) {
                setData(res?.data?.data);
                setLoading(false)
            } else {
                toast(res?.response?.data?.message);
                setLoading(false)
            }
        })
    }

    useEffect(() => {
        getTeamMember()
    }, [])

    return (
        <Main>
            
            <AboutMeta />
            <Header />
            <Container>
                <Castle>
                    <img width='200px' src={Line} alt="" />
                    <CastleText>ABOUT US</CastleText>
                    <img width='200px' src={Line} alt="" />
                </Castle>
                <Column>
                    <EmptyDiv className="line">
                        At HMD INDIA, our commitment to quality and sustainability is at the heart of everything we do. Our products are lovingly crafted through a meticulous handmade process that ensures attention to detail and a personal touch in every creation. We take pride in using only the finest, pure organic materials to maintain the highest standards of quality and environmental responsibility. Our dedication to craftsmanship means that no machinery is ever involved in the production of our goods, preserving the authentic, artisanal essence of each item. Discover our range of handcrafted products, where tradition, purity, and eco-consciousness come together to provide you with a unique and wholesome experience.
                        <br />
                        <br />
                        Where we take immense pride in our artisanal approach to crafting products that reflect our unwavering commitment to quality, sustainability, and authenticity. Here's what sets us apart:
                        {jobs_array.map((item) => (
                            <React.Fragment key={item.id.toString()}>{item?.text}</React.Fragment>
                        ))}
                        <br />
                        Discover our range of handcrafted goods where tradition, purity, and eco-consciousness come together to offer you a distinctive and wholesome experience. Thank you for being a part of our journey towards a more sustainable and artisanal world.

                    </EmptyDiv>
                    {!loading && <Slider {...settings}>
                        {data?.map((item: any, i: number) => (
                            <Item key={i.toString()} className="about-item">
                                <img src={item?.image} />
                                <EmptyDiv className="bottom">
                                    <EmptyDiv>
                                        <h5>{item?.name}</h5>
                                        <EmptyDiv>{item?.designation}</EmptyDiv>
                                        <AboutMem><EmptyDiv style={{ height: "100%" }}>{parse(item?.about)}</EmptyDiv></AboutMem>
                                        <Hspace ht={15} />
                                    </EmptyDiv>
                                    {/* <MoreButton>More</MoreButton> */}
                                </EmptyDiv>
                            </Item>
                        )
                        )}
                    </Slider>}
                    {loading && <AboutShimmer />}
                </Column>

            </Container>

            <Footer />
        </Main >
    )
}

export default About