import styled from "styled-components";


const AnnaShimmerWrap = styled.div`
padding: 15px;
display: flex;
flex-direction: row;
gap: 10px;
width: 100%;


@media screen and (max-width: 458px) {
    flex-direction: column;
}
`

const HistoryShimmeHolder = styled.div`
display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, auto);
    gap: 20px;
        width: 100%;

    @media screen and (max-width: 991px) {
        grid-template-columns: auto;
    }
`

export const AnnaShimmer = () => {
    return (
        <AnnaShimmerWrap>
            <div className='shine' style={{ height: "550px", width: "100%" }}></div>
            <div className='shine' style={{ height: "550px", width: "100%" }}></div>
        </AnnaShimmerWrap>
    )
}

export const AnnaHistoryShimmer = () => {
    return (
        <HistoryShimmeHolder>
            {Array.from({ length: 10 }).map((_, i: number) => (
                <div key={i.toString()} className='shine' style={{ height: "150px", width: "100%" }}></div>
            ))}
        </HistoryShimmeHolder>
    )
}