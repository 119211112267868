import { green } from "../../styles";
import styled from "styled-components";

export const Main = styled.div`
  overflow: auto;
  max-height: calc(100vh - 0px);
`;

export const Container = styled.div`
  margin: 40px 100px;
  height: 100%;
  line-height: 20px;
  letter-spacing: 1px;
  @media only screen and (max-width: 458px) {
    margin: 20px 20px;
  }
`;

export const CastleText = styled.div`
font-size: 25px;
font-weight: 500;
`

export const Castle = styled.div`
display: flex;
flex-direction: column;
align-items: center;
`

export const Column = styled.div`
display: flex;
flex-direction: column;
align-items: flex-start;
flex: 1;
gap: 20px;
`

export const EmptyDiv = styled.div`

&.line {
  line-height: 25px;
  letter-spacing: 1px;
}
`


export const Row = styled.div`
display: flex;
flex-direction: row;
// flex: 1;
gap:20px;
transition: all 0.3s ease;

&.center {
    align-items: center;
}

&.noGap {
    gap: 0;
}

&.item-head {
    justify-content: space-between;
    align-items: center;
}

svg {
  height: 15px;
  width: 15px;
  transition: all 0.3s ease;
  transform: rotate(90deg);
}

${({ actv }: { actv?: boolean }) => {
    if (actv) {
      return `svg {
      transition: all 0.3s ease;
      transform: rotate(-90deg);
    }`;
    }
  }}
`

export const ItemContainer = styled.div`
border: 2px solid ${green};
padding: 10px 20px;
border-radius: 5px;
max-height: 60px;
max-width: 100%;
width: 100%;
overflow: hidden;
transition: all 0.3s ease;
cursor: pointer;



${({ expanded }: { expanded?: boolean }) => {
    if (expanded) {
      return `max-height: 1000px;
            transition: all 0.3s ease;`
    }
  }}
`

export const ItemHead = styled.div`
letter-spacing: 1px;
line-height: 25px;
font-size: 22px;
// max-height: 60px;
color: ${green};
text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

`


export const Span = styled.span`
    // align-self: flex-start;
    // display: inline-flex;

    svg {
      path {
        fill: ${green}
      }
    }
`