import {
  brown,
  darkBorder,
  darkFont,
  grey,
  lightFont,
  lightGrey,
  redish,
  whiteColor,
} from "../../styles";
import styled from "styled-components";

export const Main = styled.div`
  overflow: auto;
  max-height: calc(100vh - 0px);
  transition: all 0.3s ease;
  @media only screen and (max-width: 457px) {
    max-height: calc(100vh - 100px);
  }
`;

export const Container = styled.div`
  margin: 10px 80px;
  display: flex;
  flex-direction: column;
  gap: 40px;
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  flex: 1;

  @media only screen and (max-width: 991px) {
    flex-direction: column;
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  &.flex-1 {
    flex: 1;
  }

  &.flex-2 {
    flex: 2;
  }

  &.gap10 {
    gap: 10px;
  }

  &.gap30 {
    gap: 30px;
  }

  &.resend {
    justify-content: unset;
  }

  @media only screen and (min-width: 990px) {
    &.show {
      display: none;
    }
  }
`;

export const Error = styled.div`
  color: ${redish};
  font-family: Nunito Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  // overflow: scroll;
  max-width: 600px;
  overflow-wrap: break-word;
`;

export const FormLeft = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 2;
  gap: 40px;

  @media only screen and (max-width: 991px) {
    width: 100%;
  }
`;

export const FormRight = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex: 1;
`;

export const FromDivider = styled.div`
  display: flex;
  flex-direction: row;
  gap: 25px;
  flex: 1;
  // padding: 20px;
  background-color: ${whiteColor};
  // border: 1px solid ${darkBorder};

  &.pad-top {
    padding-top: 30px;
  }

  @media only screen and (max-width: 991px) {
    flex-direction: column;
    // width: 100%;
    // flex: 1;

    &.none {
      display: none;
    }
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  &.flex-1 {
    flex: 1;
  }

  &.flex-2 {
    flex: 2;
  }

  &.gap10 {
    gap: 10px;
  }

  &.gap30 {
    gap: 30px;
  }
`;

export const Text = styled.div`
  font-weight: 500;
  font-size: 0.875rem;
  color: #37b34a;
`;

export const Input = styled.input`
  background-color: #eee !important;
  outline-width: thin;
  // border: transparent;
  border: 1px solid ${grey};
  padding: 10px 8px;
  border-radius: 4px;
  color: rgb(102 92 92);
  font-size: 16px;

  &:active,
  &:hover {
    border: 1px solid ${lightGrey};
    // outline: none;
  }
  &:focus {
    outline: none;
  }
`;

export const OtpContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;

  &.center {
    align-items: center;
  }
`;

export const Divider = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media only screen and (max-width: 991px) {
    &.none {
      display: none;
    }
  }
`;

export const OR = styled.div`
  margin: 10px 0px;
  padding: 7px;
  font-size: 13px;
  border: 2px solid rgb(238, 238, 238);
  border-radius: 36px;
`;

//delivery
export const DeliveryContainer = styled.div`
display: flex;
flex - direction: row;
gap: 25px;
flex: 1;
padding: 20px;
background-color: ${whiteColor};
border: 1px solid ${redish};
`;

export const Address = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
`;
//checkbox
export const Checkbox = styled.div`
  &.round {
    position: relative;
  }

  &.round label {
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 50%;
    cursor: pointer;
    height: 20px;
    left: 0;
    position: absolute;
    top: 0;
    width: 20px;
  }

  &.round label:after {
    border: 2px solid #fff;
    border-top: none;
    border-right: none;
    content: "";
    height: 3px;
    left: 5px;
    opacity: 0;
    position: absolute;
    top: 6px;
    transform: rotate(-45deg);
    width: 7px;
  }

  &.round input[type="checkbox"] {
    visibility: hidden;
  }

  &.round input[type="checkbox"]:checked + label {
    background-color: #66bb6a;
    border-color: #66bb6a;
  }

  &.round input[type="checkbox"]:checked + label:after {
    opacity: 1;
  }
`;

export const ChkInput = styled.input``;

export const Label = styled.label`
  width: 100%;
  text-align: left;
  font-size: 12px;
  margin-bottom: 5px;
  color: ${lightGrey};
`;

export const InfoText = styled.div`
visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 150%;
  left: 50%;
  margin-left: -60px;
`
export const InfoIcon = styled.div`
position: relative;
display: inline-block;
svg {
  cursor: pointer;
  &::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: black transparent transparent transparent;
  }
}

${InfoText} {
  &::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: black transparent transparent transparent;
  }
}

&:hover {
  ${InfoText} {
    visibility: visible;
  }
}
`

export const Seperator = styled.div`
  display: flex;
  flex-direction: column;
`;

export const PasswordDiv = styled.div`
  position: relative;
`;

export const Span = styled.span`
  position: absolute;
  right: 9px;
  top: 10px;
`;

interface PasswordInputProps {
  showPassword: boolean;
}

export const PasswordInput = styled(Input).attrs(
  (props: PasswordInputProps) => ({
    type: props.showPassword ? "text" : "password",
  })
)`
  width: 100%;
`;
