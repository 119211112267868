import styled from "styled-components"

const Div = styled.div`
// display: flex;
// flex-diection: row;
// gap: 20px;
width: 100%;
overflow: hidden;
display: grid;
grid-template-columns: repeat(4, 1fr);
height: 350px;
gap: 20px;

@media screen and (max-width: 991px) {
    grid-template-columns: repeat(3, 1fr);
}

@media screen and (max-width: 767px) {
    grid-template-columns: repeat(2, 1fr);
}

@media screen and (max-width: 600px) {
    grid-template-columns: repeat(1, 1fr);
}
`

export function AboutShimmer() {
    return (
        <Div>
            {Array.from({ length: 4 }).map((_, item: any) => (
                <div key={item} className="shine" style={{ height: 350, }}></div>
            ))}
        </Div>
    )
}