//@ts-nocheck
import { Hspace } from "../../styles"
import { Icon, PrimaryButn, Toast } from "../../components"
import { InfoIcon, InfoText } from "../../components/checkout-form/checkout-form-components";
import { Center, Close, Column, EditTextContainer, EmptyDiv, Error, FormCOntainer, Img, Inner, InnerContainer, Input, Label, OtpContainer, PasswordDiv, PasswordInput, Row, Seperator, Span, Text } from "./login-components"
import EditTop from '../../assets/svgs/addressTop.svg';
import EditLine from '../../assets/svgs/editLine.svg';
import EditBottom from '../../assets/svgs/addressBottom.svg';
import { useRef, useState, useEffect } from "react";
import { forgotPassword, userlogin, register, verifyOtp, resetPassword, verifyRegisterOtp, resendRegisterOtp, onBoard } from "../../@services";
import { toast } from "react-toastify";

type Props = {
    type: 'register' | 'login' | 'forgot' | "changePassword" | "otp";
    onClose?: any;
    login?: any;
    setLogin?: any

}

const Login = ({ type, onClose, login, setLogin }: Props) => {
    const ref = useRef<HTMLDivElement>(null)
    const [mode, setMode] = useState<string>(type);
    const [isExpanded, setIsExpanded] = useState(false);
    const [registerParms, setRegisterParams] = useState<any>({ name: '', email: '', mobile: '', password: '', retypePassword: '' })
    const [loading, setLoading] = useState(false);
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [otp, setOpt] = useState<string>("")
    const [resend, setResend] = useState<boolean>(false)
    const [load, setLoad] = useState<boolean>(false)


    const emailRegex = /^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/;
    const passwordRegex = /^(?!.*\s)(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[~`!@#$%^&*()--+={}\[\]|\\:;"'<>,.?/_₹]).{10,16}$/;
    const mobileRegex = /^[0-9]{10}$/;



    const handleSignUpClick = () => {
        setMode('first');
        setIsExpanded(!isExpanded);
    };

    const OnBoard = async () => {
        if (!isRegister()) {
            if (registerParms.name.length < 5) {
                toast('Name must contain more than 5 letters');
                return;
            }
            toast('Please fill the details')
            return;
        }
        setLoading(true)
        let params = {
            user_id: parseInt(localStorage.getItem("userId")),
            name: registerParms.name,
            email_id: registerParms.email,
            // mobile: registerParms.mobile,
            password: registerParms.password,
            mobile: registerParms.mobile
        };

        await onBoard(params).then((res: any) => {
            if (res && res.status) {
                // console.log(res.data);
                setMode('login');
                setLoading(false)
                setRegisterParams({ name: '', email: '', mobile: '', password: '', retypePassword: '' })
                localStorage.setItem('hmd_user_details', JSON.stringify(res.data.data.data.user_data));
                localStorage.setItem('hmd_access_token', res.data.data.data.access_token);
                setTimeout(() => {
                    window.location.reload()
                }, 2000)
            } else {
                toast(res?.response?.data?.message)
                setLoading(false)
            }
        })
    };

    const SendRegisterOtp = async () => {
        setLoading(true)

        let params: any = {
            six_digit_code: otp,
            mobile: parseInt(registerParms.mobile)
        }

        await verifyRegisterOtp(params).then((res: any) => {
            if (res && res.status == 200) {
                console.log(res.data);
                localStorage.setItem("userId", res?.data?.data?.user_id)
                setMode('register');
                setLoading(false)
            } else {
                console.log("res?.data", res)
                toast(res?.data?.message)
                toast(res?.response?.data?.message)
                setLoading(false)
            }
        })
    }

    const ResendRegisterOtp = async () => {
        setLoad(true)
        let res = await resendRegisterOtp(registerParms.mobile)
        if (res?.status == 200) {
            setLoad(false)
            setResend(false)
            toast(res?.data?.message)
            setOpt('')
            // setMode('registerOtp')
            await new Promise((resolve) => setTimeout(resolve, 60000));
            setResend(true)
        } else {
            toast(res?.data?.message)
            toast(res?.response?.data?.message)
            setLoad(false)
        }
    }

    const Register = async () => {
        if (!validateMobile()) {
            return;
        }

        setLoading(true)
        let res = await register(registerParms.mobile)
        if (res?.status == 200) {
            setLoading(false)
            localStorage.setItem("userId", res?.data?.data?.user_id)
            if (res?.data?.data?.is_mobile_verify === 1) {
                setMode('register')
                toast(res?.data?.message)
                return;
            }
            toast(res?.data?.message)
            setMode('registerOtp')
            await new Promise((resolve) => setTimeout(resolve, 60000));
            setResend(true)
        } else {
            toast(res?.response?.data?.message)
            setLoading(false)
        }
    }

    const Login = async () => {
        if (!isLogin()) {
            toast('Please fill the details')
            return;
        }
        setLoading(true)
        let params = {
            email_id: registerParms.email,
            password: registerParms.password
        }

        await userlogin(params).then(async(res: any) => {
            if (res && res.status) {
                localStorage.setItem('hmd_user_details', JSON.stringify(res.data.data.user_data));
                localStorage.setItem('hmd_access_token', res.data.data.access_token);
                setLoading(false)
                setLogin(false);
                toast.success(res?.data?.message,)
                await setTimeout(() => {
                    window.location.reload()
                }, 5000)
                setRegisterParams({ name: '', email: '', mobile: '', password: '', retypePassword: '' })
            } else {
                toast(res?.response?.data?.message)
                setLoading(false)
            }
        })
    }

    const Forgot = async () => {
        if (!isForgot()) {
            toast('Please fill the details')
            return;
        }
        setLoading(true)
        let params = {
            email_id: registerParms.email,
        }

        await forgotPassword(params).then((res: any) => {
            if (res && res.status) {
                console.log(res.data.data)
                setLoading(false)
                setRegisterParams({ name: '', email: params?.email_id, mobile: '', password: '', retypePassword: '' })
                setMode('otp')
            } else {
                toast(res?.response?.data?.message)
                setLoading(false)
            }
        })
    }

    const sendOtp = async () => {
        if (otp?.length < 6 || loading) {
            return;
        }
        let params = {
            email_id: registerParms.email,
            six_digit_code: otp
        }

        setLoading(true)
        await verifyOtp(params).then((res: any) => {
            if (res && res?.status == 200) {
                setLoading(false)
                setRegisterParams({ name: '', email: params.email_id, mobile: '', password: '', retypePassword: '' })
                setOpt('')
                setMode('changePassword')
                toast(res?.data?.message)
            } else {
                toast(res?.data?.message)
                setLoading(false)
            }
        })

    }

    const changePassword = async () => {
        if (!isReset()) {
            return;
        }
        let params = {
            email_id: registerParms.email,
            new_password: registerParms?.password,
            retype_password: registerParms?.resetPassword,
        }

        setLoading(true)
        await resetPassword(params).then((res: any) => {
            if (res && res?.status == 200) {
                setLoading(false)
                setRegisterParams({ name: '', email: params?.email_id, mobile: '', password: '', retypePassword: '' })
                setMode('login')
                toast(res?.data?.message)
            } else {
                toast(res?.data?.message)
                setLoading(false)
            }
        })

    }

    const handleChange = (e) => {
        const { name, value } = e.target;

        setRegisterParams((prevState: object) => ({
            ...prevState,
            [name]: value
        }))
    }

    const isLogin = () => {
        if (emailRegex.test(registerParms.email) &&
            passwordRegex.test(registerParms.password)) {
            return true
        } else return false
    }

    const isForgot = () => {
        if (emailRegex.test(registerParms.email)) {
            return true
        } else return false;
    }

    const isRegister = () => {
        if (emailRegex.test(registerParms.email) &&
            passwordRegex.test(registerParms.password) &&
            mobileRegex.test(registerParms.mobile) &&
            registerParms.name.length > 4) {
            return true
        } else return false;
    }

    const validateRegisterOtp = () => {
        if (mobileRegex.test(registerParms.mobile) && otp?.length == 6) {
            return true
        } else return false
    }

    const validateMobile = () => {
        if (mobileRegex.test(registerParms.mobile)) {
            return true
        } else return false
    }

    const isReset = () => {
        if (emailRegex.test(registerParms.email) &&
            passwordRegex.test(registerParms.password) &&
            passwordRegex.test(registerParms.resetPassword)) {
            return true
        } else return false
    }

    const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
            setLogin(false);
        }
    };

    const handleOtpChnage = (e) => {
        setOpt(e.target.value);
    }

    // useEffect(() => {
    //     document.addEventListener('mousedown', handleClickOutside);
    //     return () => {
    //         document.removeEventListener('mousedown', handleClickOutside);
    //     };
    // }, []);

    return (
        <FormCOntainer ref={ref} className={isExpanded ? 'expanded' : ''}>
            <Close onClick={onClose}><Icon name="cross" /></Close>
            <InnerContainer>
                <Inner>
                    <Column className="flex-1">
                        <Center className='gap15'>
                            <Img width='150px' src={EditTop} alt="" />
                            <EditTextContainer>
                                <EmptyDiv>
                                    {(mode == 'register' || mode == "first") ? "Register" : mode == 'login' ? "Login" :
                                        (mode == "registerOtp" || mode == "otp") ? "Verify OTP" : mode == "changePassword" ? "Reset Password" :
                                            "Forgot Password"
                                    }
                                </EmptyDiv>
                                <Img src={EditLine} alt='edit svg' />
                            </EditTextContainer>
                        </Center>

                        {mode === 'register' && mode != 'otp' &&
                            <Seperator>
                                <Label>Name</Label>
                                <Input name="name" value={registerParms.name} placeholder='eg: John Smith' autoFocus onChange={handleChange} />
                            </Seperator>}
                        {mode !== 'first' && mode !== "registerOtp" && <Seperator>
                            <Label>Email</Label>
                            <Input name="email" value={registerParms.email} placeholder='eg: you@yourdomain.com' autoFocus onChange={handleChange} disabled={mode === "changePassword"} />
                            {registerParms?.email?.includes('@') && !emailRegex.test(registerParms.email) ?
                                <Error className="red">Email must be valid.</Error> : ""}
                        </Seperator>}
                        {(mode == 'first' || mode == "registerOtp") && mode != 'otp' &&
                            <Seperator>
                                <Label>Mobile{" "}<InfoIcon><Icon name='info' /><InfoText>You will receive update on whatsapp</InfoText></InfoIcon></Label>
                                <Input name="mobile" maxLength={10} value={registerParms.mobile} placeholder='eg: 788######88' onChange={handleChange} />
                                {registerParms.mobile && !mobileRegex.test(registerParms.mobile) ?
                                    <Error className="red">Mobile number must be valid.</Error> : ""}
                            </Seperator>
                        }
                        {mode !== 'first' && mode !== "registerOtp" && mode != 'forgot' && mode != "otp" &&
                            <Seperator>
                                <Label>{mode == "changePassword" ? "New Password" : "Password"}</Label>
                                <PasswordDiv>
                                    <PasswordInput showPassword={showPassword} name="password" value={registerParms.password} placeholder='eg: Abcd@1234' onChange={handleChange} />
                                    <Span onClick={() => setShowPassword(!showPassword)}>
                                        <Icon name={!showPassword ? "eye" : 'eyeStrike'} />
                                    </Span>
                                </PasswordDiv>
                                {registerParms.password && !passwordRegex.test(registerParms.password) ?
                                    <Error className="red">Password must contain a digit from 1 to 9, a lowercase, a uppercase, a special character, must be 8-16 characters long.</Error> : ""}
                            </Seperator>
                        }
                        {mode == 'changePassword' &&
                            <Seperator>
                                <Label>Confirm Password</Label>
                                <PasswordDiv>
                                    <PasswordInput showPassword="text" name="resetPassword" value={registerParms.resetPassword} placeholder='eg: Abcd@1234' onChange={handleChange} />
                                    {/* <Span onClick={() => setShowPassword(!showPassword)}>
                                        <Icon name={!showPassword ? "eye" : 'eyeStrike'} />
                                    </Span> */}
                                </PasswordDiv>
                                {registerParms.resetPassword?.length && (registerParms.resetPassword !== registerParms.password) ?
                                    <Error className="red">Both the password should match.</Error> : ""}
                            </Seperator>
                        }
                        {(mode == "otp" || mode == "registerOtp") &&
                            <Seperator>
                                <Label>OTP</Label>
                                <Input name="otp" maxLength={6} value={otp} placeholder='eg: 123456' onChange={handleOtpChnage} />
                                {otp?.length < 6 ?
                                    <Error className="red">OTP should contain 6 digits.</Error> : ""}
                            </Seperator>
                        }
                        {mode == 'registerOtp' && <Row className="j-sb">
                            <Text>Didn't receive OTP?</Text>
                            <PrimaryButn padding={2} wd={80} isDisabled={!resend} name={load ? "Loading..." : "Resend"} onClick={ResendRegisterOtp} />
                        </Row>}
                        <Hspace ht={10} />
                        <OtpContainer className="flex-end ">
                            {mode != "changePassword" ? <Column>
                                {mode != 'login' && <Row className="gap5 small">
                                    <Text className="cursor" onClick={() => setMode('login')}>Already Registered? </Text>
                                </Row>}
                                {mode != 'register' && <Row className="gap5"><Text>New User? </Text><Text className="brown" onClick={handleSignUpClick}>Sign Up</Text></Row>}
                                {mode != 'forgot' && mode !== "otp" && <Text className="brown" onClick={() => setMode('forgot')}>Forgot Password?</Text>}
                            </Column> : <div></div>}

                            {/* <PrimaryButn name={mode == 'register' ? "Register" : mode == 'login' ? "LOGIN" : mode == 'forgot' ? "SUBMIT" : ""} onClick={() => mode == 'register' ? Register() : mode == 'login' ? Login() : Forgot()} /> */}
                            {mode == 'register' && <PrimaryButn name={loading ? "Loading..." : "Register"} isDisabled={!isRegister()} onClick={OnBoard} />}
                            {mode == 'login' && <PrimaryButn name={loading ? "Loading..." : "LOGIN"} isDisabled={!isLogin()} onClick={Login} />}
                            {mode == 'forgot' && <PrimaryButn name={loading ? "Loading..." : "SUBMIT"} isDisabled={!isForgot()} onClick={Forgot} />}
                            {mode == 'otp' && <PrimaryButn name={loading ? "Loading..." : "SUBMIT"} isDisabled={otp?.length < 6} onClick={sendOtp} />}
                            {mode == 'changePassword' && <PrimaryButn name={loading ? "Loading..." : "SUBMIT"} isDisabled={!isReset()} onClick={changePassword} />}
                            {mode == 'first' && <PrimaryButn name={loading ? "Loading..." : "SEND OTP"} isDisabled={!validateMobile()} onClick={Register} />}
                            {mode == 'registerOtp' && <PrimaryButn name={loading ? "Loading..." : "SUBMIT"} isDisabled={!validateRegisterOtp()} onClick={SendRegisterOtp} />}
                        </OtpContainer>

                        <Hspace ht={10} />
                        <Center>
                            <Img width='150px' src={EditBottom} alt="svg" />
                        </Center>
                    </Column>
                    {/* <Toast /> */}
                </Inner>
            </InnerContainer>
        </FormCOntainer>
    )
}

export default Login