import styled from "styled-components"

const Div = styled.div`
display: flex;
flex-direction: column;
gap: 10px;

&.gap20 {
    gap: 20px;
}
`

export function ProfileShimmer() {
    return (
        <Div>
            {Array.from({ length: 3 }).map((_, item: any) => (
                <div key={item} className="shine" style={{ height: 50, }}></div>
            ))}
        </Div>
    )
}