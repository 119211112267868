import styled from "styled-components";
import { grey, themeBackground, whiteColor } from "../../styles";

export const Container = styled.div`
  padding: 5px 50px;
  background: ${themeBackground};
  border-radius: 8px;
`;

export const TabItem = styled.div`
  display: inline-block;
  color: white;
  text-align: center;
  padding: 14px;
  text-decoration: none;
  width: 25%;
  &:hover {
    // background-color: #777;
    cursor: pointer;
  }
  ${({ active }: { active: boolean }) => {
    if (active) {
      return `color: ${whiteColor};
            font-weight: bold;`;
    } else {
      return `color: #fffbfbb8;`;
    }
  }}
`;

export const IconDiv = styled.div`
  border-radius: 40px;
  width: 29px;
  background-color: ${whiteColor};
  // position: absolute;
  // top: 12px;
  // left: 0px;
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 50;

  &.right {
    // margin: 0px 10px 0px 0px;
    svg {
      transform: rotate(180deg);
    }
  }

  &:focus {
    background: unset;
  }

  &:hover {
    background-color: ${whiteColor};
  }

  &::before {
    content: "\f104";
    font-size: 19px;
    opacity: 1;
    color: #222;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0%);
  }

  ${({ active }: { active?: boolean }) => {
    if (active) {
      return `opacity: 0.5;`;
    }
  }}
`;
