import { FormCOntainer, InnerContainer, Inner, Column, Label, Input, Close, Center, Div, AddressLocation, Location, ButtonContainer, EditTextContainer } from './edit-form-components'
import EditBottom from '../../assets/svgs/addressBottom.svg';
import EditTop from '../../assets/svgs/addressTop.svg';
import EditLine from '../../assets/svgs/editLine.svg';
import Primary from '../Buttons/primary';
import { darkBorder } from '../../styles';
import { useState } from 'react';
import { capitalize } from '../../utils';
import Icon from '../icon';

type Props = {
    type: 'edit' | 'add';
    onClose: () => void;
    userAddress?: any; // Assuming UserAddress is the type for userAddress
    setUserAddress?: any;
    locationType?: any;
    setLocationType?: any;
    onSubmit: () => void;
    onDelete: () => void;
    isEditing?: boolean;
    isDeleting?: boolean;
    isAdding?: boolean;

}
const EditForm = ({ type, onClose, userAddress, setUserAddress, locationType, setLocationType, onSubmit, onDelete, isEditing, isDeleting, isAdding }: Props) => {
    const addressType = ['home', 'work', 'others']

    const handleChange = (e) => {
        const { name, value, id } = e.target;
        setUserAddress((prevState: any) => ({
            ...prevState,
            [name]: value,
        }));
    };


    return (
        <FormCOntainer>
            <Close onClick={onClose}><Icon name="cross" /> </Close>
            <InnerContainer>
                <Inner>
                    <Column>
                        <Center className='gap15'>
                            <img width='150px' src={EditTop} alt="" />
                            <EditTextContainer>
                                <div>{type == 'add' ? "Add New Address" : "Edit Address"}</div>
                                <img src={EditLine} alt='edit svg' />
                            </EditTextContainer>
                        </Center>
                        <Div>
                            <Label>Flat/House No*</Label>
                            <Input name='flat_house_no' value={userAddress?.flat_house_no} onChange={handleChange} />
                        </Div>
                        <Div>
                            <Label>Street Name*</Label>
                            <Input name='street' value={userAddress?.street} onChange={handleChange} />
                        </Div>
                        <Div>
                            <Label>Address 1*</Label>
                            <Input name='address1' value={userAddress.address1} onChange={handleChange} />
                        </Div>
                        <Div>
                            <Label>Address 2</Label>
                            <Input name='address2' value={userAddress.address2} onChange={handleChange} />
                        </Div>
                        <Div>
                            <Label>City*</Label>
                            <Input name='city' value={userAddress?.city} onChange={handleChange} />
                        </Div>
                        <Div>
                            <Label>State*</Label>
                            <Input name='state' value={userAddress?.state} onChange={handleChange} />
                        </Div>
                        <Div>
                            <Label>Country*</Label>
                            <Input name='country' value={userAddress?.country} onChange={handleChange} />
                        </Div>
                        <Div>
                            <Label>Zip Code*</Label>
                            <Input name='zip' value={userAddress?.zip} onChange={handleChange} />
                        </Div>
                        <Div>
                            <Label>Landmark*</Label>
                            <Input name='landmark' value={userAddress?.landmark} onChange={handleChange} />
                        </Div>
                        <Div>
                            <Label>Address Title*</Label>
                            <AddressLocation>
                                {addressType.map((item: any, index: number) => (
                                    <Location id='location' key={index.toString()} active={item === locationType}
                                        onClick={() => setLocationType(item)}>{capitalize(item)}
                                    </Location>
                                ))}
                            </AddressLocation>
                        </Div>
                        <ButtonContainer>
                            <Primary backColor={`${darkBorder}`} padding={5} fSize={17} name='' onClick={onSubmit}>{(isAdding || isEditing) ? "Loading.." : "SAVE"}</Primary>
                            {type != 'add' && <Primary name='' padding={5} fSize={17} onClick={onDelete}>{isDeleting ? "Loading.." : "DELETE"}</Primary>}
                        </ButtonContainer>
                        <Center>
                            <img width='150px' src={EditBottom} alt="svg" />
                        </Center>
                    </Column>
                </Inner>
            </InnerContainer>
        </FormCOntainer>
    )
}

export default EditForm