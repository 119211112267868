import {
  darkFont,
  green,
  greenTheme,
  grey,
  lightGrey,
  redish,
  themeBackground,
  whiteColor,
} from "../../styles";
import styled from "styled-components";
import { red } from "@mui/material/colors";

export const Image = styled.img`
  width: 100%;
  height: 100%;
  aspect-ratio: 4/3;
  // flex: 1;
  overflow: hidden;
  //   flex: 45%;
  // max-height: 200px;
  border-radius: 5px;
`;

export const Conteiner = styled.div`
  position: relative;
  display: flex;
  // padding: 0px 10px;
  flex-direction: column;
  // gap: 15px;
  //   border: 1px solid red;
  border-radius: 3px;
  // overflow: hidden;
  width: 100%;
  height: 325px;
  // height: 100%;
  // flex: 1;
  cursor: pointer;
`;

export const Product = styled.div`
  position: absolute;
  top: 90%;
  background-color: white;
  // width: 200px;
  left: 20px;
  right: 20px;
  padding: 10px 15px;
`;

export const ProductName = styled.div`
  font-size: 15px;
  max-width: 70%;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  s {
    color: #9c9a9a;
  }

  &.price {
    color: #5b5b5b !important;
    font-size: 13px;
  }
`;

export const Offer = styled.div`
  position: absolute;
  z-index: 0;
  background-color: #4caf50;
  color: #fff;
  left: -15px;
  padding: 6px 8px;
  transform: rotate(-47deg);
  top: 4px;
  border-radius: 5px;
`;

export const Add = styled.div`
  background-color: transparent;
  border-radius: 6px;
  padding: 5px 10px;
  color: ${redish};
  font-weight: 500;
  font-size: 12px;
  border: 1px solid ${redish};
  transition: all 0.3s ease;
  min-width: 80px;
  margin-left: 10px;
  text-align: center;
  &:hover {
    background-color: ${redish};
    color: ${whiteColor};
    cursor: pointer;
  }

  ${({ disabl }: { disabl?: boolean }) => {
    if (disabl) {
      return `
      background-color: ${redish};
      color: ${whiteColor};
      `;
    }
  }}
`;

export const ProductRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  &.a-fs {
    justify-content: flex-start;
    gap: 5px;
  }
`;

export const OutOfStock = styled.div`
  position: absolute;
  color: red;
  font-size: 22px;
  background: #ffffffc7;
  top: 103px;
  right: 0;
  left: 0;
  text-align: center;
  color: #000;
  font-weight: 600;
  background-color: rgb(204 204 204 / 0.8);
  padding: 12px;
`;

export const Relative = styled.div`
  position: relative;
  height: 80%;
`;

export const Popup = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgb(219 255 235);
  top: 10px;
  transition: all 0.5s ease;
  margin: 10px 10px 0 10px;
  padding: 10px 20px;
  border-radius: 20px 20px 0 0;
  text-align: center;
  overflow: hidden;

  ${({ ht }: { ht?: boolean }) => {
    if (ht) {
      return `
        height: 100%;
        display: visible;
        `;
    } else {
      return `
        height: 0px;
        display: none;`;
    }
  }}
`;

export const Text = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3px;
  border-radius: 5px;
  font-size: 10px;
  font-weight: bold;
  background-color: rgb(210 251 216);
  color: ${green};
  padding: 3px 5px;
  max-width: unset !important;
  svg {
    path {
      fill: ${green} !important;
    }
  }

  &.plain {
    align-items: flex-end;
    background-color: unset;
    justify-content: flex-end;
    padding: unset;
    color: red;
    font-weight: normal;
  }
`;
