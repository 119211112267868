import { service } from "../service";


export const getProductDetails = async (id: number) => {
    let url = `/api/v1/get-product-details`

    return service
        .post(url, id)
        .then((res: any) => res)
        .catch((err: any) => err)
}

export const getOrders = async (id: any) => {
    let url = `/api/v1/get-order`;

    return service
        .post(url, id)
        .then((res: any) => res)
        .catch((err: any) => err)
}

export const createOrder = async (params: any) => {
    let url = `/api/v1/create-order`;

    return service
        .post(url, params)
        .then((res: any) => res)
        .catch((err: any) => err)
}

export const suggestions = async () => {
    let url = `api/v1/get-all-suggestions`;

    return service
        .get(url)
        .then((res: any) => res)
        .catch((err: any) => err)
}

export const getCupons = async () => {
    let url = `/api/v1/get-all-coupon`;

    return service
        .get(url)
        .then((res: any) => res)
        .catch((err: any) => err)
}

export const getProdByOrderId = async (params: any) => {
    let url = `/api/v1/get-product-by-order`;

    return service
        .post(url, params)
        .then((res: any) => res)
        .catch((err: any) => err)
}

export const prodReviews = async (params: any) => {
    let url = `/api/v1/review-order`;

    return service
        .post(url, params)
        .then((res: any) => res)
        .catch((err: any) => err)
}

export const getProductReviews = async (productId: number) => {
    let url = `/api/v1/get-product-reviews`;

    return service
        .post(url, productId)
        .then((res: any) => res)
        .catch((err: any) => err)

}

export const returnProd = async (params: any) => {
    let url = `/api/v1/replace-order`;

    return service
        .post(url, params)
        .then((res: any) => res)
        .catch((err: any) => err)
}

export const notify = async (params: any) => {
    let url = `/api/v1/create-product-notify`

    return service
        .post(url, params)
        .then((res: any) => res)
        .catch((err: any) => err)
}

export const getProductRecoms = async (params: any) => {
    let url = `/api/v1/get-product-recommendation`

    return service
        .post(url, params)
        .then((res: any) => res)
        .catch((err: any) => err)

}