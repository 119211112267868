import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Img from '../../assets/ashrafiya.png'
import Img1 from '../../assets/empty-card.jpg'
import Img3 from '../../assets/Job_Categories-s.jpg'
import Img4 from '../../assets/slider/slider4.jpg'
import Img5 from '../../assets/slider/slider5.jpg'
import Img6 from '../../assets/slider/slider6.jpg'
import Img7 from '../../assets/slider/slider7.jpg'
import Icon from '../icon';
import { IconDiv } from './banner-slick-components';
import '../../css/custom.css';



const SliderComponent = () => {
    const imgArr = [Img, Img1, Img3, Img4, Img5, Img6, Img7]

    const getBanners = () => {
        let imageItems: any = sessionStorage.getItem('banners')
        return JSON.parse(imageItems);
    }


    const Prev: any = ({ className, style, onClick, disabled }) => {
        return (
            <IconDiv className={className} onClick={onClick}>
                <Icon name="backArrow" />
            </IconDiv>
        )
    }

    const Next: any = ({ className, style, onClick }) => {
        return (
            <IconDiv className={`${className} right`} onClick={onClick}>
                <Icon name="backArrow" />
            </IconDiv>
        )
    }


    const settings = {
        dots: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: true,
        autoplay: true,
        arrow: true,
        prevArrow: <Prev />,
        nextArrow: <Next />,
        responsive: [
            {
                breakpoint: 480,
                settings: {
                    arrows: false,
                    dots: false,
                    slidesToShow: 1,
                    autoplay: false,
                }
            }
        ]
    };

    return (
        <div className="banContainer-hmd">
            <div className="col-lg-12">
                <Slider {...settings}>
                    {getBanners()?.map((item, index) => (
                        <div key={index.toString()} className="item">
                            <img src={item.image} alt={`Slider ${index + 1}`} title={`Slider ${index + 1}`} />
                        </div>
                    ))}
                </Slider>
            </div>
        </div>
    );
};

export default SliderComponent;
