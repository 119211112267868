import styled from "styled-components";
import { lightGrey, redish, themeBackground, whiteColor } from "../../styles";

export const Container = styled.div`
  // padding: 10px 60px;
  // background: red;
  border-radius: 8px;
  // height: 100px;

  @media only screen and (max-width: 457px) {
    padding: 10px 10px;
  }
`;

export const TabItem = styled.div`
  // display: inline-block;
  color: white;
  text-align: center;
  padding: 14px;
  text-decoration: none;
  width: 25%;
  &:hover {
    // background-color: #777;
    cursor: pointer;
  }
  // ({ active }: { active: boolean }) => {
  //       if (active) {
  //           return color: white;;
  //       } else {
  //           return color: #fffbfbb8;;
  //       }
  //   }}
`;

export const Item = styled.div`
  width: 100%;
  display: flex !important;
  flex-direction: row;
  align-item: center;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  height: 120px;
  // height: 100%;
  cursor: pointer;
`;
export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 10px;

  &.gap5 {
    gap: 5px;
  }
`;

export const CrouselItem = styled.div`
  padding: 0 10px;
`;
export const ImgDiv = styled.div`
  max-width: 40%;
  // flex: 30%;
  border-radius: 5px 0px 0px 5px !important;
  position: relative;
  ${({ mgL }: { mgL: boolean }) => {
    if (mgL) {
      return `margin-left: 15px;`;
    }
  }}
`;

export const OutOfStock = styled.div`
  position: absolute;
  color: red;
  font-size: 15px;
  background: #ffffffc7;
  top: 40px;
  right: 0;
  left: 0;
  text-align: center;
  color: #000;
  font-weight: 600;
  background-color: rgb(204 204 204 / 0.8);
  padding: 12px;
`;


export const Image = styled.img`
  // object-fit: cover !important;
  aspect-ratio: 3/3;
  width: 100%;
  height: 100%;

  // &:first-child {
  //   margin-left: 0;
  // }
`;

export const Product = styled.div`
  background-color: white;
  flex: 70%;
  padding: 0px 10px 0px 0px;
  border-radius: 0px 5px 5px 0px !important;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  // padding: 10px;
  padding: 10px 10px 10px 0;
`;
export const ProductName = styled.div`
  font-size: 16px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;

  s {
    color: #9c9a9a;
  }

  &.price {
    color: #5b5b5b !important;
    font-size: 13px;
  }
`;

export const Add = styled.div`
  background-color: transparent;
  border-radius: 6px;
  padding: 5px 10px;
  color: ${redish};
  font-weight: 500;
  font-size: 12px;
  border: 1px solid ${redish};
  transition: all 0.3s ease;
  &:hover {
    background-color: ${redish};
    color: ${whiteColor};
    cursor: pointer;
  }

  ${({ disabl }: { disabl?: boolean }) => {
    if (disabl) {
      return `
      background-color: ${redish};
      color: ${whiteColor};
      `;
    }
  }}
`;

export const ProductRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  margin: 10px;
`;

export const IconDiv = styled.div`
  border-radius: 40px;
  width: 29px;
  background-color: ${whiteColor};
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 51;
  box-shadow: -1px 0px 2px 1px #bdb8b8;
  left: -15px;
  &.right {
    box-shadow: 1px 0px 2px 1px #bdb8b8;
    right: -12px;
    left: unset;
    svg {
      transform: rotate(180deg);
    }
  }

  &:disabled {
    background: unset;
    font-style: normal;
    opacity: 1;
    color: black;
    border-color: rgb(207, 207, 207);
    cursor: default;
  }

  &:focus {
    background: unset;
  }

  &:hover {
    background-color: ${whiteColor};
  }

  &::before {
    content: "\f104";
    font-size: 19px;
    opacity: 1;
    color: #222;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0%);
  }

  // &:disabled {
  //     background: black !important;
  //     cursor: default !important;
  // }

  svg {
    stroke: ${lightGrey};
    stroke-width: 1px;
  }
`;
