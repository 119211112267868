import { brown, darkBorder, redish } from "../../styles";
import styled from "styled-components";



export const FormCOntainer = styled.div`
// transform: translate(-50%, 3%);
position: relative;
    background: #ffffff;
    border-radius: 5px;
    box-shadow: 0px 4px 8px 2px #111;
    padding: 8px;
    max-width: 600px;
    width: 100%;
    margin: 0px 20px;

    @media only screen and (max-width: 767px) {
        // max-width: 300px;
    }
`

export const InnerContainer = styled.div`
border: 3px solid rgb(181 33 33);
padding: 8px;
width: 100%;
`

export const Inner = styled.div`
border: 3px solid rgb(181 33 33);
padding: 0px 30px;

@media only screen and (max-width: 767px) {
    padding: 0px 6px;
}
`

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 2px 10px;

  &.flex-1 {
    flex: 1;
  }

  &.flex-2 {
    flex: 2;
  }

  &.gap10 {
    gap: 10px;
  }

  &.gap30 {
    gap: 30px;
  }
`;

export const EditTextContainer = styled.div`
max-width: 200px;
width: 100%;
font-size: 25px;
font-weight: 500;
text-align: center;

img {
    width: 100% !important;
    vertical-align: top;
}
`
export const Center = styled.div`
display: flex;
flex-direction: column;
align-items: center;

img {
    // height: 50%;
    width: 50%;
}

&.gap15 {
    gap: 15px;
}

`
export const Close = styled.div`
  font-weight: bold;
  font-size: 20px;
  position: absolute;
  background-color: black;
  padding: 4px 8px;
  border-radius: 35px;
  color: white;
  top: -5px;
  right: -5px;
  border: 2px solid ${brown};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const ButtonContainer = styled.div`
display: flex;
flex-direction: row;
align-items: center;
justify-content: center;
gap: 20px;
`