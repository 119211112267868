import {
  brown,
  darkBorder,
  green,
  grey,
  lighBlacktFont,
  lightGrey,
  redish,
  whiteColor,
} from "../../styles";
import styled from "styled-components";
import WhatsAppBack from "../../assets/svgs/whatsapp.svg";

type Props = {
  maxHeight?: boolean; // Adjust the type to match your requirements (e.g., boolean or string)
};

export const PaymentOverlay = styled.div`
position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 53;
    background-color: #998d8d61;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    backdrop-filter: blur(7px);
    gap: 20px;
`

export const LoaderText = styled.div`
    font-size: larger;
    font-weight: 500;
    max-width: 350px;
    text-align: center;
    background-color: white;
    padding: 18px;
`

export const Main = styled.div`
  overflow: auto;
  max-height: calc(100dvh - 0px);
  transition: all 0.3s ease;
  // letter-spacing: .7px;
  @media only screen and (max-width: 457px) {
    // max-height: calc(100vh - 100px);
  }
`;

export const Container = styled.div`
  margin: 20px 60px 80px 60px;
  display: flex;
  flex-direction: column;
  gap: 40px;

  @media only screen and (max-width: 457px) {
    margin: 20px 20px 60px 20px;
  }
`;

export const LikeText = styled.span`
color: #434343;
font-size: .875rem;
margin: 0.875rem 0;
letter-spacing: .1875rem;
font-weight: 500;
`

export const SuggestionHead = styled.div`
display: flex;
flex-direction: Column;
`

export const DottedLine = styled.div`
  /* background-color: red; */
  // padding-left: 20px;
  // margin-left: 10px;
  // padding-top: 20px;
  // border-left: 1px dotted;
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: row;
  //   align-items: center;
  justify-content: space-between;
  gap: 20px;
  flex: 1;

  @media only screen and (max-width: 991px) {
    flex-direction: column;
  }
`;

export const AuthContainer = styled.div<Props>`
  background-color: ${whiteColor};
  border: 1px solid ${darkBorder};
  overflow: hidden;
  flex: 1;
  padding: 20px 20px 20px 20px;
  transition: all 0.5s ease;
  max-height: ${(props) => (props.maxHeight ? "70px" : "900px")};
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  &.flex-1 {
    flex: 1;
  }

  &.flex-2 {
    flex: 2;
  }

  &.gap10 {
    gap: 10px;
  }

  &.gap30 {
    gap: 30px;
  }

  &.resend {
    justify-content: unset;
    gap: 15px;
  }

  &.flex-start {
    align-items: flex-start;
  }

  &.j-start {
    justify-content: flex-start;
  }

  &.svgs {
    svg {
      height: 30px;
      width: 30px;
      background-color: ${grey};
      padding: 7px;
      border-radius: 20px;
      path {
        stroke: ${redish};
        fill: red;
        opacity: 0.5;
      }
    }
  }

  @media only screen and (min-width: 990px) {
    &.show {
      display: none;
    }
  }
`;

export const FormLeft = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  // flex: 2;
  flex: 75%;
  gap: 20px;
  padding-top: 30px;

  @media only screen and (max-width: 991px) {
    width: 100%;
    gap: 80px;
  }
`;
export const FormRight = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 25px;
  flex: 25%;
  position: relative;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  &.flex-1 {
    flex: 1;
  }

  &.flex-2 {
    flex: 2;
  }

  &.gap10 {
    gap: 10px;
  }

  &.gap30 {
    gap: 30px;
  }

  &.noGap {
    gap: 0px;
  }
`;

export const AddressShow = styled.div`
display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  `

export const Text = styled.div`
  font-weight: 500;
  font-size: 0.875rem;
  color: #37b34a;
  transition: all 0.3s ease;

  &.coupon {
    font-size: 16px;
    color: ${redish};
    font-weight: 500;
  }

  &.remove {
    color: ${brown};

    &:hover {
      transition: all 0.3s ease;
      color: ${green};
      border-bottom: 1px solid ${brown};
    }
  }

  &.large {
    font-size: 18px;
    letter-spacing: 1px;
    color: ${brown};
    font-weight: 600;
    text-align: center;
  }

  &.medium {
    font-size: 14px;
    letter-spacing: 1px;
    color: ${brown};
    font-weight: 600;
    text-align: center;
  }
`;

//delivery

export const DeliveryContainer = styled.div<Props>`
  display: flex;
  flex-direction: row;
  gap: 25px;
  flex: 1;
  padding: 20px;
  background-color: ${whiteColor};
  border: 1px solid ${redish};
  // position: relative;
  overflow: auto;
  transition: all 0.5s ease;
  max-height: ${(props) => (props.maxHeight ? '60px' : '600px')};

`;

export const Address = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
`;

export const AddNewDIv = styled.div`
  min-height: 140px;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='10' ry='10' stroke='%23AD9F9FFF' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='59' stroke-linecap='square'/%3e%3c/svg%3e");
  border-radius: 10px;
  border-radius: 10px;
  width: 100%;
  text-align: center;
  display: flex;
  // flex-direction: column;
  gap: 10px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 5px;
  color: #ad9f9f;
  font-weight: 600;
  background-color: rgb(242 242 242 / 74%);

  svg {
    path {
      // fill: #ad9f9f;
    }
  }
`;

//cart-bottom

export const CartConatiner = styled.div`
  border-radius: 8px;
  overflow: hidden;
  padding: 10px 20px 30px 20px;
  background-color: ${whiteColor};
  border-radius: 4px;
  color: #364d59;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const Payment = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  // margin: 5px 1px 10px 20px;
  font-weight: bold;
`;

export const Ashrafiya = styled.div`
  border: 1px dotted black;
  padding: 13px 10px;
  text-align: center;
    flex: 1;
    // width: 100%;
  font-size: 15px;
  letter-spacing: 0.02rem;

  &:disabled {
    cursor: not-allowed;
  }
`;

//checkbox
export const Checkbox = styled.div`
  &.round {
    position: relative;
  }

  &.round label {
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 50%;
    cursor: pointer;
    height: 15px;
    left: 0;
    position: absolute;
    top: 0;
    width: 15px;
  }

  &.round label:after {
    border: 2px solid #fff;
    border-top: none;
    border-right: none;
    content: "";
    height: 2px;
    left: 3px;
    opacity: 0;
    position: absolute;
    top: 4px;
    transform: rotate(-45deg);
    width: 6px;
  }

  &.round input[type="checkbox"] {
    visibility: hidden;
  }

  &.round input[type="checkbox"]:checked + label {
    background-color: #66bb6a;
    border-color: #66bb6a;
  }

  &.round input[type="checkbox"]:checked + label:after {
    opacity: 1;
  }
`;

export const ChkInput = styled.input``;

export const Label = styled.label``;

export const BadgeDiv = styled.div`
  position: relative;
  transition: all 0.5s ease;
  @media only screen and (min-width: 991px) {
    padding: 0px 0px 20px 20px;
    ${({ bd }: { bd?: boolean }) => {
    if (bd) {
      return `border-left: 1px dotted ${darkBorder};`;
    } else if (bd == undefined) {
      return `border-left: 1px dotted ${darkBorder};`;
    }
  }}
  }
`;

export const Badge = styled.div`
position: absolute;
    top: -32px;
    background-color: #2d282e;
    color: ${whiteColor};
    padding: 12px 20px;
    left: -10px;
    border-radius: 8px;
    transition: all 0.3s ease;
    display: flex;
    flex-direction: row;
    align-items: center;
    // justify-content: space-between;
    gap: 10px;

    width: 100%;
    max-width: 130px;
    svg {
      height: 15px !important;
      width: 15px !important;
      path {
        stroke: ${redish};
        fill: red;
        opacity: 0.5;
      }
    }

    @media only screen and (max-width: 991px) {
        top: -50px;
        left: 40%;
    }

    @media only screen and (max-width: 557px) {
        top: -50px;
        left: 30%;
    }
`;

export const PaymentCOntainer = styled.div<Props>`
display: flex;
flex-direction: row;
flex: 1;
padding: 20px;
background-color: ${whiteColor};
border: 1px solid ${darkBorder};
transition: all 0.5s ease;
max-height: ${(props) => (props.maxHeight ? '60px' : '500px')};

@media only screen and (max-width: 600px) {
  flex-direction: column;
  transition: all 0.5s ease;
}
`;

export const TabContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  // justify-content: flex-start;

  @media only screen and (max-width: 600px) {
    flex-direction: row;
    margin-bottom: 20px;
  }
`;

export const Selected = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  padding: 10px 48px 10px 24px;
  transition: all 0.3s ease;
  cursor: pointer;

  ${({ active }: { active?: boolean }) => {
    if (active) {
      return `background-color: #f3f3f3;
        transition: all 0.3s ease;`;
    } else {
      return `background-color: transparent;
        transition: all 0.3s ease;`;
    }
  }}
`;

export const PaymentRight = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;

  @media only screen and (min-width: 600px) {
    padding-left: 30px;
    border-left: 1px solid ${grey};
  }
`;

export const WalletItem = styled.div`
  border: 1px solid ${grey};
  font-size: 10px;
  border-radius: 5px;
  padding: 10px 20px;
  display: flex;
  flex-direction: column;
  // width: 80%;
  gap: 10px;
`;

export const Wallet = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  flex: 1;
`;
export const Cash = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

export const EmptyDIv = styled.div``;

export const Image = styled.img<any>`
  ${({ heit }: { heit?: string }) => {
    if (heit) {
      return `height: ${heit};`;
    } else {
      return ``;
    }
  }};

  ${({ wd }: { wd?: string }) => {
    if (wd) {
      return `width: ${wd};`;
    } else {
      return ``;
    }
  }};
`;

export const WhatsAppContainer = styled.div`
  // padding: 15px;
  // background-color: ${green};
  border-radius: 0.3125rem;
  margin-top: 15px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-box-shadow: 0 10px 15px 0 hsl(0deg 0% 73% / 25%);
  box-shadow: 0 10px 15px 0 hsl(0deg 0% 73% / 25%);
  // background-image: url(${WhatsAppBack});
  // background-size: 98% 100%;
  // background-repeat: no-repeat;
  // background-position: 50% 50%;
  cursor: pointer;

  background-color: darkgreen;
  color: white;
  border-radius: 45px;
  letter-spacing: 0.7px;
  /* padding: 0px; */
  padding: 10px 15px;
`;

export const WappWrap = styled.div`
display: flex;
    align-items: center;
    justify-content: space-between;

    img {
      width: 44px;
      height: 44px;
    }
`

export const WhatsappText = styled.div`
font-size: 24px;
font-weight: 700;

  &.small {
    font-size: 16px;
    font-style: italic;
    font-weight: 500;
  }
`;

export const AddressContainer = styled.div`
width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  background-color: ${whiteColor};
  // padding: 20px 25px;

  grid-row-gap: 20px;
  grid-column-gap: 20px;

  @media only screen and (max-width: 991px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media only screen and (max-width: 767px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const AddressCardHolder = styled.div`
cursor: pointer;
`


export const ProductName = styled.div`
  font-size: 16px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;

  s {
    color: #9c9a9a;
  }

  &.price {
    color: #5b5b5b !important;
  }
`;

export const OutOfBox = styled.div`
font-size: 10px;
    color: red;
`