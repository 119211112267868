import styled from "styled-components";
import {
  brown,
  darkBorder,
  green,
  lighBlacktFont,
  lightFont,
  redish,
  whiteColor,
} from "../../styles";

export const Container = styled.div`
  position: relative;
  margin: 40px 80px;
  min-height: calc(100vh - 225px);
  height: 100%;
  background-color: ${whiteColor};
  padding: 20px;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 30px;
  align-items: center;
  padding-bottom: 70px;
  @media only screen and (max-width: 458px) {
    margin: 20px 20px;
  }
`;

export const Heading = styled.div`
  color: #55616d;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 1px;
  align-self: flex-start;
  background-color: #ececec;
  width: 100%;
  padding: 10px;
  border-radius: 8px;
`;

export const CardHolder = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, auto);
  gap: 20px;
  width: 100%;
  // align-items: center
  // justify-content:

  @media screen and (max-width: 991px) {
    grid-template-columns: auto;
  }
`;

export const Card = styled.div`
  padding: 10px;
  background-color: #f7f7f7;
  border-radius: 6px;
  max-height: 300px;
  height: 100%;
  display: flex;
  flex-direction: column;
  // flex: 1;
  max-width: 600px;
  gap: 15px;
  letter-spacing: 1px;
  line-height: 15px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  -webkit-box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
`;

export const Text = styled.div`
  font-weight: 500;
  font-size: 15px;
  color: ${brown};
  transition: all 0.3s ease;

  &.coupon {
    font-size: 16px;
    color: ${redish};
    font-weight: 500;
  }

  &.remove {
    color: ${brown};
    cursor: pointer;

    &:hover {
      transition: all 0.3s ease;
      color: ${green};
      border-bottom: 1px solid ${brown};
    }
  }

  &.sm {
    font-size: 10px;
  }

  &.grey {
    color: ${lightFont};
  }
`;

export const Comment = styled.div`
  color: #4d4d4d;
  font-size: 14px;
  font-weight: 100;
  max-height: 600px;
  width: 100%;
  overflow: auto;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  // flex: 1;
  gap: 20px;

  &.center {
    align-items: center;
  }

  &.noGap {
    gap: 0;
  }

  &.gap10 {
    gap: 10px;
  }

  &.gap2 {
    gap: 2px;
  }
  &.j-sb {
    justify-content: space-between;
  }

  svg {
    path {
      fill: ${green} !important;
    }
  }
`;

export const PaginationDiv = styled.div`
  position: absolute;
  bottom: 15px;
  left: auto;
`;
