import React from "react";

function Icon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="15px"
            height="15px"
            version="1"
            viewBox="0 0 512 512"
        >
            <path
                d="M3570 5114c-223-28-457-114-635-234-108-73-228-186-636-596-441-445-510-531-603-757-81-195-111-370-103-596 12-314 100-556 293-806 81-106 152-170 222-203 49-23 70-27 147-27 82 0 97 3 156 32 79 39 140 102 177 181 23 49 27 70 27 147 0 111-24 168-109 265-121 138-176 267-184 436-6 128 14 224 70 337 39 79 57 98 467 511 467 470 506 502 657 552 77 25 104 29 199 29 73 0 132-6 174-18 186-51 347-183 430-353 51-104 66-174 65-304-1-98-5-127-28-195-49-142-78-179-397-500-351-354-354-358-354-515 0-88 3-101 33-162 91-186 306-255 497-161 67 33 634 601 718 719 116 164 196 347 239 547 31 146 31 408 0 554-61 281-184 510-382 709-262 263-583 401-955 409-82 2-166 1-185-1z"
                transform="matrix(.1 0 0 -.1 0 512)"
            ></path>
            <path
                d="M2763 3216c-104-34-185-106-232-205-22-47-26-70-26-146 0-111 24-168 109-265 121-138 176-268 183-435 6-128-13-221-69-334-41-84-50-94-467-515-467-470-506-502-657-552-77-25-104-29-199-29-125 1-202 17-302 67-170 85-300 244-350 429-12 42-18 101-18 174 0 94 4 122 29 198 16 48 47 116 68 150 24 36 136 159 272 297 191 192 238 246 260 293 92 197-10 434-216 503-74 24-196 16-271-18-97-45-526-478-637-643-98-145-169-316-212-506-32-145-32-405 0-556 91-421 357-774 729-965 450-232 1004-203 1418 75 114 76 221 176 620 579 470 472 531 548 628 778 81 191 112 372 104 599-12 314-100 556-293 806-128 166-219 226-354 232-45 2-92-3-117-11z"
                transform="matrix(.1 0 0 -.1 0 512)"
            ></path>
        </svg>
    );
}

export default Icon;