import { AnnaShimmer, AnnasShimmer, Footer, Header } from "../../components"
import { Container, Main, Image, Ashrafiyan, Column, EmptyDiv, Box } from "./ashrafiya-components"
import ashrafi from '../../assets/credit.jpeg'
import { Hspace } from "../../styles"
import { getAnnas } from '../../@services'
import { useEffect, useState } from "react"
import { userDetails } from "../../utils"
import AnnaHistroy from "../anna-histroy"
import { AnnasMeta } from "../../utils";

const Ashrafify = () => {
    const [annas, setAnnas] = useState<any>()
    const [loading, setLoading] = useState<boolean>(false);

    const getTotalAnnas = async () => {
        setLoading(true)
        let param: any = {
            user_id: userDetails().user_id
        }
        await getAnnas(param).then((res: any) => {
            if (res && res.status) {
                setAnnas(res?.data?.data)
                setLoading(false)
            } else {
                // console.log(res.response)
                setLoading(false)
            }
        })

    }

    useEffect(() => {
        getTotalAnnas()
    }, [])

    return (
        <Main>
            <AnnasMeta />
            <Header />
            <Container>
                {loading && <AnnaShimmer />}
                {
                    !loading &&
                    <>
                        <Image>
                            <img src={ashrafi} alt="ashrafiya" />
                        </Image>
                        <Column>
                            <Ashrafiyan>Annas</Ashrafiyan>

                            <EmptyDiv>
                                <EmptyDiv>HMD is pleased to declare a real decree, announcing that HMD will now reward its devoted subjects with Annas - the hmd's vertual currency. </EmptyDiv>
                            </EmptyDiv>

                            <EmptyDiv>
                                <EmptyDiv>You can utilize Annas to settle your orders, or alternatively, store them for a duration of 60 days before they expire.</EmptyDiv>
                            </EmptyDiv>

                            <EmptyDiv>
                                <EmptyDiv>This is a token of appreciation from HMD, and we hope you enjoy the benefits of Annas as you engage in transactions within HMD.</EmptyDiv>
                            </EmptyDiv>
                            <Hspace ht={20} />
                            <Box>YOU HAVE {annas?.result?.total_annas ? annas?.result?.total_annas : 0}/- {annas?.result?.total_annas <= 0 || annas?.result?.total_annas == undefined ? "ANNA" : "ANNAS"}</Box>

                            <EmptyDiv className="italic">16 ANNA = 1 Rupee</EmptyDiv>
                        </Column>
                    </>
                }
            </Container>
            {!loading && <AnnaHistroy />}
            <Footer />
        </Main>
    )
}

export default Ashrafify