import styled from "styled-components";
const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;

  @media (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: 1024px) {
    grid-template-columns: repeat(3, 1fr);
  }
`;

const BlogShimmer = () => {
    return (
        <GridContainer>
            {Array.from({ length: 9 }).map((_, i) => (
                <div key={i.toString()} className="shine" style={{ height: 350, width: "100%" }}></div>
            ))}
        </GridContainer>
    )
}

export { BlogShimmer }