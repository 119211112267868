import { Castle, CastleText, Column, Container, EmptyDiv, ItemContainer, ItemHead, Main, Row, Span } from "./return-components"
import Line from '../../assets/line.png'
import { Footer, Header, Icon, Overlay, PrimaryButn, ReturnForm } from "../../components"
import { useState } from "react"
import { data, inline } from "./data"
import { Hspace } from "../../styles"
import { EditContainer } from "../address/address-components"
import { ReturnPolicyMeta } from "../../utils";



const Return = () => {
    const [expansed, setExpanded] = useState<boolean>(false)
    const [expandId, setExpandId] = useState<number>();
    const [showForm, setShowForm] = useState<boolean>(false);

    return (
        <Main>
            <ReturnPolicyMeta />
            <Header />
            <Container>
                <Castle>
                    <img width='300px' src={Line} alt="" />
                    <CastleText>Return and Ship Policy</CastleText>
                    <img width='300px' src={Line} alt="" />
                </Castle>
                <Hspace ht={20} />
                <Column>
                    <EmptyDiv className="line">
                        This Privacy Policy explains how we collect, use, and share your personal information when you visit or make a purchase from <a href={process.env.REACT_APP_HOST}>www.hmdindia.in</a>
                    </EmptyDiv>
                    <EmptyDiv style={{ alignSelf: "flex-end" }}><PrimaryButn wd={200} name="Return Product" onClick={() => setShowForm(!showForm)} /></EmptyDiv>
                    {data.map((item: any, i: number) => {
                        return (
                            <ItemContainer key={item.id.toString()} expanded={expansed && item.id == expandId} onClick={() => {
                                setExpanded(prev => !prev);
                                setExpandId(i)
                            }}>
                                <Row className="item-head" actv={expansed && item.id == expandId}>
                                    <ItemHead>{i + 1}. {item?.title}</ItemHead>
                                    <Span><Icon name="backArrow" /></Span>
                                </Row>
                                <EmptyDiv>{item.text}</EmptyDiv>
                            </ItemContainer>
                        )
                    })}
                </Column>
                {showForm &&
                    <Overlay>
                        <EditContainer>
                            <ReturnForm
                                onSubmit={() => { }}
                                onClose={() => setShowForm(false)} onDelete={() => { }} />
                        </EditContainer>
                    </Overlay>
                }
            </Container>

            <Footer />
        </Main>
    )
}

export default Return