export const inline = { marginLeft: 60, display: "inline" };
export const data = [
  {
    id: 0,
    title: "We collect personal information.",
    text: (
      <div>
        <br />
        <div style={inline}>We</div> automatically collect certain information
        about your device when you visit the Site, such as information about
        your web browser, IP address, time zone, and some of the cookies that
        are installed on your device. Furthermore, as you navigate the Site, we
        collect information about the individual web pages or products that you
        view, what websites or search terms referred you to the Site, and how
        you interact with the Site. This automatically collected data is
        referred to as "Device Information" by us.
      </div>
    ),
  },
  {
    id: 1,
    title: "What do we do with your personal information?",
    text: (
      <div>
        <br />
        <div style={inline}>We</div> use the Order Information we collect in
        general to fulfill any orders placed through the Site (including
        processing your payment information, arranging to ship, and sending you
        invoices and/or order confirmations). Furthermore, we use this Order
        Information to:
        <br />
        <div style={inline}>&#8226; Communicate with you;</div>
        <br />
        <div style={inline}>
          &#8226; Screen our orders for potential risk or fraud;
        </div>
        <br />
        <div style={inline}>
          &#8226; and, based on your preferences, provide you with information
          or advertising about our products or services.
        </div>
        <br />
        <div style={inline}>We</div> collect device information to help us
        screen for potential risk and fraud (specifically, your IP address), as
        well as to improve and optimize our site in general (for example, by
        generating analytics about how our customers browse and interact with
        the site and assessing the success of our marketing and advertising
        campaigns).
      </div>
    ),
  },

  {
    id: 2,
    title: "Personal Information Disclosure.",
    text: (
      <div>
        <br />
        <div style={inline}>We</div> also use Google Analytics to better
        understand how our customers interact with the site. You can find out
        more about how Google uses your personal information here:
        <a href="https://policies.google.com/privacy?hl=en">
          https://policies.google.com/privacy?hl=en.
        </a>{" "}
        You can also disable Google Analytics by visiting{" "}
        <a href="https://tools.google.com/dlpage/gaoptout">
          https://tools.google.com/dlpage/gaoptout.
        </a>
        <br />
        <div style={inline}>Finally,</div>
        we may share your personal information in order to comply with
        applicable laws and regulations, respond to a subpoena, search warrant,
        or another lawful request for information we receive or protect our
        rights in other ways.
        <br />
      </div>
    ),
  },
  {
    id: 3,
    title: "Behavioral marketing.",
    text: (
      <div>
        <br />
        <div style={inline}>As</div> previously stated, we use your personal
        information to send you targeted advertisements or marketing
        communications that we believe will be of interest to you. To learn more
        about how targeted advertising works, go to{" "}
        <a href="http://www.networkadvertising.org/understanding-online-advertising/how-does-it-work">
          http://www.networkadvertising.org/understanding-online-advertising/how-does-it-work.
        </a>
        <br />
        <div style={inline}>You</div>
        can opt out of targeted advertising by clicking on the following links:
        <br />
        <div style={inline}>&#8226; Facebook </div>settings page:
        <a href="https://www.facebook.com/settings/?tab=ads">
          https://www.facebook.com/settings/?tab=ads
        </a>
        <br />
        <div style={inline}>&#8226; Google </div>settings page:
        <a href="https://www.google.com/ads/anonymous">
          https://www.google.com/ads/anonymous
        </a>
        <br />
        <div style={inline}>&#8226; Microsoft Bing </div>
        <a href="https://advertise.bingads.microsoft.com/en-us/resources/policies/personalized-ads">
          https://advertise.bingads.microsoft.com/en-us/resources/policies/personalized-ads
        </a>
        <br />
      </div>
    ),
  },

  {
    id: 4,
    title: "Do not follow.",
    text: (
      <div>
        <br />
        <div style={inline}>Please</div> be aware that when we receive a Do Not
        Track signal from your browser, we do not alter our site's data
        collection and use practices.
      </div>
    ),
  },
  {
    id: 5,
    title: "Your legal rights.",
    text: (
      <div>
        <br />
        <div style={inline}>You</div> have the right to access your personal
        information and have it corrected, updated, or deleted. Please contact
        us using the information provided below if you wish to exercise this
        right.
        <br />
        <div style={inline}>Furthermore, </div> if you are a European resident,
        please be aware that we are processing your information in order to
        fulfill contracts we may have with you (for example, if you place an
        order through the Site) or to pursue our legitimate business interests
        listed above.
      </div>
    ),
  },

  {
    id: 6,
    title: "Data storage.",
    text: (
      <div>
        <br />
        <div style={inline}>When</div> you place an order through the site, we
        will keep your order information for our records unless and until you
        request that we delete it.
      </div>
    ),
  },
  {
    id: 7,
    title: "Changes.",
    text: (
      <div>
        <br />
        <div style={inline}>We</div> reserve the right to modify this privacy
        statement at any time to reflect changes in our practices or for other
        operational, legal, or regulatory reasons. Keep visiting our Privacy
        Policy page to keep yourself up-to-date.
      </div>
    ),
  },
  {
    id: 8,
    title: "Contact us.",
    text: (
      <div>
        <br />
        <div style={inline}>For</div> more information about our privacy policy,
        if you have any questions, or if you want to file a complaint, please
        contact us via email at <a href="mailto:hello@hmdindia.in">hello@hmdindia.in</a> in
        or by mail at the address listed below
      </div>
    ),
  },
];