import { ReactNode } from "react";
import { BackArrow } from "../icon/icons";
import { CloseButton, Overlay } from "./overlay-components"

type Props = {
    children: any;
    onClose?: () => void

}

const OverlayS = ({ children, onClose }: Props) => {
    return (
        <Overlay>
            {onClose &&
                <CloseButton onClick={onClose}>
                    <BackArrow />
                </CloseButton>}
            {children}
        </Overlay>
    )
}

export default OverlayS;