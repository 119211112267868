import { useCartContext } from "../../context";
import React, { useState } from "react";
import { Hline } from "../../styles"
import PrimaryBtn from "../Buttons/primary/primary-button"
import { ApplyButton, CheckoutCOntainer, Close, Column, Inner, InnerContainer, Input, Row, OR, Text, CouponLabel, CouponCOntainer, CouponRow, CouponCode, CouponMessage, CouponHolder } from "./checkout-coupon-components"
import { toast } from "react-toastify";
import { CouponShimmer } from "../shimmers";
import Icon from "../icon";

const Coupon: any = React.memo(({ takeCoupons, setSelecetdCoupon, closePopup }: any) => {
    const { totalAmount } = useCartContext()
    const [selectCupon, setSelectCOupon] = useState<any>();
    const onOptionChange = (e: any) => {
        let checkMinOrder = takeCoupons.filter((item: any) => item?.coupon_code == e.target.id)[0]?.min_order
        if (checkMinOrder <= totalAmount) {
            setSelectCOupon(e.target.id);
        } else {
            setSelectCOupon('')
            toast(`Minimum order should be ${checkMinOrder}`)
        }
    }

    const filterCoupon = takeCoupons.filter((item: any) => item?.coupon_code == selectCupon);
    const Done = () => {
        setSelecetdCoupon(filterCoupon)
        closePopup();
    }

    return (
        <CouponCOntainer>
            <CouponHolder>
                {takeCoupons.map((item: any) => {
                    return (
                        <CouponRow key={item?.coupon_code}>
                            <input type="radio" id={item?.coupon_code} value={item?.coupon_code} checked={item?.coupon_code == selectCupon} onChange={onOptionChange} />
                            <CouponCode>
                                <CouponCode htmlFor={item?.coupon_code}>{item?.coupon_code}</CouponCode>
                                <CouponMessage>{item?.message}</CouponMessage>
                            </CouponCode>
                        </CouponRow>
                    )
                })}
            </CouponHolder>
            <PrimaryBtn padding={7} wd={80} name="Done" onClick={Done} />
        </CouponCOntainer>
    )

});



const CheckoutCoupon = ({ coupons, isCouponLoad, setIsCouponLoad, selectedCoupon, setSelecetdCoupon, onClose }: any) => {
    return (
        <CheckoutCOntainer>
            <InnerContainer>
                <Inner>
                    <Close onClick={onClose}><Icon name="cross" /></Close>
                    <Column>
                        {!selectedCoupon && <Text>Select a Coupon</Text>}
                        {isCouponLoad && <CouponHolder><CouponShimmer /></CouponHolder>}
                        {!selectedCoupon?.length ? <Coupon takeCoupons={coupons} setSelecetdCoupon={setSelecetdCoupon} closePopup={onClose} /> :
                            <>
                                <Row>
                                    <Text>Applied Coupon</Text><PrimaryBtn name="Change" padding={3} wd={80} onClick={() => setSelecetdCoupon("")} />
                                </Row>
                                <Row className="">
                                    {/* <Input className="noWidth" type="checkbox" id="coupon" /> */}
                                    <CouponLabel htmlFor="coupon">{selectedCoupon[0]?.coupon_code}</CouponLabel>

                                </Row>
                                <Text className="small">{selectedCoupon[0]?.message}</Text>
                            </>
                        }
                    </Column>
                </Inner>
            </InnerContainer>
        </CheckoutCOntainer>
    )
}

export default CheckoutCoupon