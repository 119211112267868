import { useCartContext } from "../../context";
import React, { useCallback, useEffect, useState } from "react";
import { Hline } from "../../styles"
import PrimaryBtn from "../Buttons/primary/primary-button"
import { ApplyButton, CheckoutCOntainer, Close, Column, Inner, InnerContainer, Input, Row, OR, Text, CouponLabel, ProdCOntainer, CouponRow, CouponCode, CouponMessage, ProdHolder, Textarea, LoaderDiv } from "./review-prod-components"
import { toast } from "react-toastify";
import { CouponShimmer, ReviewShimmer } from "../shimmers";
import Icon from "../icon";
import ReactStars from "react-rating-stars-component";
import { getProdByOrderId, prodReviews } from "../../@services";
import { userDetails } from "../../utils";
import Loader from "../loader/loader";
import { Selected } from "../../modules/checkout/checkout-components";

const ReviewProd = ({ orderId, onClose }: any) => {
    const [prods, setProds] = useState<Array<any>>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [selected, setSelected] = useState<number>(0);
    const [comment, setComment] = useState<string>("")
    const [rating, setRating] = useState<number>(0)
    const [showInput, setSHowInput] = useState<any>({ ordId: 0, show: false })
    const [postReview, setPostreview] = useState<boolean>(false);

    const deliveryStarProps = {
        size: 20,
        count: 5,
        color: "black",
        activeColor: "red",
        a11y: true,
        isHalf: true,
        emptyIcon: <Icon name="emptyStar" />,
        halfIcon: <Icon name="halfStar" />,
        filledIcon: <Icon name="filledStar" />,
        classNames: 'stars',
    };

    async function getProds() {
        setLoading(true)
        let params: any = {
            user_id: userDetails()?.user_id,
            order_no: orderId
        }
        await getProdByOrderId(params).then((res: any) => {
            if (res && res?.status) {
                setProds(res?.data?.data);
                setLoading(false)
            } else {
                toast(res?.response?.data?.message);
                setLoading(false)
            }
        })
    }

    async function updateReview(prodId: number) {
        setPostreview(true)
        let params: any = {
            user_id: userDetails()?.user_id,
            product_id: prodId,
            rating: rating,
            comment: comment

        }
        await prodReviews(params).then((res: any) => {
            if (res && res?.status) {
                toast('Review updated successfully')
                getProds()
                setSelected(0)
                setComment('')
                setRating(0)
                setPostreview(false)
                setSHowInput({ ordId: 0, show: false })
            } else {
                toast(res?.response?.data?.message)
                setPostreview(false)
                getProds()
            }
        })
    }

    const publishReview = useCallback(async (prodId: number) => {
        // if (selected)
        setSelected(prodId)
        await updateReview(prodId)
    }, [selected, comment])

    const handleChange = (e: any, item) => {
        const { id, name, value } = e.target;
        setComment(value);
        setSelected(item?.product?.id)
    }

    const toggleInput = (item) => {
        setSHowInput({ ordId: item?.id, show: !showInput.show })
        setComment('')
        setSelected(item?.product?.id);
    }

    const onRating = (e: any, item: any) => {
        console.log("🚀 ~ file: review-prod.tsx:99 ~ onRating ~ e:", e)
        setRating(e)
        setSelected(item?.product?.id)
    }


    useEffect(() => {
        getProds();
    }, [])

    return (
        <CheckoutCOntainer>
            <InnerContainer>
                <Inner>
                    <Close onClick={onClose}><Icon name="cross" /></Close>
                    <Column>
                        <ProdHolder>
                            {!loading && prods.map((item: any, i: number) => {
                                return (
                                    <Column key={i.toString()} className="back gap5">
                                        <Row className="gap5">
                                            <CouponCode htmlFor={item?.product?.name}>{item?.product?.name}</CouponCode>
                                            {item?.reviewed != true &&
                                                <ReactStars {...deliveryStarProps}
                                                    value={rating}
                                                    onChange={(e: any) => onRating(e, item)}>
                                                </ReactStars>
                                            }
                                        </Row>
                                        <Row>
                                            {item?.reviewed != true ? <CouponMessage onClick={() => toggleInput(item)}>Write a review</CouponMessage> :
                                            <CouponMessage className="light-g"> <Icon name="tick" /> Already reviewed </CouponMessage>}
                                        </Row>
                                        {showInput?.ordId == item?.id && showInput?.show == true && <Textarea name={item?.id.toString()} value={comment} onChange={(e) => handleChange(e, item)} />}
                                        {item?.reviewed != true && 
                                        <ProdCOntainer>
                                            <PrimaryBtn name="Done" isDisabled={item?.product?.id != selected} padding={2} wd={60} onClick={() => publishReview(item?.product?.id)} />
                                        </ProdCOntainer>}
                                    </Column>

                                )
                            })}
                            {postReview ? <LoaderDiv><Loader /></LoaderDiv> : null}
                        </ProdHolder>
                        {loading && <ProdHolder><ReviewShimmer /></ProdHolder>}
                    </Column>
                </Inner>
            </InnerContainer >
        </CheckoutCOntainer >
    )
}

export default ReviewProd