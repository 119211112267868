export const inline = { marginLeft: 60, display: "inline" };
export const inline2 = { marginLeft: 80, display: "inline", fontSize: 15 };
export const inline3 = { marginLeft: 100, display: "inline", fontSize: 12 };

export const data = [
  {
    id: 0,
    title: "Shipping Policy",
    text: (
      <div>
        <br />
        <div style={inline}>At</div> hmdindia.in, we understand that our
        customers expect to receive their orders in a timely manner and in
        perfect condition. When you place an order at HMD INDIA. We process your
        order as soon as possible. HMD INDIA uses different courier partners for
        shipping your order across India. Orders are shipped within 2-3 business
        days of the order being placed. Once the order has been shipped, you
        will receive a tracking number via email/sms. International orders may
        take up to 14 business days to arrive. We do not ship on weekends or
        holidays. HMD INDIA reserves the right to cancel any order within 48
        hours from the time of order due to any cause. If you have any questions
        about our shipping policy, please contact us at{" "}
        <a href="mailto:hello@hmdindia.in">hello@hmdindia.in</a>
        <br />
        <div style={inline}>
          NOTE: We are not responsible for shipping carrier delays.
        </div>
      </div>
    ),
  },
  {
    id: 1,
    title: "Replacement Policy",
    text: (
      <div>
        <br />
        <div style={inline}>We</div> have a 7-day exchange/return policy, which
        means you have 7 days from the time you receive your item to request a
        replacement or return it. You can reach us at{" "}
        <a href="mailto:hello@hmdindia.in">hello@hmdindia.in</a> in for an
        exchange/return.
        {/* <br /> */}
        {/* <div style={inline}>ADD</div> HERE REPLACEMENT FORM BUTTON.
        <br />
        <div style={inline}>A</div> popup will be show on click and show the
        form for replacement:
        <br />
        <div style={inline}>&#8226; Phone Number</div>
        <br />
        <div style={inline}>&#8226; Email Id</div>
        <br />
        <div style={inline}>&#8226; Order No.</div>
        <br />
        <div style={inline}>&#8226; Do</div> you have original packaging intact?
        Yes/No - radio
        <br />
        <div style={inline}>&#8226; What</div> is the problem you are facing ?
        <br />
        <div style={inline2}>a. Received</div> damage product
        <br />
        <div style={inline2}>b. Facing</div> quality issue
        <br />
        <div style={inline2}>c. Other</div>
        <br />
        <div style={inline3}>&#8226; Open a text box to enter other</div>
        <br />
        <div style={inline3}>&#8226; Describe your issue in brief</div>
        <br />
        <div style={inline3}>&#8226; Text Area</div> */}
      </div>
    ),
  },
  {
    id: 2,
    title: "Returns and Exchanges",
    text: (
      <div>
        <br />
        <div style={inline}>&#8226; To</div> be eligible for an exchange or
        return, your item must be unused and in its original packaging, and it
        must be in the same condition that you received it. The invoice must be
        provided at the time of return pickup to complete your exchange or
        return. Products that have been used are not eligible for exchange or
        return.
      </div>
    ),
  },
  {
    id: 3,
    title:
      "Exchanges/returns are only permitted in the following unlikely circumstances:",
    text: (
      <div>
        <br />
        <div style={inline}>&#8226; If</div> the product is damaged or if you
        received the incorrect item, please contact us.
        <br />
        <div style={inline}>&#8226; At</div> the time of delivery, the product
        is not properly sealed.
        <br />
        <div style={inline}>&#8226; By</div> the time the product was delivered,
        it had expired.
        <br />
        <div style={inline}>&#8226; If</div> your return is accepted, we will
        pick up the item from the same address and notify you of the expected
        pick-up date. If you request an exchange, the product will be delivered
        to you within 3-5 days of the return pickup. If there is a delivery
        issue, please contact us within 48 hours of the order being delivered.
        <br />
        <div style={inline}>
          Note: If you have any quality concerns, please contact us at +91
          9852100215 or send an email to{" "}
          <a href="mailto:hello@hmdindia.in">hello@hmdindia.in</a>
        </div>
      </div>
    ),
  },
  {
    id: 4,
    title: "Cancellation",
    text: (
      <div>
        <br />
        <div style={inline}>&#8226; An</div> order cancellation request will be
        accepted only if we have not yet shipped the product.
        <br />
        <div style={inline}>&#8226; If</div> a cancellation request is accepted,
        you are entitled to get a refund of the entire amount.
        <br />
        <div style={inline}>&#8226; By</div> the time the product was delivered,
        it had expired.
        <br />
        <div style={inline}>&#8226; If</div>your return is accepted, we will
        pick up the item from the same address and notify you of the expected
        pick-up date. If you request an exchange, the product will be delivered
        to you within 3-5 days of the return pickup. If there is a delivery
        issue, please contact us within 48 hours of the order being delivered.
        <br />
        <div style={inline}>HMD INDIA </div>
        reserves the right to cancel or refuse to accept any order placed for
        various reasons, including but not limited to the non-availability of
        stock, pricing errors, informational errors or problems identified with
        the personal/financial details provided by the customer
      </div>
    ),
  },

  {
    id: 5,
    title: "Refunds",
    text: (
      <div>
        <br />
        <div style={inline}>Once</div> your return is received and inspected, we
        will notify you that we have received your returned item. We will also
        notify you if the refund was approved or not. If approved, you'll be
        automatically refunded on your original payment method. Please note that
        it generally takes around 5-7 working days to reflect this amount. You
        can always contact us for any return question at:{" "}
        <a href="mailto:hello@hmdindia.in">hello@hmdindia.in</a>
      </div>
    ),
  },
];
