import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Header from '../header'
import Loader from '../loader/loader'
import { useNavigate, userDetails } from '../../utils'
import { verifyEmail } from '../../@services'
import { toast } from 'react-toastify'
import Toast from '../toast'
import { useLocation } from 'react-router-dom'


const Center = styled.div`
// position: absolute;
// top: 0;
// left: 0;
// right: 0;
// bottom: 0;
// z-index: 53;
background-color: #998d8d61;
height: 100vh;
display: flex;
align-items: center;
justify-content: center;
flex-direction: column;
// backdrop-filter: blur(7px);
gap: 20px;

`

const LoaderText = styled.div`
    font-size: larger;
    font-weight: 500;
    max-width: 350px;
    text-align: center;
    // background-color: white;
    // color: black;
    padding: 18px;
    height: 200px;
`

const Success = () => {
    const { navigate } = useNavigate();
    const [data, setData] = useState<any>();
    const [loading, setLoading] = useState<boolean>(false);
    const location = useLocation();
    const [code, setCode] = useState('');
    const [uId, setUId] = useState('');
    const [msg, setMsg] = useState<string>('')

    useEffect(() => {
        // Parse URL parameters
        const searchParams = new URLSearchParams(location.search);
        const codeParam = searchParams.get('code');
        const uIdParam = searchParams.get('uId');

        // Set state with parameters
        setCode(codeParam || '');
        setUId(uIdParam || '');

        // Run your API call with the parameters
        if (codeParam && uIdParam) {
            Verify(codeParam, uIdParam)
        }
    }, [location.search]);


    const Verify = async (email: any, userId: any) => {
        setLoading(true)

        let params: any = {
            email_key: email,
            user_id: parseInt(atob(userId))
        }
        await verifyEmail(params).then(async (res: any) => {
            if (res && res.status == 200) {
                setMsg(res?.data?.message)
                setLoading(false)
                await new Promise((resolve) => setTimeout(resolve, 5000));
                window.location.href = `${process.env.REACT_APP_HOST}`
            } else if (res && res.status == 203) {
                setMsg(res?.data?.message)
                setLoading(false)
                await new Promise((resolve) => setTimeout(resolve, 5000));
                window.location.href = `${process.env.REACT_APP_HOST}`
            } else {
                toast(res?.response?.data?.message)
                setLoading(false)
            }
        })
    }
    return (
        // <Header />
        <div style={{ position: "relative" }}>
            <Center>
                {loading && <Loader />}
                {msg.length ? <LoaderText>{msg}</LoaderText> : ""}
            </Center>
            <Toast />
        </div>
    )
}

export default Success
