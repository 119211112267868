import { darkFont, greyBackground, lightFont } from "../../styles";
import styled from "styled-components";

export const Main = styled.div`
overflow: auto;
height: 100%;
max-height: 100dvh;
`

export const Container = styled.div`
display: flex;
flex-direction: row;
flex: 1;
// width: 100%;
gap:20px;
// margin: 0 80px;
@media only screen and (max-width: 767px) {
    flex-direction: column;
}
`


export const Image = styled.div`
position: relative;
flex: 1;
// aspect-ratio: 4/3;
max-width: 50%;
object-fit: cover;

img {
    width: 100%;
}

@media only screen and (max-width: 767px) {
    max-width: 100%;
}

`

export const Ashrafiyan = styled.div`
font-weight: 300;
    color: #000;
    font-size: 35px;
`

export const Column = styled.div`
display: flex;
flex-direction: column;
align-items: center;
justify-content: flex-start;
flex: 1;
padding: 20px;
gap: 20px;
`

export const EmptyDiv = styled.div`
text-align: center;

&.italic {
    font-style: italic;
}
`

export const Box = styled.div`
//   display: flex;
//   flex-direction: row;
//   justify-content: space-between;
  align-items: center;
  background-color:  #f2eade;
  color: ${lightFont};
  padding: 15px;
  border-radius: 8px;
  width: 90%;
  text-align: center;
  padding: 20px 0;
  font-size: 18px;
  font-weight: 600;
`;


