import React, { useState } from "react";
import { RWebShare } from "react-web-share";
import Icon from "../icon";
import { IconBack } from "./share-comp";


function ShareApiButton() {
    const shareData: any = {
        title: "MDN",
        text: "Learn web development on MDN!",
        url: "https://developer.mozilla.org",
    };
    const btn: any = document.querySelector("button");
    const click = async () => {
        try {
            await navigator.share(shareData);
            //   resultPara.textContent = "MDN shared successfully";
        } catch (err) {
            //   resultPara.textContent = `Error: ${err}`;
        }
    };

    return (
        <RWebShare
            data={{
                text: "Like humans, flamingos make friends for life",
                url: "https://on.natgeo.com/2zHaNup",
                title: "Share this article on Flamingos"
            }}
            onClick={() => console.info("share successful!")}
        >
            <IconBack className="clip share-button" onClick={() => navigator.share({
                text: "Like humans, flamingos make friends for life",
                url: "https://on.natgeo.com/2zHaNup",
                title: "Share this article on Flamingos"
            })}><Icon name="shareUrl" /></IconBack>
        </RWebShare>
    )
}
export default ShareApiButton;
