import { redish, whiteColor } from "../../../styles";
import styled from "styled-components";

type Props = {
    pad?: number;
    wid?: number;
    align?: string;
    justify?: string;
    shadow?: boolean;
    backClr?: string;
    fSz?: number;
    isDisabled?: boolean;
};

export const Button = styled.div`
  border-radius: 6px;
  padding: 5px 10px;
  background-color: ${redish};
  color: ${whiteColor};
  font-weight: 500;
  transition: all 0.3s ease;
  display: flex;
  border: 1px solid transparent;
  cursor: not-allowed;
  &:hover {
    background-color: transparent;
    color: ${redish};
    border: 1px solid ${redish};
  }

  ${({ isDisabled }: Props) => {
        if (isDisabled) {
            return `
      font-style: italic;
      opacity: 0.5;
      &:hover {
        background-color: ${redish};
        color: ${whiteColor}
      }
      `;
        } else {
            return `cursor: pointer;`
        }
    }};

  ${({ pad }: Props) => {
        if (pad) {
            return `padding: ${pad}px;`;
        } else {
            return `padding: 10px;`;
        }
    }};

  ${({ fSz }: Props) => {
        if (fSz) {
            return ` font-size: ${fSz}px;`;
        } else {
            return ` font-size: 15px;`;
        }
    }};

  ${({ backClr }: Props) => {
        if (backClr) {
            return `background-color: ${backClr};`;
        } else {
            return `background-color: ${redish};`;
        }
    }};

  ${({ wid }: Props) => {
        if (wid) {
            return `width: ${wid}px;`;
        } else {
            return `width: 118px;`;
        }
    }};

  ${({ align }: Props) => {
        if (align) {
            return `align-items: ${align};`;
        } else {
            return `align-items: center;`;
        }
    }};

  ${({ justify }: Props) => {
        if (justify) {
            return `justify-content: ${justify};`;
        } else {
            return `justify-content: center;`;
        }
    }};

  ${({ shadow }: Props) => {
        if (shadow) {
            return `box-shadow: rgb(0 0 0) 2px 1px 4px -2px;`;
        } else {
            return `box-shadow: unset`;
        }
    }};
`;
