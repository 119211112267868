import React, { useEffect, useState } from 'react'
import { getProductReviews } from '../../@services'
import { Card, CardHolder, Comment, Container, Heading, PaginationDiv, Row, Text } from './prod-reviews-components'
import ReactStars from "react-rating-stars-component";
import { Icon, Paginate, ReviewsShimmer } from '../../components';
import { orderTimeAndDate, userDetails } from '../../utils';
import { Hline } from '../../styles';
import { capitalize } from '../../utils';

const foodStar = {
    size: 20,
    count: 5,
    color: "black",
    activeColor: "red",
    a11y: true,
    isHalf: true,
    emptyIcon: <Icon name="emptyStar" />,
    halfIcon: <Icon name="halfStar" />,
    filledIcon: <Icon name="filledStar" />,
    edit: false,
    onChange: (newValue) => {
        console.log(`Example 2: new value is ${newValue}`);
    }
};
const ProductReviews = ({ productId, handleRating }: any) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [reviews, setReviews] = useState<any>([]);
    const [page, setPage] = useState<number>(1);

    const getReviews = async () => {
        setLoading(true)
        let param: any = {
            "product_id": productId,
            page: page
        }
        await getProductReviews(param).then((res: any) => {
            if (res && res?.status) {
                setReviews(res?.data?.data);
                handleRating(res?.data?.data)
                setLoading(false)
            } else {
                console.log(res?.response?.data)
                setLoading(false)
            }
        })
    }



    const handlePageChange = async (e: any, pageNo: number) => {
        setLoading(true)
        setPage(pageNo);
        let params: any = {
            product_id: productId,
            page: pageNo
        }
        await getProductReviews(params).then((res: any) => {
            if (res && res?.status) {
                // console.log(res?.data?.data)
                setReviews(res?.data?.data);
                setLoading(false)
            } else {
                console.log(res?.response?.data)
                setLoading(false)
            }
        })
    }

    useEffect(() => {
        if (productId) {
            getReviews();
        }
    }, [])
    // console.log(reviews)

    if (!loading && (!reviews?.reviews?.data || reviews?.reviews?.data?.length <= 0)) {
        return <div></div>
    } else {
        return (
            <Container>
                <Heading>Product Review { reviews?.total ? `(${reviews?.total})` : ""}</Heading>
                {!loading ? <CardHolder>
                    {reviews?.reviews?.data?.map((item: any, index: number) => (
                        <Card key={index.toString()}>
                            <Text>{capitalize(item?.user?.name)}</Text>
                            <Comment>{item?.comment}</Comment>
                            <Hline />
                            <Row className='j-sb'>
                                <Text className='sm grey'>{orderTimeAndDate(item?.created_at)}</Text>
                                <ReactStars {...foodStar} value={item?.rating} />
                            </Row>
                        </Card>

                    ))}

                </CardHolder> : <ReviewsShimmer />}
                <PaginationDiv>
                    <Paginate totalPage={Math.ceil(reviews?.total / 20)}
                        page={page} setPage={setPage} onPageChange={handlePageChange} />
                </PaginationDiv>
            </Container>
        )
    }
}

export default ProductReviews