import { Hspace } from "../../styles"
import styled from "styled-components"
export const DescpCont = styled.div`
padding: 40px 80px;
// min-height:  calc(100vh - 225px);
// height: 100%;
// width: 100%;
@media only screen and (max-width: 458px) {
    padding: 20px 20px;
}
`

const ProductDetail = styled.div`
// position: relative;
display: flex;
flex-direction: row;
flex: 1;
// width: 100%;
gap:20px;
margin-bottom: 20px;

@media only screen and (max-width: 767px) {
    flex-direction: column;
}
`

export const Image = styled.div`
position: relative;
flex: 1;

div {
    width: 100%;
    // height: 100%;
    position: relative;
    aspect-ratio: 4/4;
    max-height: 400px;
}

@media only screen and (max-width: 767px) {
    max-width: 100%;
}
`

const ItemCol = styled.div`
display: flex;
flex-direction: column;
gap: 10px;

&.gap20 {
    gap: 20px;
}
`

const ProductReviewShimmer = styled.div`
display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, auto);
    gap: 20px;
        width: 100%;

    @media screen and (max-width: 991px) {
        grid-template-columns: auto;
    }
`
export const DescriptionShimmer = () => {
    return <DescpCont className=''>
        {/* <div className='shine' style={{height: '100%', width: "100%"}}></div> */}
        <ProductDetail>
            <Image>
                <div className='shine'></div>
            </Image>
            <Image>
                <div style={{ backgroundColor: "transparent" }}>
                    <div className='shine' style={{ height: 20 }}></div>
                    <Hspace ht={20} />
                    <div className='shine' style={{ height: 30 }}></div>
                    <Hspace ht={10} />
                    <div className='shine' style={{ height: 5 }}></div>
                    <div className='shine' style={{ height: 5 }}></div>
                    <div className='shine' style={{ height: 5 }}></div>
                    <div className='shine' style={{ height: 5 }}></div>
                    <div className='shine' style={{ height: 5 }}></div>
                    <div className='shine' style={{ height: 5 }}></div>
                    <Hspace ht={70} />
                    <div className='shine' style={{ height: 50 }}></div>
                    <Hspace ht={40} />
                    <ItemCol className=''>
                        <div className='shine' style={{ height: 40, width: 250 }}></div>
                        <Hspace ht={10} />
                        <div className='shine' style={{ height: 10, width: 210 }}></div>
                        <div className='shine' style={{ height: 10, width: 210 }}></div>
                        <div className='shine' style={{ height: 10, width: 210 }}></div>
                        <div className='shine' style={{ height: 10, width: 210 }}></div>

                        <div className='shine' style={{ height: 40, width: 250 }}></div>
                        <Hspace ht={10} />
                        <div className='shine' style={{ height: 10, width: 210 }}></div>
                        <div className='shine' style={{ height: 10, width: 210 }}></div>
                        <div className='shine' style={{ height: 10, width: 210 }}></div>
                        <div className='shine' style={{ height: 10, width: 210 }}></div>
                    </ItemCol>
                </div>
            </Image>
        </ProductDetail>
    </DescpCont>
}


export const ReviewsShimmer = () => {
    return (
        <ProductReviewShimmer>
            {Array.from({ length: 10 }).map((_, i: number) => (
                <div key={i.toString()} className='shine' style={{ height: "150px", width: "100%" }}></div>
            ))}
        </ProductReviewShimmer>
    )
}