import { capitalize } from "../../utils";
import { darkBorder, Hspace } from "../../styles"
import Primary from "../Buttons/primary"
import { Card, Row, Address, AddText, AddButton, NewBoxAlign } from "./address-card-components"

type Props = {
    addNewAddress?: string;
    onEditClicked: any;
    onDeleteCliked: any;
    onAddClicked?: any;
    data?: any;
}


const AddressCard = ({ addNewAddress, onEditClicked, onDeleteCliked, onAddClicked, data }: Props) => {
    return (
        <>
            {/* {addNewAddress &&
                <Card className="add" bdrStyl={addNewAddress}>
                    <Address>
                        <NewBoxAlign>
                            <AddText>ADD A NEW CASTLE</AddText>
                            <Hspace ht={15} />
                            <AddButton onClick={onAddClicked}>ADD NEW</AddButton>
                        </NewBoxAlign>
                    </Address>
                </Card>
            } */}
            <Card>
                <Address>
                    <div>{capitalize(data?.location)}</div>
                    <div>{data?.flat_house_no}, {capitalize(data?.address1)}, {capitalize(data?.address2)}, {capitalize(data?.street)} </div>
                    <div>{capitalize(data?.city)} {capitalize(data?.state)} {data?.zip}</div>
                    <div>Landmark - {capitalize(data?.landmark)}</div>
                </Address>
                <Row className="gap20">
                    <Primary padding={8} wd={70} name="" backColor={darkBorder} onClick={onEditClicked}>Edit</Primary>
                    <Primary padding={8} wd={70} name="" onClick={onDeleteCliked}>Delete</Primary>
                </Row>
            </Card>
        </>
    )
}

export default AddressCard