import styled from "styled-components";
import { green } from "../../styles";

export const Row = styled.div`
  display: flex;
  gap: 10px;
  width: 100%;
  max-width: 400px;

  &.j-sb {
    justify-content: space-between;
  }
`;
export const ColumnEnd = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 5px;

  &.rating {
    align-items: flex-start;
    svg {
      path {
        fill: ${green} !important;
      }
    }
  }
`;

export const LineOuter = styled.div`
  width: 100%;
  max-width: 200px;
  height: 17px;
  background-color: #e9e9e9;
  position: relative;
  z-index: 50;
  overflow: hidden;

  @media only screen and (max-width: 458px) {
    max-width: 100px;
  }
`;

export const LineInner = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: ${green};
  z-index: 51;

  ${({ wd }: { wd?: number }) => {
    if (wd) {
      return `width: ${wd}%`;
    } else {
      return `width: 0%`;
    }
  }}
`;

export const Text = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  font-size: 12px;
  background-color: rgb(210 251 216);
  color: ${green};
  padding: 3px 5px;

  &.heavy {
    font-size: 20px;
    font-weight: 500;
    // display: block;
    text-align: start;
  }

  &.f700 {
    font-weight: 800;
  }
`;
