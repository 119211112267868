import "./App.css";
// import './css/style.css'
// import './css/custom.css'
// import './css/bootstrap/bootstrap-grid.css'
// import './css/bootstrap/bootstrap.css'
// import './css/bootstrap/bootstrap-reboot.css'
import { BrowserRouter } from "react-router-dom";
import { LayoutContainer } from "./modules";
import { useEffect, useState } from "react";
import { getAllCategory } from "./@services";
import { service } from "./@services/service";
import { toast } from "react-toastify";
import { CartContext } from "./context";
import ErrorHandler from "./components/error";
const App = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [cartItems, setCartItems] = useState<any>([]);
  const [cartProducts, setCartProducts] = useState<any>([]);
  const [error, setError] = useState<string>("");
  const [cartAmount, setCartAmount] = useState<number>(0);
  const [annaValue, setAnnaValue] = useState<number>(0);



  const totalAmount = cartProducts?.reduce((total, item) => {
    const itemCost = item?.amount * item.count;
    return total + itemCost;
  }, 0);


  const getAllCategorys = async () => {
    if (!sessionStorage.getItem('food-categories')) {
      setLoading(true)
      await getAllCategory()
        .then((res: any) => {
          if (res && res.data.status && res.data.status_code == 200) {
            sessionStorage.setItem("food-categories", JSON.stringify(res.data.data))
            setLoading(false)
          }
        })
        .catch((err: any) => {
          console.log(err)
          setLoading(false)
        })
    }
  }

  // useEffect(() => {
  //   getAllCategorys()
  // }, [])

  useEffect(() => {
    const storedCartProducts: any = localStorage.getItem("cartItems");
    if (storedCartProducts) {
      setCartItems(JSON.parse(storedCartProducts));
      setCartProducts(JSON.parse(storedCartProducts))
    }
  }, []);




  service.interceptors.response.use(
    (response) => {
      // if (response.status === 200) {
      //   setError('')
      // }
      return response;
    },
    (error) => {
      let errorCode: number = error?.response?.data?.status_code;
      if (errorCode == 419 || errorCode == 401 || errorCode == 403) {
        localStorage.removeItem('hmd_access_token');
        localStorage.removeItem('hmd_user_details')
        setError(error.response.data.message);
      }
      return Promise.reject(error);
    }
  )

  return (
    <div className="App">
      {error ? <ErrorHandler error={error} /> : null}
      <CartContext.Provider value={
        {
          cartItems, setCartItems,
          cartProducts, setCartProducts,
          totalAmount, cartAmount,
          setCartAmount, annaValue, setAnnaValue
        }
      }>
        {loading ?
          <div>Loading...</div> :
          <BrowserRouter basename='/'>
            <LayoutContainer />
          </BrowserRouter>
        }
      </CartContext.Provider>
    </div>
  );
}

export default App;
