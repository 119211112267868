import { service } from "../service"

export const getBlogs = async (params?: any) => {
    let url = `/api/v1/get-all-blog?page=${params?.page}`;
    return service
        .get(url, params)
        .then((res: any) => res)
        .catch((err: any) => err)
}


export const searchBlogs = async (body: any) => {
    let url = '/api/v1/get-blog-by-search';
    return service
        .post(url, body)
        .then((res: any) => res)
        .catch((err: any) => err)
}

export const getBlogById = async (body: any) => {
    let url = '/api/v1/get-blog-by-id';
    return service
        .post(url, body)
        .then((res: any) => res)
        .catch((err: any) => err)
}

export const blogComment = async (body: any) => {
    let url = '/api/v1/blog-comment';
    return service
        .post(url, body)
        .then((res: any) => res)
        .catch((err: any) => err)
}

export const getCommentsById = async (body: any) => {
    let url = '/api/v1/get-blog-comment-by-id';
    return service
        .post(url, body)
        .then((res: any) => res)
        .catch((err: any) => err)
}