import styled from "styled-components"


const Square = styled.div`
border: 2px solid red;
padding: 2px;
border-radius: 3px;
height: 15px;
width: 15px;
`

const Circle = styled.div`
border-radius: 10px;
    height: 7px;
    width: 7px;
    background-color: red;
`

export default function NonVeg() {
    return (
        <Square>
            <Circle />
        </Square>
    )
}