export const OrderItemSHimmer = () => {
    return <div>
        {Array.from({ length: 5 }).map((_, i) => (
            <div key={i.toString()} className="shine" style={{ height: 170, width: "100%", marginBottom: 20 }}></div>
        ))}
    </div>
}

export const ReviewShimmer: any = () => {
    return Array.from({ length: 2 }).map((_, index) => {
        return <div key={index.toString()} className='shine' style={{ height: "180px", width: "100%" }}></div>
    })
}