import * as React from "react";
import { SVGProps } from "react";

const BackArrow = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="9"
    height="15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M8.291.294a1 1 0 0 0-1.414 0L.234 6.936a.796.796 0 0 0 0 1.127l6.643 6.643a.999.999 0 0 0 1.414 0 .999.999 0 0 0 0-1.415L2.504 7.496 8.299 1.7A.996.996 0 0 0 8.291.294Z"
      fill="#494E9D"
    />
  </svg>
);

export default BackArrow;
