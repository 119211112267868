//@ts-nocheck
import React, { useEffect, useState } from 'react'
import { Container, Heading, Main, Column, Label, Row, Input, PasswordInput, PasswordDiv, Span } from './profile-comp'
import { Footer, Header, Icon, PrimaryButn, ProfileShimmer, Toast } from '../../components'
import { Hspace } from '../../styles'
import { changePassword, fetchProfile, resentEmail, verifyEmail } from '../../@services'
import { toast } from 'react-toastify'
import { Text } from '../blogs/blogs-comp'
import { userDetails } from '../../utils'
import { MyProfileMeta } from "../../utils";





const Profile = () => {
    const emailRegex = /^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/;
    const passwordRegex = /^(?!.*\s)(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[~`!@#$%^&*()--+={}\[\]|\\:;"'<>,.?/_₹]).{10,16}$/;
    const mobileRegex = /^[0-9]{10}$/;
    const [data, setData] = useState<any>();
    const [loading, setLoading] = useState<boolean>(false);
    const [passwordParams, setPasswordParams] = useState<any>({ currPass: "", newPass: "", retypePass: "" })
    const [show, setShow] = useState<any>({ name: "", show: false });
    const [loadPass, setLoadPass] = useState<boolean>(false);


    const getProfileData = async () => {
        setLoading(true)
        let fetchProfileData = await fetchProfile()
        if (fetchProfileData?.status == 200) {
            setData(fetchProfileData?.data)
            setLoading(false)
        } else {
            toast(fetchProfileData?.response?.data?.message)
            setLoading(false)
        }
    }
    useEffect(() => {
        getProfileData()
    }, [])


    const ChangePassword = async () => {
        // if (isChange()) {
        //     return
        // }
        setLoadPass(true)
        let params = {
            "user_id": userDetails()?.user_id,
            "current_password": passwordParams?.currPass,
            "new_password": passwordParams?.newPass,
            "retype_password": passwordParams?.retypePass
        }

        await changePassword(params).then(async (res: any) => {
            if (res && res.status == 200) {
                toast(res?.data?.message)
                setLoadPass(false)
                await new Promise((resolve) => setTimeout(resolve, 3000));
                logout()
            } else {
                toast(res?.response?.data?.message)
                setLoadPass(false)
            }
        })
    }

    const ResentEmail = async () => {
        setLoading(true)

        let params: any = {
            email_id: data?.data?.email,
            user_id: userDetails()?.user_id
        }

        await resentEmail(params).then(async (res: any) => {
            if (res && res.status == 200) {

                setLoading(false)
            } else {
                toast(res?.response?.data?.message)
                setLoading(false)
            }
        })
    }

    const isChange = () => {
        if (passwordRegex.test(passwordParams.newPass) &&
            passwordParams.newPass == passwordParams.retypePass) {
            return true
        } else return false
    }

    const handlePasswordChange = (e) => {
        const { name, value } = e.target;

        setPasswordParams((prevState: object) => ({
            ...prevState,
            [name]: value
        }))
    }
    const handleShowPassword = (name) => {
        setShow(prevState => ({
          name,
          show: name !== prevState.name ? true : !prevState.show
        }));
      };
      

    const logout = () => {
        localStorage.removeItem('hmd_access_token');
        localStorage.removeItem('hmd_user_details');
        window.location.replace((process.env.REACT_APP_HOST as any));

    }
    return (
        <Main>
            <MyProfileMeta />
            <Header />
            <Container>
                <Heading>Profile Details</Heading>
                <Hspace ht={30} />
                {loading ? <ProfileShimmer /> : <Column>
                    <Row className='j-start gap30'>
                        <Row className='gap10'>
                            <Label>Full Name</Label>
                            <Input value={data?.data?.name} disabled={true} />
                        </Row>
                        {/* <Row className='gap10'>
                            <Label>First Name</Label>
                            <Input value={"siddiq"} disabled={true} />
                        </Row> */}
                    </Row>
                    <Row className='j-start flex-start gap10'>
                        <Label>Email</Label>
                        <Row className='gap30'>
                            <Column>
                                <Input value={data?.data?.email} disabled={true} />
                                {data?.data?.is_email_verify === 0 && <Text className='sm'><Icon name="info" /> Please verify your email</Text>}
                            </Column>
                            {data?.data?.is_email_verify === 0 && <PrimaryButn name={loading ? "Loading" : 'Verify'} padding={3} wd={70} onClick={ResentEmail} />}
                        </Row>
                    </Row>
                    <Row className='j-start gap10'>
                        <Label>Mobile</Label>
                        <Row className='gap30'>
                            <Column>
                                <Input value={data?.data?.mobile} disabled={true} />
                                {data?.data?.is_mobile_verify === 0 && <Text className='sm'><Icon name="info" /> Please verify your mobile number</Text>}
                            </Column>
                            {data?.data?.is_mobile_verify === 0 && <PrimaryButn name='Verify' padding={3} wd={70} onClick={() => { }} />}
                        </Row>
                    </Row>
                </Column>}

                <Hspace ht={40} />
                <Heading>Modify Password</Heading>
                <Hspace ht={30} />
                {loadPass ? <ProfileShimmer /> : <Column>
                    <Row className='j-start gap10'>
                        <Label>Current Password</Label>
                        <PasswordDiv>
                            <Input name='currPass' value={passwordParams?.currPass} onChange={handlePasswordChange} />
                        </PasswordDiv>
                    </Row>
                    <Row className='j-start flex-start gap10'>
                        <Label>New Password</Label>
                        <Column>
                            <PasswordDiv>
                                <PasswordInput showPassword={show.name == 'newPass' && show.show} name='newPass' value={passwordParams?.newPass} onChange={handlePasswordChange} />
                                <Span onClick={() => handleShowPassword("newPass")}>
                                    <Icon name={(show.name == 'newPass' && show.show) ? "eye" : 'eyeStrike'} />
                                </Span>
                            </PasswordDiv>
                            {passwordParams.newPass && !passwordRegex.test(passwordParams.newPass) ?
                                <Text className='sm'><Icon name="info" /> Password must contain a digit from 1 to 9, a lowercase, a uppercase, a special character, must be 8-16 characters long.</Text> : ""}
                        </Column>
                    </Row>
                    <Row className='j-start gap10'>
                        <Label>Confirm Password</Label>
                        <Column>
                            <PasswordDiv>
                                <PasswordInput showPassword={show.name == 'retypePass' && show.show} name='retypePass' value={passwordParams?.retypePass} onChange={handlePasswordChange} />
                                <Span onClick={() => handleShowPassword("retypePass")}>
                                    <Icon name={(show.name == 'retypePass' && show.show )? "eye" : 'eyeStrike'} />
                                </Span>
                            </PasswordDiv>
                            {(passwordParams.newPass && passwordParams.retypePass.length) && passwordParams.newPass !== passwordParams.retypePass ?
                                <Text className='sm'><Icon name="info" /> Password should match.</Text> : ""}
                        </Column>
                    </Row>
                    <PrimaryButn isDisabled={!isChange()} name={loadPass ? "Loading..." : "Change"} onClick={ChangePassword} />
                </Column>}
            </Container>
            <Toast />
            <Footer />
        </Main>
    )
}

export default Profile
