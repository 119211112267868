import { brown, darkBorder, redish } from "../../styles";
import styled from "styled-components";

export const CheckoutCOntainer = styled.div`
  // transform: translate(-50%, 3%);
  position: relative;
  background: #ffffff;
  border-radius: 5px;
  box-shadow: 0px 4px 8px 2px #111;
  padding: 8px;
  max-width: 500px;
  width: 100%;
  margin: 0px 20px;

  @media only screen and (max-width: 767px) {
    max-width: 400px;
  }
`;

export const InnerContainer = styled.div`
  border: 3px solid rgb(181 33 33);
  padding: 8px;
  width: 100%;
`;

export const Inner = styled.div`
  border: 3px solid rgb(181 33 33);
  padding: 20px 30px;

  @media only screen and (max-width: 767px) {
    padding: 10px 6px;
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 2px 10px;

  &.flex-1 {
    flex: 1;
  }

  &.flex-2 {
    flex: 2;
  }

  &.gap10 {
    gap: 10px;
  }

  &.gap30 {
    gap: 30px;
  }
`;

export const Input = styled.input`
  width: 100%;
  border-radius: 5px 0px 0px 5px;
  box-shadow: none;
  border: 1px solid rgb(220, 220, 220);
  padding: 6px;
  outline: none;
  // background-color: rgb(250, 250, 250);
  font-weight: 500;

  &:focus {
    border: 1px solid ${darkBorder};
  }

  &.noWidth {
    width: unset;
  }
`;

export const Div = styled.div``;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  //   margin: 10px 20px 0px 20px;

  &.center {
    align-items: center;
  }

  &.noJustify {
    justify-content: unset;
    gap: 20px;
  }
`;

export const ApplyButton = styled.div`
  background-color: #ff0000;
  color: #fff;
  padding: 6px 20px 10px;
  //   box-shadow: 2px 3px 4px -2px #000;
  font-weight: 600;

  &:hover {
    cursor: pointer;
  }
`;

export const Close = styled.div`
  font-weight: bold;
  font-size: 20px;
  position: absolute;
  background-color: black;
  padding: 4px 8px;
  border-radius: 35px;
  color: white;
  top: -5px;
  right: -5px;
  border: 2px solid ${brown};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &:hover {
    color: ${redish};
  }
`;

export const OR = styled.div`
  margin: 10px 0px;
  padding: 7px;
  font-size: 13px;
  border: 2px solid rgb(238, 238, 238);
  border-radius: 36px;
`;

export const Text = styled.div`
  font-size: 16px;
  color: #364d59;
  line-height: 20px;
  letter-spacing: 1px;
  color: ${brown};


  &.small {
    font-size: 12px;
  }

`;

export const CouponLabel = styled.label`
  border: 1px dotted ${brown};
  padding: 6px 20px;
  letter-spacing: 1px;
  font-size: 14px;
  //   flex: 1;

  &:disabled {
    cursor: not-allowed;
  }
`;

//coupon
export const CouponRow = styled.div`
display: flex;
    gap: 20px;
    /* background: aliceblue; */
    background-color: #f5f5dc73;
    padding: 10px;
    border-radius: 8px;
`

export const CouponHolder = styled.div`
display: flex;
flex-direction: column;
gap: 20px;
max-height: 300px;
overflow: auto;
scroll-behavior: smooth;
`
export const CouponCOntainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 20px;
`;

export const CouponCode = styled.label`
  line-height: 20px;
  color: #950101;
  font-size: 14px;
  letter-spacing: 1px;
`;

export const CouponMessage = styled.div`
color: #950101;
font-size: 11px;
letter-spacing: 1px;
opacity: 0.8;
opacity: 0.8;
}
`
