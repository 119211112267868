import styled from 'styled-components'
import { Hspace } from '../../styles'
export const BannerContainer = styled.div`
margin: 20px 25px 25px 25px;
overflow: hidden;


@media screen and (max-width: 667px) {
    max-height: 150px;
}
`

export const TabContainer = styled.div`
margin: 0px 25px;
max-height: 50px;
overflow: hidden;

@media screen and (max-width: 667px) {
    max-height: 70px;
}
`

const CardCOntainer = styled.div`
overflow: hidden;
padding: 25px;
display: grid;
grid-template-columns: repeat(3, 1fr);
grid-column-gap: 20px;
grid-row-gap: 20px;
// width: 100%;

@media screen and (max-width: 991px) {
    grid-template-columns: repeat(2, 1fr);
    // width: 100%;
}

@media screen and (max-width: 558px) {
    grid-template-columns: repeat(1, 1fr);
}

`

const Column = styled.div`
display: flex;
flex-direction: column;
gap: 20px;
overflow: hidden;
`

const Row = styled.div`
display: flex !important;
flex-direction: row !important;
flex: 1;
// gap: 20px;

@media screen and (max-width: 760px) {
    flex-direction: column !important;
}
`

const CardsSpace = styled.div`
flex: 3;
`

const CartPart = styled.div`
flex: 1;
padding: 25px 25px 0 0px;


@media screen and (max-width: 760px) {
    display: none;
}

`


const BannerShimmer = () => {
    return (
        <BannerContainer>
            <div className='shine' style={{ height: "25vw", width: "100%" }}></div>
        </BannerContainer>
    )
}



const TabShimmer = () => {
    return (
        <TabContainer>
            <div className='shine' style={{ height: "30vw", width: "100%" }}></div>
        </TabContainer>
    )
}

export const CardsShimmer = () => {
    const numberOfThumbnails = 6;
    return (
        <CardsSpace>
            <CardCOntainer>
                {Array.from({ length: numberOfThumbnails }).map((_, index) => {
                    return (
                        <div key={index.toString()} className='shine' style={{ height: "250px", width: "100%" }}></div>
                    )
                })}
            </CardCOntainer>
        </CardsSpace>
    )
}

export const CartShimmer = () => {
    return (
        <CartPart>
            <div className='shine' style={{ height: "250px", width: "25vw" }}></div>
            <Hspace ht={20} />
            <div className='shine' style={{ height: "250px", width: "25vw" }}></div>
        </CartPart>
    )
}


export const HomescreenSHimmer = () => {
    return (
        <Column>
            <BannerShimmer />
            <TabShimmer />
            <Row>
                <CardsShimmer />
                <CartShimmer />
            </Row>
        </Column>
    )
}
