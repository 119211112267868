//@ts-nocheck
import React, { useState, useEffect, useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { TabItem, Container, IconDiv } from "./slick-tabs-components";
import Icon from '../icon'

type SlideProps = {
    activeTab: string;
    onClicked: (e: any) => void;
    tabs?: any;
};
const SlickTab: React.FC = ({ tabs, activeTab, onClick }: SlideProps) => {
    const [slideIndex, setSlideIndex] = useState(0);
    const [updateCount, setUpdateCount] = useState(0);
    const sliderRef = useRef(null);

    const Prev = ({ className, style, onClick, disabled }) => {
        return (
            <IconDiv active={slideIndex == 0} className={className} onClick={onClick}>
                <Icon name="backArrow" />
            </IconDiv>
        )
    }

    const Next = ({ className, style, onClick }) => {
        return (
            <IconDiv active={tabs.length-1 == slideIndex} className={`${className} right`} onClick={onClick}>
                <Icon name="backArrow" />
            </IconDiv>
        )
    }

    const settings = {
        slidesToShow: 4,
        slidesToScroll: 1,
        infinite: false,
        prevArrow: <Prev />,
        nextArrow: <Next />,
        afterChange: () => setUpdateCount(updateCount + 1),
        beforeChange: (current, next) => setSlideIndex(next),
        responsive: [
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            },
        ],
    };

    return (
        <Container className="tab">
            <Slider {...settings} ref={sliderRef}>
                {tabs?.map((item: string, i: number) => {
                    return <TabItem key={i.toString()} active={item.name == activeTab.name} onClick={() => onClick(item)}>
                        {item.name}
                    </TabItem>
                })}
            </Slider>
        </Container>
    );
};

export default SlickTab

