import { Footer, Header, Icon, OrderItem, OrderItemSHimmer, Paginate, Toast } from "../../components"
import { Castle, CastleText, Container, Main, PaginationDiv, SeeMore } from "./orders-components"
import Line from '../../assets/line.png'
import { Hline, Hspace, Vline } from "../../styles"
import { useEffect, useState } from "react"
import { getOrders } from "../../@services"
import { userDetails } from "../../utils"
import { OrderMeta } from "../../utils";



const Orders = () => {
    const foodStarProps = {
        size: 20,
        count: 5,
        color: "black",
        activeColor: "red",
        value: 0,
        a11y: true,
        isHalf: true,
        emptyIcon: <Icon name="emptyStar" />,
        halfIcon: <Icon name="halfStar" />,
        filledIcon: <Icon name="filledStar" />,
        onChange: (newValue) => {
            console.log(`Example 2: new value is ${newValue}`);
        }
    };

    const deliveryStarProps = {
        size: 20,
        count: 5,
        color: "black",
        activeColor: "red",
        value: 0,
        a11y: true,
        isHalf: true,
        emptyIcon: <Icon name="emptyStar" />,
        halfIcon: <Icon name="halfStar" />,
        filledIcon: <Icon name="filledStar" />,
        onChange: (newValue) => {
            console.log(`Example 2: new value is ${newValue}`);
        }
    };
    const [expand, setExpand] = useState<any>(false)
    const [expandId, setExpandId] = useState<any>(0);
    const [orders, setOrders] = useState<any>();
    const [loading, setLoading] = useState<boolean>(false);
    const [page, setPage] = useState<number>(1)
    const [empty, setEmpty] = useState<boolean>(false)

    const getAllOrders = async () => {
        setLoading(true)
        let id: any = {
            user_id: userDetails()?.user_id,
            page: page,
        }
        await getOrders(id).then((res: any) => {
            if (res && res.status === 200) {
                setOrders(res?.data?.data)
                setLoading(false)
            } else if (res && res.response.status === 404) {
                console.log(res?.response)
                // setOrders(res?.data?.data)
                setEmpty(true)
                setLoading(false)
            } else {
                setLoading(false)
            }
        })
    }

    const arrowClick = (item: any) => {
        if (expandId == item.id) {
            setExpandId(null);
        } else {
            setExpandId(item.id);
        }
    }

    const handlePageChange = async (e: any, pageNo: number) => {
        setLoading(true)
        setPage(pageNo)
        let id = {
            user_id: userDetails()?.user_id,
            page: pageNo,
        }
        await getOrders(id).then((res: any) => {
            if (res && res.status) {
                setOrders(res?.data?.data)
                setLoading(false)
            } else {
                console.log(res.response);
                setLoading(false)
            }
        })
    }

    useEffect(() => {
        getAllOrders()
    }, [])
    return (
        <Main>
            <OrderMeta />
            <Header />
            <Container>
                <Castle>
                    <img width='200px' src={Line} alt="" />
                    <CastleText>Your Orders</CastleText>
                    <img width='200px' src={Line} alt="" />
                </Castle>
                <Hspace ht={20} />
                {(!loading && orders?.order?.data?.length === 0) || empty && <Container className="emptyC">Orders Not Available.</Container>}
                {!loading ? orders?.order?.data?.map((item: any, index: number) =>
                    <OrderItem key={index.toString()} exapnded={expandId == item.id} setExpand={setExpand} foodReview={foodStarProps} deliveryReview={deliveryStarProps} data={item} arrowClick={() => arrowClick(item)} />
                ) : <OrderItemSHimmer />}
                {/* <OrderItem exapnded={expand} setExpand={setExpand} foodReview={foodStarProps} deliveryReview={deliveryStarProps} />
                <OrderItem exapnded={expand} setExpand={setExpand} foodReview={foodStarProps} deliveryReview={deliveryStarProps} />
                <OrderItem exapnded={expand} setExpand={setExpand} foodReview={foodStarProps} deliveryReview={deliveryStarProps} />
                <OrderItem exapnded={expand} setExpand={setExpand} foodReview={foodStarProps} deliveryReview={deliveryStarProps} /> */}
                {/* {!loading && <SeeMore>See more order</SeeMore>} */}
                {!loading && orders?.order?.data?.length > 0 && <PaginationDiv>
                    <Paginate totalPage={Math.ceil(orders?.total / 10)}
                        page={page} setPage={setPage} onPageChange={handlePageChange} />
                </PaginationDiv>}
            </Container>
            <Toast />
            <Footer />
        </Main>
    )
}

export default Orders