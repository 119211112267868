import { Footer, Header, Icon, Toast } from "../../components"
import { Button, Card, CardContainer, Castle, Error, CastleText, Container, Div, EmptyDiv, Iframe, Img, Input, Left, Main, Right, Row, Text, TextArea } from "./contact-us-components"
import Globe from '../../assets/svgs/globe.svg'
import Phone from '../../assets/svgs/phone.svg';
import Location from '../../assets/svgs/location.svg';
import Line from '../../assets/line.png'
import { Hspace } from "../../styles";
import { ContactCompany } from '../../@services'
import { toast } from "react-toastify";
import { useState } from "react";
import { ContactMeta } from "../../utils";


const ContactUs = () => {
    const mapUrl = `https://www.google.com/maps/embed?pb=!1m10!1m8!1m3!1d3736489.7218514383!2d90.21589792292741!3d23.857125486636733!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sbd!4v1506502314230`
    const emailRegex = /^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/;
    const mobileRegex = /^[0-9]{10}$/;
    const [loading, setLoading] = useState<boolean>(false);
    const [data, setData] = useState<any>({ name: '', email: "", phone: "", message: "" });

    const Conatct = async () => {
        if (loading) {
            return;
        }
        setLoading(true)
        let params: any = {
            "name": data?.name,
            "email_id": data?.email,
            "mobile_no": data?.phone,
            "message": data?.message
        }

        await ContactCompany(params).then((res: any) => {
            if (res && res?.status) {
                setLoading(false)
                toast(res?.data?.message);
                setData({ name: '', email: "", phone: "", message: "" });
            } else {
                setLoading(false)
                toast(res?.response?.data?.message);
            }
        })
    }

    const handleChange = (e) => {
        const { name, value, id } = e.target;
        setData((prevState: any) => ({
            ...prevState,
            [name]: value,
        }));

    };

    return (
        <Main>
            <ContactMeta />
            <Header />
            <Container>
                <Castle>
                    <img width='200px' src={Line} alt="" />
                    <CastleText>CONTACT US</CastleText>
                    <img width='200px' src={Line} alt="" />
                </Castle>
                <Hspace ht={20} />
                <Div>
                    <Row>
                        <Left>
                            <Iframe src={mapUrl} width="100%" height="315" frameBorder="0" style={{}} allowFullScreen />
                        </Left>
                        <Right>
                            <Input placeholder="Name" name="name" value={data?.name} onChange={handleChange} />
                            <EmptyDiv>
                                <Input placeholder="E-mail" name="email" value={data?.email} onChange={handleChange} />
                                {data.email.includes('@') && !emailRegex.test(data.email) ?
                                    <Error className="red">Email must be valid.</Error> : ""}
                            </EmptyDiv>
                            <EmptyDiv>
                                <Input placeholder="Phone" maxLength={10} name="phone" value={data?.phone} onChange={handleChange} />
                                {data.phone && !mobileRegex.test(data.phone) ?
                                    <Error className="red">Mobile number must be valid.</Error> : ""}
                            </EmptyDiv>
                            <TextArea placeholder="Message" name="message" value={data?.message} onChange={handleChange} />
                            <Button onClick={Conatct}>{loading ? "Loading..." : <><Icon name="plane" /> <span>Submit</span></>}</Button>
                        </Right>
                    </Row>
                </Div>
                <CardContainer>
                    <Card>
                        <Img src={Phone} alt="" />
                        <Text>CALL US</Text>
                        <EmptyDiv>+91 {process.env.REACT_APP_PHONE}</EmptyDiv>
                    </Card>

                    <Card>
                        <Img src={Location} alt="" />
                        <Text>OFFICE LOCATION</Text>
                        <EmptyDiv style={{ textAlign: "center" }}>{process.env.REACT_APP_ADDRESS}, {process.env.REACT_APP_AREA_PIN}</EmptyDiv>
                    </Card>

                    <Card>
                        <Img src={Globe} alt="" />
                        <Text>EMAIL</Text>
                        <a style={{ textDecoration: "none" }} href="mailto:hello@hmdindia.in"><EmptyDiv> {process.env.REACT_APP_EMAIL}</EmptyDiv></a>
                    </Card>
                </CardContainer>
            </Container>
            <Toast />
            <Footer />
        </Main>
    )
}

export default ContactUs