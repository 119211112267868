import { darkBorder, darkFont, green, grey, greyBackground, whiteColor } from "../../styles";
import styled from "styled-components";

export const Main = styled.div`
  overflow: auto;
  max-height: calc(100vh - 0px);
  transition: all 0.3s ease;
`;

export const Container = styled.div`
position: relative;
padding-bottom: 70px;
margin: 40px 100px;
min-height: calc(100vh - 268px);
height: 100%;

 &.emptyC {
    // height: 80vh;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
  }
  
@media only screen and (max-width: 458px) {
    margin: 20px 20px;
}
`

export const CastleText = styled.div`
font-size: 25px;
font-weight: 500;
`

export const Castle = styled.div`
display: flex;
flex-direction: column;
align-items: center;
`
export const SeeMore = styled.div`
text-align: center;
font-size: 15px;
font-weight: bold;
color: ${green};
cursor: pointer;
`

export const PaginationDiv = styled.div`
position: absolute;
bottom: 15px;
left: auto;
display: flex;
align-items: center;
justify-content: center;
width: 100%;
`


