import React, { useState } from 'react';
import Icon from '../icon';
import { Column, Container, ItemWrapper, MediaContainer, NarrowFooter, Row, Text } from './footer-components';
import { useNavigate } from '../../utils';

const Footer = () => {
    const { navigate } = useNavigate();

    return (
        <Container>
            <Column>
                <MediaContainer>
                    <Row className='flex-1'>
                        <a style={{ textDecoration: "none", color: "white" }} href="https://www.facebook.com/hmdpureorganic" target="_blank">
                            <ItemWrapper><Icon name="facebookFilled" /> facebook</ItemWrapper>
                        </a>
                        <a style={{ textDecoration: "none", color: "white" }} href="https://www.instagram.com/hmd___india/" target="_blank">
                            <ItemWrapper><Icon name="instagram" />Instagram</ItemWrapper>
                        </a>
                        {/* <ItemWrapper><Icon name="twitter" />X</ItemWrapper>
                        <ItemWrapper><Icon name="youtube" />Youtube</ItemWrapper> */}
                    </Row>

                    <Row className='flex-1 j-space grid'>
                        <div onClick={() => navigate('/about')}>About Us</div>
                        <div onClick={() => navigate('/blogs')}>Blogs</div>
                        <div onClick={() => navigate('/contact')}>Contact Us</div>
                        <div onClick={() => navigate('/terms')}>Terms of Use</div>
                        <div onClick={() => navigate('/policy')}>Policy</div>
                    </Row>
                </MediaContainer>
                <NarrowFooter className='narrow-footer'>
                    <Text> © {new Date().getFullYear()} HMD INDIA | All rights reserved</Text>
                    {/* <Text> © 2020 owned by HAMIDA PICKLE C/O - SHABNAM KHATOON | All rights reserved</Text> */}
                </NarrowFooter>
            </Column>
        </Container >
    );
}

export default Footer;
