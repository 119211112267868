import { brown, darkFont, lightFont, whiteColor } from "../../styles";
import styled from "styled-components";

export const IconDiv = styled.div`
  border-radius: 40px;
  width: 29px;
//   background-color: ${whiteColor};
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 50;

  &.right {
    // margin: 0px 10px 0px 0px;
    svg {
      transform: rotate(180deg);
    }
  }

  &:disabled {
    background: unset;
    font-style: normal;
    opacity: 1;
    color: black;
    border-color: rgb(207, 207, 207);
    cursor: default;
  }

  &:focus {
    background: unset;
  }

  &:hover {
    background-color: ${whiteColor};
  }

  &::before {
    content: "\f104";
    font-size: 19px;
    opacity: 1;
    color: #222;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0%);
  }
`;

export const Row = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 20px;
`;

export const Text = styled.div`
  font-weight: 500;
  font-size: 15px;
  color: ${brown};
  transition: all 0.3s ease;

  &.sm {
    font-size: 10px;
  }

  &.med {
    font-size: 18px;
    color: green;
  }

  &.grey {
    color: ${lightFont};
  }

  &.cl-blk {
    color: ${darkFont};
  }

  &.slug {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 43px;
  }

  &.search {
    min-width: 100px;
  }

  &.pad {
    padding: 15px 25px;
  }

  &.i {
    font-style: italic;
  }
`;

export const Textarea = styled.textarea`
  min-width: 400px;
  width: 100%;
  height: calc(1.5em * 10);
  resize: none;
  border: none;
  outline: none;
  font-size: 10px;
  font-family: inherit;
  line-height: 1.5em;
  padding: 0;
  margin: 0;
  text-overflow: ellipsis;
  pointer-events: none;

  @media only screen and (min-width: 911px) and (max-width: 1200px) {
    min-width: 500px
  }

  @media only screen and (min-width: 767px) and (max-width: 911px) {
    min-width: 400px
  }

  @media only screen and (min-width: 480px)  and (max-width: 767px) {
    min-width: 177px
  }

  @media only screen and (max-width: 480px) {
    min-width: 100px
    height: 50px;
  }
`;
