// @ts-nocheck
import { Header, Icon, SlickTab, Overlay, Modal, Footer, Cart, HomescreenSHimmer, CardsShimmer, Toast, PrimaryButn, Notification } from "../../components";
import { BannerDiv, Body, BottomC0ntainer, CardContainer, Category, EmptyDivText, IconDiv, Image, ImageDiv, Input, InputDiv, ItemConteiner, LeftContainer, Main, Payment, Proceed, Row, StickyDivContainer, Text, ViewCartButton, Column, OutOfBox, ProductName, BestSeller } from "./homescreen-components";
import { EmptyDiiv, Hline, Hspace } from "../../styles";
import EmptyCard from '../../assets/empty-card.jpg';
import ProductCard from "../../components/product-card";
import { useEffect, useState, useRef } from "react";
import { getFoodCategories, HomescreenMeta, isLoggedIn, notification, useNavigate } from "../../utils";
import SliderComponent from "../../components/banner-slick/banner-slick";
import { getDashboard, getItemsByCategory, search } from "../../@services";
import { useHistory } from "react-router";
import { toast } from 'react-toastify'
import { useCartContext } from "../../context";
import '../checkout/checkbox.css';


const Homescreen = () => {
    const { navigate } = useNavigate()
    const history = useHistory();
    const tab = getFoodCategories()
    const defaultTab = { id: 1, name: "Pickle" };
    const [loading, setLoading] = useState<boolean>(false);
    const [loadByItem, setLoadByItem] = useState<boolean>(false)
    const [activeTab, setActiveTab] = useState<any>({});
    const [tabs, setTabs] = useState<any>(tab);
    const [data, setData] = useState<any>([]);
    const [inputVal, setInputVal] = useState<string>('')
    const [searched, setSearched] = useState<boolean>(false)
    const { cartItems, setCartItems, totalAmount } = useCartContext()
    const [descption, setDescription] = useState<any>();
    const [selectedProd, setselctedProd] = useState<any>(null);
    const [selectSku, setSelectedSku] = useState<any>(null);
    const [openNotify, setopenNotify] = useState<boolean>(false);
    const [notifyProd, setnotifyProd] = useState<any>();
    const [notifyLoad, setnotifyLoad] = useState<boolean>(false)

    // useEffect(() => {
    //     getDashboardItems();
    // }, [])

    // useEffect(() => {
    //     setTabs(getFoodCategories());
    // }, [])

    const fetchCategory = async () => {
        setLoadByItem(true);
        let preItems = data;

        let param = {
            category_id: activeTab.id
        }
        await getItemsByCategory(param)
            .then((res: any) => {
                if (res && res.status == 200) {
                    setData(res?.data?.data?.data)
                    setLoadByItem(false)
                } else {
                    toast(`No item available for ${activeTab.name} category`);
                    setActiveTab({})
                    setData(preItems)
                    setLoadByItem(false)
                }
            })
            .catch((err: any) => {
                console.log(err)
                setLoadByItem(false)
            })
    }

    const getDashboardItems = async () => {
        setLoading(true)
        await getDashboard()
            .then((res: any) => {
                if (res && res.data.status && res.data.status_code == 200) {
                    sessionStorage.setItem("food-categories", JSON.stringify(res?.data?.data?.category));
                    sessionStorage.setItem("banners", JSON.stringify(res?.data?.data?.banners))
                    const combineArray = [].concat(...res?.data?.data?.collections)
                    setData(combineArray)
                    setLoading(false)
                    setDescription(res?.data?.data?.metadata)
                }
            })
            .catch((err: any) => {
                console.log("no item for this category");
                setLoading(false)
            })
    }

    const onCardClick = (item) => {
        history.push({
            pathname: `/product/${item.slug}`, state: item
        })
    }

    const onSearch = async () => {
        setLoadByItem(true)
        let prevData = data
        let params = {
            "keyword": inputVal
        }
        await search(params).then((res: any) => {
            if (res && res?.status) {
                setData(res?.data?.data?.data)
                setLoadByItem(false)
                setSearched(true)
            } else {
                setInputVal('')
                setSearched(false)
                toast(res?.response?.data?.message);
                setData(prevData);
                setLoadByItem(false);
            }
        })
    }

    const onSearchClicked = () => {
        if (!searched) {
            onSearch();
        } else {
            setSearched(false);
            setInputVal('')
            getDashboardItems();
        }
    }

    // const onAddtoCart = (e, newProd: any) => {
    //     e.stopPropagation();
    //     const isItemInCart = cartItems.some(item => item.id === newProd.id);

    //     if (!isItemInCart) {
    //         setCartItems((prev) => ([newProd, ...prev]));
    //     } else {
    //         toast('Item already exits on cart')
    //     }
    // }

    const openModal = (e: MouseEvent, prod: any) => {
        e.stopPropagation();
        const isItemInCart = cartItems.some(item => item.id === prod.id);
        if (isItemInCart) {
            toast('Item already exits on cart')
            return;
        }
        setselctedProd(prod)
    }

    //new method
    const onAddtoCart = (sku: any, parentProd: any) => {
        const isItemInCart = cartItems.some(item => item.id === sku.id);
        if (!isItemInCart) {
            const updatedSku = { ...sku, name: parentProd?.name, type: parentProd?.type, id: parentProd?.id };
            setCartItems((prev) => ([updatedSku, ...prev]));
            setselctedProd(null)
            setSelectedSku(null)
        } else {
            toast('Item already exits on cart')
        }
    }

    const isItemInCart = (prodId: number) => {
        return cartItems.some(item => item.id === prodId);
    }

    const closeModal = () => {
        setselctedProd(null);
        setSelectedSku(null)
    }
    const handleSku = (elem: any) => {
        setSelectedSku(elem)
    }

    const Noftify = async (e: MouseEvent, data: any) => {
        e.stopPropagation();
        if (!isLoggedIn()) {
            setopenNotify(true)
            setnotifyProd(data)
        } else {
            if (notifyLoad) return;
            setnotifyProd(data)
            setnotifyLoad(true)
            let res = await notification(data?.id);
            if (res.status === 200) {
                toast(res?.data?.message);
                setnotifyLoad(false)
            } else {
                toast(res?.data?.message)
                setnotifyLoad(false)
            }

        }
    }

    useEffect(() => {
        if (Object.keys(activeTab).length > 0) {
            fetchCategory();
        } else if (!data) {
            getDashboardItems();
        }
    }, [activeTab])

    useEffect(() => {
        getDashboardItems();
    }, [])
    return (
        <>
            <Main>
                <Header />

                <HomescreenMeta descption={descption} />
                {loading ?
                    <HomescreenSHimmer /> :
                    <>
                        <BannerDiv>
                            <SliderComponent />
                        </BannerDiv>
                        <StickyDivContainer>
                            <Row className="sticky-head">
                                <h1>Collection</h1>
                                <InputDiv>
                                    <Input value={inputVal} onChange={(e) => setInputVal(e.target.value)} placeholder="Search" />
                                    <IconDiv onClick={onSearchClicked}><Icon name={searched ? "cross" : "search"} /></IconDiv>
                                </InputDiv>
                            </Row>
                            <Hspace ht={8} />
                            <Hline />
                        </StickyDivContainer>
                        <BottomC0ntainer>
                            <SlickTab tabs={tab} activeTab={activeTab} onClick={(e: any) => setActiveTab(e)} />
                            <Category>{Object.keys(activeTab).length > 0 ? activeTab.name : "All Items"}</Category>
                            <Body>
                                {loadByItem ? <CardsShimmer /> :
                                    <CardContainer className="flex-3">
                                        {data.map((item: any, index: number) => (
                                            <ProductCard
                                                key={index.toString()}
                                                name={item?.name}
                                                image={item?.image}
                                                discount={item?.discount}
                                                quantity={item?.quantity}
                                                pType={item?.type}
                                                isItemInCart={isItemInCart(item?.id)}
                                                onCardClick={() => onCardClick(item)}
                                                onAddtoCart={(e: any) => openModal(e, item)}
                                                item={item}
                                                reviews={item?.over_all_reviews}
                                                notifyLoader={notifyLoad && notifyProd?.id === item?.id}
                                                notify={(e: any) => Noftify(e, item)}
                                            />
                                        ))}
                                    </CardContainer>
                                }
                                <EmptyDiiv>
                                    <LeftContainer>
                                        {!cartItems.length &&
                                            <>
                                                <EmptyDivText>Empty Cart</EmptyDivText>
                                                <ImageDiv className="img-height">
                                                    <Image src={cartItems?.length ? cartItems[0]?.image : EmptyCard} />
                                                </ImageDiv>
                                                <Hspace ht={10} />
                                            </>
                                        }
                                        {/* cart */}
                                        {cartItems?.length ? <Cart isCheckout onProceed={() => navigate('/checkout')} /> : null}
                                    </LeftContainer>
                                </EmptyDiiv>

                                {cartItems?.length ?
                                    <ViewCartButton>
                                        <Row>{cartItems?.length} Items @ {totalAmount}</Row>
                                        <Row className="gap10" onClick={() => navigate('/checkout')}>View Cart<Icon name="backArrow" /></Row>
                                    </ViewCartButton> : null}
                                <Toast />
                            </Body>
                        </BottomC0ntainer>
                        <Hspace ht={20} />
                    </>
                }
                {selectedProd ?
                    <Overlay>
                        <Modal title="Select item net weight" onClose={closeModal}>
                            <Row className="a-fs">
                                <ProductName>{selectedProd?.name}</ProductName>
                                <Icon name={selectedProd?.type == 'Veg' ? "veg" : "nonVeg"} />
                            </Row>
                            <Hspace />
                            <Column>
                                {selectedProd?.product_sku?.map((elm: any, i: number) => (
                                    <Row>
                                        <Row className="gap10">
                                            <input onClick={() => handleSku(elm)} name={elm?.sku_name} type="checkbox" value={elm?.sku_name} id={elm?.sku_name} checked={elm?.sku_id === selectSku?.sku_id} disabled={elm?.quantity === 0} onChange={() => { }} />
                                            <div style={{fontSize: 12}}>{elm?.sku_name} {elm?.units?.name}</div>
                                            <OutOfBox>{elm?.quantity === 0 ? "Out of stock" : elm?.is_best_seller === 1 ? <BestSeller><Icon name="tick" />Best Selling Item</BestSeller> : null}</OutOfBox>
                                        </Row>
                                        <div style={{fontSize: 12}}>{selectedProd?.discount.discount_value > 0 ? <s>₹{elm?.mrp}</s> : null} ₹{elm?.amount}</div>
                                    </Row>
                                ))}
                                <Hline />
                                <PrimaryButn wd="100%" name="Add to cart" padding="10" fSize="16" isDisabled={selectSku === null} onClick={() => onAddtoCart(selectSku, selectedProd)} />
                            </Column>
                        </Modal>
                    </Overlay> : null}
                {openNotify ? <Notification data={notifyProd} open={openNotify} setOpen={setopenNotify} /> : null}
                <Footer />
            </Main >
        </>
    );
}

export default Homescreen