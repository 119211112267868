import {
  brown,
  darkBorder,
  darkFont,
  green,
  grey,
  redish,
  whiteColor,
} from "../../styles";
import styled from "styled-components";

export const CartConatiner = styled.div`
  border-radius: 8px;
  overflow: hidden;
  padding: 30px 20px;
  background-color: ${whiteColor};
  border-radius: 4px;
  color: #364d59;

  ${({ bdr }: { bdr?: boolean }) => {
    if (bdr) {
      return `border: 1px solid ${grey};`;
    }
  }};
`;

export const Payment = styled.div`
  display: flex;
  flex-direction: revert;
  justify-content: space-between;
  // margin: 5px 1px 10px 20px;
  font-weight: bold;
`;

export const ItemConteiner = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  &.flex-1 {
    flex: 1;
  }

  &.gap5 {
    gap: 5px;
  }

  &.gap10 {
    gap: 10px;
  }

  &.gap30 {
    gap: 30px;
  }

  &.gap20 {
    gap: 20px;
  }

  &.noJustify {
    justify-content: unset;
  }

  &.resend {
    justify-content: unset;
    gap: 15px;
  }

  &.sku {
    font-size: 9px;
    justify-content: flex-end; 
    color: #00ad00;
    // font-size: 10px;
    font-weight: 500;
    font-style: italic;

  }

  @media only screen and (max-width: 767px) {
    &.sticky-head {
      flex-direction: column;
      align-items: flex-start;

      input {
        width: 100%;
      }
    }
  }
`;

export const InfoText = styled.div`
  font-size: 14px;
  color: ${darkFont};
  letter-spacing: 1px;

  &.sm {
    font-size: 12px;
    color: ${green};
    letter-spacing: unset;
    font-weight: 500;
  }
`;

export const EmptyDiv = styled.div``;

export const Sign = styled.div`
  padding: 4px 8px;
  background-color: ${redish};
  cursor: pointer;
  &:hover {
    background-color: brown;
  }

  &.tick {
    background-color: ${green};
    cursor: not-allowed
    &:hover {
      background-color: darkgreen;
    }

    ${({ disable }: { disable?: boolean }) => {
    if (disable) {
      return `pointer-events: none;
        opacity: 0.6`;
    } else {
      return `cursor: pointer;`;
    }
  }}
  }

  svg {
    height: 10px;
    width: 10px;
    stroke-width: 5px;

    path {
      fill: ${whiteColor};
      stroke-width: 5px;
    }
  }
`;

export const AnnaInfo = styled.div`
  font-size: 12px;
  /* line-height: 30px; */
  margin-top: 5px;
  letter-spacing: 1px;
  color: rgb(68 8 8);
  font-weight: bold;
`;

export const AnnaBox = styled.div`
  display: flex;
  text-align: center;
  gap: 8px;
  position: relative;
`;
export const Clear = styled.div`
  position: absolute;
  top: 0;
  right: 0px;
  cursor: pointer;
  color: brown;
  text-decoration: underline;
`;

export const AnnaInput = styled.div`
  max-height: 28px;
  display: flex;
  max-width: 93px;
  /* text-align: center; */
  flex-direction: revert;
  gap: 5px;
  align-items: center;
  font-size: 15px;
  letter-spacing: 0.8px;
  color: black;
`;

export const AnnaUsed = styled.div`
  align-self: end;
  display: flex;
  align-items: flex-end;
  font-size: 12px;
  color: ${green};
  letter-spacing: 0.5px;

  &.m-tp {
    margin-top: -20px;
    font-style: italic;
  }

  &.info {
    font-style: normal;
    align-items: flex-start;
    align-self: start;
    margin: 7px 0 7px 0;
    letter-spacing: 0px;
  }
`;

export const Note = styled.div`
  line-height: 25px;
  letter-spacing: 0.5px;
  color: #09006b;
`;

export const CartItemText = styled.div`
  font-size: 15px;
  max-width: 115px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
`;
export const IconDiv = styled.div`
  cursor: pointer;
`;

export const Count = styled.div`
  height: 20px;
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Price = styled.div`
  width: 50px;
  text-align: end;
`;

//bottom
export const BottomCart = styled.div`
  border-radius: 8px;
  overflow: hidden;
  padding: 10px 20px 30px 20px;
  background-color: ${whiteColor};
  border-radius: 4px;
  color: #364d59;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;
export const Ashrafiya = styled.div`
  border: 1px dotted black;
  padding: 13px 10px;
  text-align: center;
  flex: 1;
  // width: 100%;
  font-size: 12px;
  font-weight: 600;

  letter-spacing: 0.02rem;

  &:disabled {
    cursor: not-allowed;
  }

  &.hand {
    cursor: pointer;
  }

  ${({ active }: { active?: boolean }) => {
    if (active) {
      return `opacity: 0.5;
      pointer-events: none`;
    }
  }}
`;

export const Text = styled.div`
  font-weight: 500;
  font-size: 15px;
  color: #37b34a;
  transition: all 0.3s ease;

  &.coupon {
    font-size: 16px;
    color: ${redish};
    font-weight: 500;
  }

  &.remove {
    color: ${brown};
    cursor: pointer;

    &:hover {
      transition: all 0.3s ease;
      color: ${green};
      border-bottom: 1px solid ${brown};
    }
  }

  &.f-sz {
    font-size: 14px;
    letter-spacing: unset;
  }
`;

//checkbox
export const Checkbox = styled.div`
  &.round {
    position: relative;
  }

  &.round label {
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 50%;
    cursor: pointer;
    height: 15px;
    left: 0;
    position: absolute;
    top: 0;
    width: 15px;
  }

  &.round label:after {
    border: 2px solid #fff;
    border-top: none;
    border-right: none;
    content: "";
    height: 2px;
    left: 3px;
    opacity: 0;
    position: absolute;
    top: 4px;
    transform: rotate(-45deg);
    width: 6px;
  }

  &.round input[type="checkbox"] {
    visibility: hidden;
  }

  &.round input[type="checkbox"]:checked + label {
    background-color: #66bb6a;
    border-color: #66bb6a;
  }

  &.round input[type="checkbox"]:checked + label:after {
    opacity: 1;
  }
`;

export const ChkInput = styled.input``;

export const Label = styled.label``;

export const Input = styled.input`
width: 100%;
    border-radius: 5px;
    box-shadow: none;
    border: 1px solid rgb(220, 220, 220);
    padding: 6px 2px;
    font-size 13px;
    outline: none;
    text-align: center;

    // background-color: rgb(250, 250, 250);

    &:focus {
        border: 1px solid ${darkBorder};
    }
`;
