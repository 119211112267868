import { darkBorder, grey, lightGrey, mediumBorde, redish, whiteColor } from "../../styles";
import styled from "styled-components";

export const Main = styled.div`
  overflow: auto;
  max-height: calc(100vh - 0px);
`;

export const Container = styled.div`
  margin: 40px 100px;
  height: 100%;
  @media only screen and (max-width: 458px) {
    margin: 20px 20px;
  }
`;

export const CastleText = styled.div`
font-size: 25px;
font-weight: 500;
`

export const Castle = styled.div`
display: flex;
flex-direction: column;
align-items: center;
`

export const Div = styled.div`
//   padding: 20px;
`;

export const Row = styled.div`
  // position: relative;
  display: flex;
  flex-direction: row;
  // flex: 1;
  // width: 100%;
  gap: 20px;
  margin-bottom: 20px;

  @media only screen and (max-width: 767px) {
    flex-direction: column;
  }
`;

export const Left = styled.div`
  flex: 60%;
`;

export const Right = styled.div`
  flex: 40%;
  // width: 50%;
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 20px;

  &.space-betn {
    justify-content: space-between;
  }
`;

export const Input = styled.input`
  background-color: ${whiteColor};
  outline-width: thin;
  // border: transparent;
  border: 1px solid ${grey};
  padding: 12px 8px;
  border-radius: 4px;
  color: rgb(102 92 92);
  font-size: 16px;
  width: 100%;

  &:active,
  &:hover {
    border: 1px solid ${lightGrey};
    // outline: none;
  }
  &:focus {
    outline: none;
  }
`;

export const TextArea = styled(Input).attrs({ as: "textarea" })`
  resize: vertical;
`;

export const Button = styled.div`
  background-color: #ff0000;
  color: #fff;
  padding: 6px 20px 10px;
  border-radius: 5px;
  box-shadow: 2px 3px 4px -2px #000;
  font-weight: 600;
  width: 140px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  &:hover {
    cursor: pointer;
  }
`

export const Iframe = styled.iframe``

export const Card = styled.div`
display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    padding: 20px;
    border: 1px solid ${mediumBorde};
    border-radius: 5px;
    background-color: ${whiteColor};
`

export const CardContainer = styled.div`
display: grid;
grid-template-columns: repeat(3, 1fr);
grid-column-gap: 20px;
grid-row-gap: 20px;

@media only screen and (max-width: 991px) {
    grid-template-columns: repeat(2, 1fr);  
}

@media only screen and (max-width: 767px) {
    grid-template-columns: repeat(1, 1fr);  
}
`

export const Text = styled.h4``

export const Img = styled.img`
height: 100px;
`

export const EmptyDiv = styled.div``

export const Error = styled.div`
  color: ${redish};
  font-family: Nunito Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  // overflow: scroll;
  max-width: 600px;
  overflow-wrap: break-word;
`;
