import { service } from "../service";

export const getAnnas = async (params: any) => {
    let url = `/api/v1/get-total-annas`;

    return service
        .post(url, params)
        .then((res: any) => res)
        .catch((err: any) => err)
}

export const annaHistory = async (params: any) => {
    let url = `/api/v1/get-annas-history`

    return service
        .post(url, params)
        .then((res: any) => res)
        .catch((err: any) => err)
}