import { FormCOntainer, InnerContainer, Inner, Column, Label, Input, Close, Center, Div, AddressLocation, Location, ButtonContainer, EditTextContainer } from './modal-components'
import EditBottom from '../../assets/svgs/addressBottom.svg';
import EditTop from '../../assets/svgs/addressTop.svg';
import EditLine from '../../assets/svgs/editLine.svg';
import Primary from '../Buttons/primary';
import { darkBorder } from '../../styles';
import { useState } from 'react';
import { capitalize } from '../../utils';
import Icon from '../icon';

type Props = {
    title?: string;
    onClose?: any;
    children?: any;

}
const Modal = ({ title, onClose, children }: Props) => {


    return (
        <FormCOntainer>
            <Close onClick={onClose}><Icon name="cross" /> </Close>
            <InnerContainer>
                <Inner>
                    <Column>
                        <Center className='gap15'>
                            <img width='150px' src={EditTop} alt="" />
                            <EditTextContainer>
                                {title && <div>{title}</div>}
                                <img src={EditLine} alt='edit svg' />
                            </EditTextContainer>
                        </Center>
                        {children}
                        <Center>
                            <img width='150px' src={EditBottom} alt="svg" />
                        </Center>
                    </Column>
                </Inner>
            </InnerContainer>
        </FormCOntainer>
    )
}

export default Modal