import React, { useEffect, useState } from 'react'
import { Card, CardHolder, Comment, Container, Heading, PaginationDiv, Row, Text } from './anna-history-components'
import { Icon, Paginate } from '../../components';
import { annaAndDate, orderTimeAndDate, userDetails } from '../../utils';
import { Hline } from '../../styles';
import { capitalize } from '@mui/material';
import { annaHistory } from '../../@services';
import { AnnaHistoryShimmer } from '../../components/shimmers/anna-shimmmer';

const AnnaHistory = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [history, setHistory] = useState<any>([]);
    const [page, setPage] = useState<number>(1)



    const gethistory = async () => {
        setLoading(true)
        let param: any = {
            "user_id": userDetails()?.user_id,
            page: page
        }
        await annaHistory(param).then((res: any) => {
            if (res && res?.status) {
                // console.log(res?.data?.data)
                setHistory(res?.data?.data);
                setLoading(false)
            } else {
                console.log(res?.response?.data)
                setLoading(false)
            }
        })
    }

    const handlePageChange = async (e: any, pageNo: number) => {
        setLoading(true)
        setPage(pageNo);
        let params: any = {
            "user_id": userDetails()?.user_id,
            page: pageNo
        }
        await annaHistory(params).then((res: any) => {
            if (res && res?.status) {
                // console.log(res?.data?.data)
                setHistory(res?.data?.data);
                setLoading(false)
            } else {
                console.log(res?.response?.data)
                setLoading(false)
            }
        })
    }

    useEffect(() => {
        gethistory();
    }, [])

    if (!loading && (!history?.history?.data|| history?.history?.data?.length <= 0)) {
        return <Card className='center'> Anna History Not Available.</Card>
    } else {
        return (
            <Container>
                <Heading>Annas Histories</Heading>
                {!loading ? <>
                    <CardHolder>
                        {history?.history?.data?.map((item: any, index: number) => (
                            <Card key={index.toString()}>
                                <Row className='j-sb'>
                                    <Text>Total Anna: {item?.total_annas}</Text>
                                    <Text>Rs: {(parseFloat(item?.total_annas) / 16).toFixed(2)}</Text>
                                </Row>
                                {/* <Comment>{item?.comment}</Comment> */}
                                <Hline />
                                <Row className='j-sb'>
                                    <Text className='sm grey'>Mode: {capitalize(item?.mode)}</Text>
                                    {(item?.expires_at != null && item?.expires_at != "") &&
                                        <Text className='sm grey'>Expires at: {annaAndDate(item?.expires_at)}</Text>}
                                </Row>
                            </Card>

                        ))}
                    </CardHolder>
                    <PaginationDiv>
                        <Paginate totalPage={Math.ceil(history?.total / 10)} page={page} onPageChange={handlePageChange} />
                    </PaginationDiv>
                </> : <AnnaHistoryShimmer />}
            </Container>
        )
    }
}

export default AnnaHistory