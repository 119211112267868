import { themeBackground, whiteColor } from '../../styles';
import styled from 'styled-components';

export const ScrollMenuContainer = styled.div`
  background: ${themeBackground};
  overflow: auto;
  white-space: nowrap;
  border-radius: 8px;
  position: relative;
  padding: 0px 60px;
  margin-bottom: 10px;
  transition: all 0.5s ease;
`;

// Define a styled component for the individual menu items
export const TabItem = styled.div`
  display: inline-block;
  color: white;
  text-align: center;
  padding: 14px;
  text-decoration: none;
  width: 25%;
  &:hover {
    // background-color: #777;
    cursor: pointer;
  }
  ${({ active }: { active: boolean }) => {
        if (active) {
            return `color: ${whiteColor};`;
        } else {
            return `color: #fffbfbb8;`;
        }
    }}
`;

export const IconDiv = styled.div`
border-radius: 40px;
width: 29px;
background-color: ${whiteColor};
position: absolute;
top: 12px;
left: 20px;
height: 29px;
width: 32;
/* text-align: center; */
display: flex;
align-items: center;
justify-content: center;
margin: 0px 20px 0px 0px;

&.right {
    right: 20px;
    left: unset;
    margin: 0px 0px 0px 20px;
    svg {
        transform: rotate(180deg);
    }
}
`

export const Hider = styled.div`
padding: 10px;
    background-color: #403c3c;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 8px 0px 0px 8px;
    bottom: 10px;

    &.right {
        right: 0;
        left: unset;
        border-radius: 0px 8px 8px 0px;
    }
`
