import styled from "styled-components";
import {
  brown,
  redish,
  green,
  lightFont,
  grey,
  lighBlacktFont,
  lightGrey,
  whiteColor,
  darkFont,
  darkBorder,
} from "../../styles";

export const Main = styled.div`
  overflow: auto;
  max-height: calc(100dvh - 0px);
  transition: all 0.3s ease;
  // @media only screen and (max-width: 457px) {
  //   max-height: calc(100vh - 100px);
  // }
`;

export const Container = styled.div`
  margin: 10px 40px;
  position: relative;
  padding-bottom: 70px;

  &.empty {
    height: 80vh;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
  }
  @media (max-width: 458px) {
    margin: 10px 10px;
  }
`;

export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;

  @media (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: 1024px) {
    grid-template-columns: repeat(3, 1fr);
  }
`;

export const StyledCard = styled.div`
  flex-basis: 33.33333%;
  padding: 15px;
  cursor: pointer;
  flex: 1;
`;

export const InnerCard = styled.div`
  background-color: #fff;
  // padding: 15px 25px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
`;

export const ImgWrapper = styled.div`
  width: 100%;
  height: 250px;
  margin-bottom: 10px;
  padding: 15px 25px;
`;

export const Img = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
`;

export const Content = styled.div`
  margin-bottom: 10px;
`;

export const Title = styled.h1`
  font-weight: 900;
  font-size: 16px;
  margin-bottom: 10px;
  color: #444;
  padding: 0px 25px;

  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 38px;
`;

export const Paragraph = styled.p`
  font-size: 14px !important;
  line-height: 1.5;
  color: #555;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 10px;
  padding: 0px 25px;
`;

export const Text = styled.div`
  font-weight: 500;
  font-size: 15px;
  color: ${brown};
  transition: all 0.3s ease;

  &.sm {
    font-size: 10px;
  }

  &.med {
    font-size: 18px;
    color: green;
  }

  &.grey {
    color: ${lightFont};
  }

  &.cl-blk {
    color: ${darkFont};
  }

  &.slug {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 43px;
  }

  &.search {
    min-width: 100px;
  }

  &.pad {
    padding: 15px 25px;
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  // flex: 1;
  gap: 20px;

  &.center {
    align-items: center;
  }

  &.noGap {
    gap: 0;
  }

  &.gap10 {
    gap: 10px;
  }

  &.gap2 {
    gap: 2px;
  }
  &.j-sb {
    justify-content: space-between;
  }

  &.m-t {
    // margin-top: 10px;
    // padding: 15px 25px;
  }

  svg {
    path {
      fill: ${green} !important;
    }
  }

  @media only screen and (max-width: 458px) {
    &.search {
      flex-direction: column;
      flex: 1;
      align-items: flex-start;
      gap: 10px;

      input {
        width: 100%;
      }
    }
  }
`;

export const IconDiv = styled.div`
  background-color: ${lighBlacktFont};
  padding: 8.5px 9px;
  display: flex;
  align-items: center;
  border-radius: 0 4px 4px 0;
  cursor: pointer;
`;
export const Input = styled.input`
  background: ${whiteColor} !important;
  outline-width: thin;
  // border: transparent;
  border: 1px solid ${grey};
  padding: 7px 8px;
  border-radius: 4px 0 0px 4px;
  color: rgb(102 92 92);
  font-size: 16px;

  &:active,
  &:hover {
    border: 1px solid ${lightGrey};
    // outline: none;
  }
  &:focus {
    outline: none;
  }
`;

export const InputDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  @media only screen and (max-width: 767px) {
    width: 100%;
  }
`;

export const StickyDivContainer = styled.div`
  position: sticky;
  top: -1px;
  background-color: #f0f0f0;
  z-index: 51;
  padding: 15px 25px 15px 25px;
`;

export const End = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;
export const PaginationDiv = styled.div`
  position: absolute;
  bottom: 15px;
  left: 0;
  right: 0;
  // margin-left: auto;
  // margin-right: auto;
  // // width: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Textarea = styled.textarea`
width: 90%;
    border-radius: 5px;
    box-shadow: none;
    border: 1px solid rgb(220, 220, 220);
    padding: 8px 6px;
    font-size 15px;
    outline: none;
    // background-color: rgb(250, 250, 250);
    rows: 5;
resize: none;
margin: 5px 25px;
    &:focus {
        border: 1px solid ${darkBorder};
    }
`;

export const FlexEnd = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  // margin-top: 10px;
  // gap: 50px;
  padding: 15px 25px;

  &.space-btn {
    // justify-content: space-between;
    gap: 10%;
  }
`;
