import { Switch, Route } from "react-router-dom";
import { About, Address, Ashrafiya, Checkout, Orders, ContactUs, Homescreen, Policy, Return, Terms, Blogs, BlogDescription, Profile } from "../modules";
import ProductDescription from "../modules/product-descption/product-descption";
import { Success } from "../components";

const Routes = () => {
  return (
    <Switch>
      <Route exact path='/' component={Homescreen} />
      <Route exact path='/about' component={About} />
      <Route exact path='/checkout' component={Checkout} />
      <Route exact path='/address' component={Address} />
      <Route exact path='/orders' component={Orders} />
      <Route exact path='/product/:slug' component={ProductDescription} />
      <Route exact path='/annas' component={Ashrafiya} />
      <Route exact path='/contact' component={ContactUs} />
      <Route exact path='/terms' component={Terms} />
      <Route exact path='/policy' component={Policy} />
      <Route exact path='/return' component={Return} />
      <Route exact path='/blogs' component={Blogs} />
      <Route exact path='/blog/:id/:slug' component={BlogDescription} />
      <Route exact path='/profile' component={Profile} />
      <Route exact path='/profile/success' component={Success} />
    </Switch>
  );
}

export default Routes