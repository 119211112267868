import { Routes } from "../../routes";
import { useEffect, useState } from "react";
import { Redirect, Route, useHistory } from "react-router-dom";
import { Container, Div, Input, Label, PageContainer, PageContent } from "./layout-container-components";
import { PrimaryButn, Toast } from "../../components";
import { service } from "../../@services/service";
import ErrorHandler from "../../components/error";
//auth
import Overlay from "../../components/overlay";
import DeletePopup from "../../components/address-delete/address-delete";
import { EmptyDiiv } from "../../styles";
import { toast } from "react-toastify";

const AUTH = ({ params, setParams, setEntry }: any) => {
    const mb = '7002357790'
    const mpin = 'HMDIND'

    const handleChange = (e) => {
        const { name, value, id } = e.target;
        setParams((prevState: any) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const onSubmit = () => {
        if (params?.mobile == mb && params?.mpin == mpin) {
            setEntry(true)
        } else {
            toast('Please enter correct details')
        }
    }

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            onSubmit();
        }
    };

    return (
        <Overlay>
            <DeletePopup onClose={() => { }} onSubmit={() => { }} onCancel={() => { }}>
                <Div>
                    <EmptyDiiv>
                        <Label>Mobile</Label>
                        <Input name='mobile' value={params?.mobile} placeholder="Ex: 7002###7790" autoFocus onChange={handleChange}
                            onKeyDown={handleKeyDown} />
                    </EmptyDiiv>
                    <EmptyDiiv>
                        <Label>Password</Label>
                        <Input name='mpin' type='password' value={params.mpin} placeholder="EX: 123456" onChange={handleChange}
                            onKeyDown={handleKeyDown} />
                    </EmptyDiiv>
                    <PrimaryButn onClick={() => onSubmit()} name="Verify" padding={7} />
                </Div>
                <Toast />
            </DeletePopup>
        </Overlay>
    )
}
const LayoutContainer = () => {
    const session = 'dkesdbfhesdvdsgdwesgdgwesgd'
    const history = useHistory();
    const [params, setParams] = useState<any>({ mobile: "", mpin: "" })
    const [entry, setEntry] = useState<boolean>(false)

    return (
        <Container>
            <PageContainer>
                <PageContent>
                    {/* {
                        entry ?
                            <>
                                <Route
                                    exact
                                    path="/"
                                    render={() => <Redirect to="/" />}
                                />
                                <Routes />
                            </> :
                            <AUTH params={params}
                                setParams={setParams}
                                setEntry={setEntry}
                            />
                    } */}
                    <Route
                        exact
                        path="/"
                        render={() => <Redirect to="/" />}
                    />
                    <Routes />
                </PageContent>
            </PageContainer>
        </Container>
    );
}

export default LayoutContainer;