import styled from "styled-components";

export const themeBackground = 'linear-gradient(to right, #403c3c, #4d9219, #403c3c)';
export const whiteColor = '#fff';
export const greenTheme = '#4d8e1b';
export const lightGrey = '#666666';
export const grey = '#e6e6e6';
export const redish = '#ff0000';
export const brown = `#8b0314`;
export const green = `#37b34a`;
export const darkBorder = `#434343`;
export const mediumBorde = `rgb(220, 220, 220)`;
export const greyBackground = `#eee`;


//font
export const lightFont = `#364d59`;
export const lighBlacktFont = `#5e5e5e`;
export const darkFont = '#0a0a0a';