import { green } from "../../../styles";
import styled from "styled-components";

const Square = styled.div`
  border: 2px solid ${green};
  padding: 2px;
  border-radius: 3px;
  height: 15px;
  width: 15px;
`;

const Circle = styled.div`
  border-radius: 10px;
  height: 7px;
  width: 7px;
  background-color: ${green};
`;

export default function Veg() {
  return (
    <Square>
      <Circle />
    </Square>
  );
}
