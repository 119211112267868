import { brown, darkFont, green, lightFont, whiteColor } from "../../styles";
import styled from "styled-components";

export const Main = styled.div`
  overflow: auto;
  max-height: calc(100vh - 0px);
  letter-spacing: 1px;
`;

export const Container = styled.div`
  margin: 40px 80px;
  min-height: calc(100vh - 225px);
  height: 100%;
  @media only screen and (max-width: 458px) {
    margin: 20px 20px;
  }
`;

export const ProductDiv = styled.div`
  padding: 20px;
  background-color: ${whiteColor};
`;

export const ProductDetail = styled.div`
  // position: relative;
  display: flex;
  flex-direction: row;
  // flex: 1;
  // width: 100%;
  gap: 40px;
  margin-bottom: 20px;

  @media only screen and (max-width: 767px) {
    flex-direction: column;
  }
`;

export const OutOfStock = styled.div`
  position: absolute;
  color: red;
  font-size: 22px;
  background: #ffffffc7;
  top: 47%;
  right: 0;
  left: 0;
  text-align: center;
  color: #000;
  font-weight: 600;
  background-color: rgb(204 204 204 / 0.8);
  padding: 12px;
`;

export const CartCount = styled.div`
  position: absolute;
  color: red;
  font-size: 22px;
  background: #ffffffc7;
  bottom: 0;
  right: 0;
  left: 0;
  text-align: center;
  color: #000;
  background-color: rgb(204 204 204 / 0.8);
  padding: 12px;
`;

export const Offer = styled.div`
  position: absolute;
  z-index: 0;
  background-color: #4caf50;
  color: #fff;
  left: -20px;
  padding: 6px 8px;
  transform: rotate(-47deg);
  top: 15px;
  border-radius: 5px;
`;

export const PriceBox = styled.div`
  display: flex;
  // flex: 1;
  gap: 20px;
  align-items: center;

  @media screen and (max-width: 559px) {
    flex-direction: column;
    align-items: flex-end;
  }
`;

export const Image = styled.div`
  position: relative;
  flex: 1;
  // aspect-ratio: 4/3;
  max-width: 50%;
  height: 100%;

  img {
    width: 100%;
    position: relative;
    aspect-ratio: 4/3;
    object-fit: cover;
  }

  @media only screen and (max-width: 767px) {
    max-width: 100%;
  }
`;

export const Description = styled.div`
  flex: 1;
  // width: 50%;
  display: flex;
  flex-direction: column;

  &.space-betn {
    justify-content: space-between;
  }
`;

export const TopTitle = styled.div`
  font-size: 0.875rem;
  text-transform: uppercase;
  letter-spacing: 0.3125rem;
  font-weight: 400;
  margin: 0;
  color: #323232;
`;

export const SubTitle = styled.div`
  font-size: 2.1875rem;
  color: #323232;
`;

export const SmallDescription = styled.div`
  font-weight: 500;
  font-size: 20px;
  margin: 0 0 20px 0;
  color: green;
  line-height: 20px;
`;

export const EmptyDiv = styled.div``;

//cart
export const Price = styled.div`
  color: #9c9a9a;
  flex: 20%;
  display: flex;
  gap: 10px;
`;

export const CartControls = styled.div`
  display: flex;
  align-items: center;
  flex: 70%;
  justify-content: flex-end;
  gap: 8px;
`;

export const CartIcon = styled.span`
  cursor: pointer;
`;

export const Count = styled.div`
  height: 20px;
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

//bottom
export const FLex1 = styled.div`
  flex: 1;
`;

export const SpecilityBox = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 0 10px 0;
  gap: 10px;
`;

export const SpHead = styled.div`
  font-weight: 300;
  color: #000;
  font-size: 20px;
`;

export const SpList = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 22px;
`;

export const FlexEnd = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 10px;
  // gap: 50px;

  &.space-btn {
    // justify-content: space-between;
    gap: 10%;
  }
`;

export const ColumnEnd = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 5px;
`;

export const New = styled.div`
  display: inline-block;
  padding: 0.3125rem 0.625rem;
  color: #fff;
  font-size: 0.625rem;
  line-height: 1;
  font-weight: 500;
  background: -webkit-gradient(
    linear,
    right top,
    left top,
    from(#64c08a),
    color-stop(#00a550),
    to(#00914c)
  );
  background: -webkit-linear-gradient(right, #64c08a, #00a550, #00914c);
  background: -o-linear-gradient(right, #64c08a, #00a550, #00914c);
  background: linear-gradient(270deg, #64c08a, #00a550, #00914c);
  background-size: 600% 600%;
  -webkit-animation: slideGradient 4s ease infinite;
  animation: slideGradient 4s ease infinite;
`;

export const IconBack = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 7px 13px;
  border-radius: 4px;
  cursor: pointer;

  &.facebook {
    background-color: #3a5696;
  }

  &.twitter {
    background-color: #51a3de;
  }

  &.clip {
    background-color: #847e7e;
  }

  svg {
    height: 1em;
    path {
      fill: ${whiteColor};
    }
  }
`;

export const Text = styled.div`
  font-weight: 500;
  font-size: 15px;
  color: ${brown};
  transition: all 0.3s ease;

  &.sm {
    font-size: 10px;
  }

  &.med {
    font-size: 18px;
    color: green;
  }

  &.grey {
    color: ${lightFont};
  }

  &.cl-blk {
    color: ${darkFont};
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  // flex: 1;
  gap: 20px;

  &.center {
    align-items: center;
  }

  &.noGap {
    gap: 0;
  }

  &.gap10 {
    gap: 10px;
  }

  &.gap2 {
    gap: 2px;
  }
  &.j-sb {
    justify-content: space-between;
  }

  &.m-t {
    margin-top: 10px;
  }
`;

