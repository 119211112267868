import styled from "styled-components";
import { grey, lightGrey, redish, whiteColor } from "../../styles";



export const Main = styled.div`
  overflow: auto;
  max-height: calc(100vh - 0px);
  transition: all 0.3s ease;
`;

export const Container = styled.div`
position: relative;
padding-bottom: 70px;
margin: 40px 100px;
min-height: calc(100vh - 268px);
height: 100%;
background-color: ${whiteColor};
padding: 20px;
@media only screen and (max-width: 776px) {
    margin: 20px 20px;
}
`

export const Heading = styled.div`
  color: #55616d;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 1px;
  align-self: flex-start;
  background-color: #ececec;
  width: 100%;
  padding: 10px;
  border-radius: 8px;
`;

export const Input = styled.input`
  background: ${whiteColor} !important;
  outline-width: thin;
  border: 1px solid ${grey};
  padding: 7px 8px;
  border-radius: 4px 0 0px 4px;
  color: rgb(102 92 92);
  font-size: 16px;
  min-width: 300px;

  &:active,
  &:hover {
    // border: 1px solid ${lightGrey};
    // outline: none;
  }
  &:focus {
    outline: none;
  }
`;

interface PasswordInputProps {
  showPassword: boolean;
}

export const PasswordInput = styled(Input).attrs(
  (props: PasswordInputProps) => ({
    type: props.showPassword ? "text" : "password",
  })
)`
  // width: 100%;
`;

export const PasswordDiv = styled.div`
  position: relative;
  max-width: 300px;
`;

export const Span = styled.span`
  position: absolute;
  right: 9px;
  top: 10px;
  cursor: pointer;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  &.flex-1 {
    flex: 1;
  }

  &.flex-2 {
    flex: 2;
  }

  &.gap10 {
    gap: 10px;
  }

  &.gap30 {
    gap: 30px;
  }

  &.noGap {
    gap: 0px;
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  &.flex-1 {
    flex: 1;
  }

  &.flex-2 {
    flex: 2;
  }

  &.gap10 {
    gap: 10px;
  }

  &.gap30 {
    gap: 30px;
  }

  &.resend {
    justify-content: unset;
    gap: 15px;
  }

  &.flex-start {
    align-items: flex-start;
  }

  &.j-start {
    justify-content: flex-start;
    width: 100%;
    flex: 1;
  }

  &.svgs {
    svg {
      height: 30px;
      width: 30px;
      background-color: ${grey};
      padding: 7px;
      border-radius: 20px;
      path {
        stroke: ${redish};
        fill: red;
        opacity: 0.5;
      }
    }
  }

  @media only screen and (max-width: 458px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const Label = styled.label`
  min-width: 150px;
  &::after {
    content: ":";
    // padding-left: 30px;
  }
`;

