import { FormCOntainer, Option, Row, InnerContainer, Inner, Column, Label, Input, Close, Center, Div, AddressLocation, Location, ButtonContainer, EditTextContainer, Select, TextArea } from './return-form-components'
import EditBottom from '../../assets/svgs/addressBottom.svg';
import EditTop from '../../assets/svgs/addressTop.svg';
import EditLine from '../../assets/svgs/editLine.svg';
import Primary from '../Buttons/primary';
import { darkBorder } from '../../styles';
import { useState } from 'react';
import { capitalize, isLoggedIn, userDetails } from '../../utils';
import { returnProd } from '../../@services';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import Icon from '../icon';

type Props = {
    orderNo?: string;
    setOrderNo?: any
    onClose: () => void;
    onSubmit: () => void;
    onDelete: () => void;

}
const RetuenForm = ({ onClose, onSubmit, onDelete, orderNo, setOrderNo }: Props) => {
    const { pathname } = useLocation();
    const loginStatus = isLoggedIn();
    // const isReturnPage = pathname.includes('/return');
    const addressType = ['home', 'work', 'others']
    const [loading, setLoading] = useState<boolean>(false);
    const [returnParams, setReturnParams] = useState<any>({
        name: loginStatus ? userDetails()?.name : "",
        email: loginStatus ? userDetails()?.email : "",
        mobile: "",
        order_no: orderNo ? orderNo : "",
        intact: "",
        reason: "",
        other: "",
        description: "",

    })

    const areFieldsNotEmpty = () => {
        const requiredFields = ['name', 'email', 'mobile', 'order_no', 'intact', 'reason', 'description'];

        for (const field of requiredFields) {
            if (!returnParams[field]) {
                return false;
            }
        }
        return true; 
    };

    const isActive = areFieldsNotEmpty();

    const handleChange = (e) => {
        const { name, value, id } = e.target;
        setReturnParams((prevState: any) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleProductIntactChange = (e) => {
        const { id, value } = e.target;
        setReturnParams((prevState: any) => ({
            ...prevState,
            [id]: value,
        }));
    };


    const Return = async () => {
        if (loading) {
            return
        }
        setLoading(true)
        let params: any = {
            name: returnParams?.name,
            email_id: returnParams?.email,
            mobile_no: returnParams?.mobile,
            order_number: returnParams?.order_no,
            packaging_intact: returnParams?.intact,
            problem_facing: returnParams?.reason,
            other_message: returnParams?.reason == "Other" ? returnParams?.other : "",
            description: returnParams?.description,
        }
        await returnProd(params).then((res: any) => {
            if (res && res.status) {
                console.log(res?.data?.message);
                setReturnParams({
                    name: loginStatus ? userDetails()?.name : "",
                    email: loginStatus ? userDetails()?.email : "",
                    mobile: "",
                    order_no: orderNo ? orderNo : "",
                    intact: "",
                    reason: "",
                    other: "",
                    description: "",

                })
                setLoading(false);
                onClose();
                toast(res?.data?.message)
            } else {
                toast(res?.response?.data?.message)
                setLoading(false)
            }
        })
    }

    return (
        <FormCOntainer>
            <Close onClick={onClose}><Icon name="cross" /> </Close>
            <InnerContainer>
                <Inner>
                    <Column>
                        <Center className='gap15'>
                            <img width='150px' src={EditTop} alt="" />
                            <EditTextContainer>
                                <div>Return Product</div>
                                <img src={EditLine} alt='edit svg' />
                            </EditTextContainer>
                        </Center>
                        <Div>
                            <Label>name*</Label>
                            <Input name='mobile' value={returnParams?.name} placeholder='Eg: 9877###7778' onChange={handleChange} />
                        </Div>
                        <Div>
                            <Label>Mobile Number*</Label>
                            <Input name='mobile' value={returnParams?.mobile} placeholder='Eg: 9877###7778' onChange={handleChange} />
                        </Div>
                        <Div>
                            <Label>Email id*</Label>
                            <Input name='email' value={returnParams?.email} placeholder='Eg: you@youdomain.com' onChange={handleChange} />
                        </Div>
                        <Div>
                            <Label>Order No*</Label>
                            <Input name='order_no' value={returnParams?.order_no} placeholder='Eg: HMDX####tuVE' onChange={handleChange} />
                        </Div>
                        <Div>
                            <Label htmlFor='intact'>Do you have origin product intact?*</Label>
                            <Row className='j-start'>
                                <Row className='g10'>
                                    <input id="intact" name='product_intact' value="yes" type="radio" onChange={handleProductIntactChange} />
                                    <Label>Yes</Label>
                                </Row>
                                <Row className='g10'>
                                    <input id="intact" name='product_intact' value="no" type="radio" onChange={handleProductIntactChange} />
                                    <Label>No</Label>
                                </Row>
                            </Row>
                        </Div>
                        <Div>
                            <Label>What is the problem you are facing?*</Label>
                            <Select name='reason' value={returnParams?.reason} onChange={handleChange}>
                                <Option>Select a Reson</Option>
                                <Option>Received damage product</Option>
                                <Option>Facing quality issue</Option>
                                <Option>Other</Option>
                            </Select>
                        </Div>
                        {returnParams?.reason == "Other" &&
                            <TextArea name='other' value={returnParams?.other} placeholder='What is the reason?' onChange={handleChange} />}
                        <Div>
                            <Label>Description*</Label>
                            <TextArea name='description' value={returnParams?.description} placeholder='Explain in brief.' onChange={handleChange} />
                        </Div>
                        <ButtonContainer>
                            <Primary backColor={`${darkBorder}`} isDisabled={!isActive} padding={5} fSize={17} name='' onClick={Return}>{!loading ? "Submit" : "Loading.."}</Primary>
                            <Primary name='' padding={5} fSize={17} onClick={onClose}>Cancel</Primary>
                        </ButtonContainer>
                        <Center>
                            <img width='150px' src={EditBottom} alt="svg" />
                        </Center>
                    </Column>
                </Inner>
            </InnerContainer>
        </FormCOntainer>
    )
}

export default RetuenForm