import styled from "styled-components";
import { grey, lightGrey } from "./color";

export const EmptyDiiv = styled.div``

type Props = {
    ht?: number;
    wd?: number;
    start?: boolean;
}

export const Hspace = styled.div`
${({ ht }: Props) => {
        if (ht) {
            return `height: ${ht}px`
        } else {
            return `height: 5px`
        }
    }}
`

export const Vspace = styled.div`
${({ wd }: Props) => {
        if (wd) {
            return `width: ${wd}px`
        } else {
            return `width: 5px`
        }
    }}
`

export const Hline = styled.div`
  height: 1px;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.125);
`;

export const Vline = styled.div`
${({ ht }: { ht: number | string }) => {
        if (ht) {
            return `height: ${ht};`
        } else {
            return `height: 100%;`;
        }
    }}; 
  width: 2px;
  background-color: rgb(238, 238, 238);
`;
