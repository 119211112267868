import { DescriptionShimmer, Footer, Header, Icon, Toast } from "../../components"
import { Container, Row, Image, Description, TopTitle, SubTitle, ProductDiv, SmallDescription, Price, CartControls, ProductDetail, Main, OutOfStock, Offer, EmptyDiv, FLex1, SpecilityBox, SpHead, SpList, FlexEnd, ColumnEnd, New, CartCount, IconBack, CartIcon, Text} from "./blog-descption-components"
import Img from '../../assets/mini.webp';
import PrimaryBtn from "../../components/Buttons/primary/primary-button";
import { Hline, Hspace } from "../../styles";
import { useHistory, useLocation, useParams } from "react-router";
import { useEffect, useState } from "react";
import { getBlogById, getProductDetails } from "../../@services";
import { Helmet } from 'react-helmet'
import parse from 'html-react-parser'
import { capitalize } from "../../utils";
import BlogsComments from "../blogs-comments";



const BlogDetails = () => {
    const { id }: any = useParams();
    const history = useHistory()
    const [loading, setLoading] = useState<boolean>(false);
    const [data, setData] = useState<any>({});

    const getBlogDetails = async () => {
        setLoading(true)
        let p_id: any = {
            blog_id: parseInt(id)
        }
        await getBlogById(p_id)
            .then((res) => {
                if (res && res.status == 200) {
                    setData(res.data.data)
                    setLoading(false)
                } else {
                    console.log()
                    setLoading(false)
                }
            })
    }

    useEffect(() => {
        getBlogDetails()
    }, [])
    return (
        <Main>
            <Helmet>
                <title>{data?.meta_title}</title>
                <meta name="og:title" content={data?.meta_title} />
                <meta name="og:description" content={data?.meta_description} />
                <meta name="og:keyword" content={data?.meta_keyword} />
                <meta name="og:image" content={data?.image} />

                <meta name="title" content={data?.meta_title} />
                <meta name="description" content={data?.meta_description} />
                <meta name="keyword" content={data?.meta_keyword} />

            </Helmet>
            <Header />
            {!loading && data && Object.keys(data).length > 1 ?
                <>
                    <Container>
                        <ProductDiv>
                            <Row className="gap10"><CartIcon onClick={() => history.goBack()}>
                                <Icon name='arrow' /></CartIcon><TopTitle>BACK TO Blogs</TopTitle>
                            </Row>
                            <Hspace ht={10} />
                            <ProductDetail>
                                <Image>
                                    <FLex1>
                                        <img src={data?.image || Img} />
                                    </FLex1>
                                    <Hline />
                                    <Row className='j-sb m-t'>
                                        <Text className='sm grey'>{data?.created_at}</Text>
                                        <Text className="sm green">Total Views {data?.total_views}</Text>
                                    </Row>
                                </Image>
                                <FLex1>
                                    {/* <Column> */}
                                    <SubTitle>{data?.title}</SubTitle>
                                    <SmallDescription>{capitalize(data?.slug)}</SmallDescription>
                                    {/* </Column> */}
                                    <Description className="space-betn ">{parse(data?.description.toString())}</Description>
                                </FLex1>
                            </ProductDetail>
                        </ProductDiv>
                    </Container>
                </> : <DescriptionShimmer />}
                {data?.id ? <BlogsComments blogId={data?.id} /> : null}

            <Toast />
            <Footer />
        </Main>
    )
}

export default BlogDetails