import { AddressCard, AddressDelte, AddressShimmer, EditForm, Footer, Header, Overlay, Toast } from '../../components'
import Line from '../../assets/line.png'
import { AddressContainer, Card, Castle, CastleText, Container, EditContainer, Main, NewBoxAlign, AddText, AddButton, AddressText } from './address-components'
import { Hspace } from '../../styles'
import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { addUserAddress, deleteUserAddress, editUserAddress, getAllAddress } from '../../@services'
import { capitalize, userDetails } from '../../utils'
import { toast } from 'react-toastify'
import { AddressMeta } from "../../utils";


type UserAddress = {
    user_id: number;
    flat_house_no: string;
    address1: string;
    address2: string;
    street: string;
    landmark: string;
    city: string;
    state: string;
    country: string;
    zip: string;
    location: string;
};


const Address = () => {
    const [isOpenOverlay, setIsOpenOverlay] = useState<any>({ show: false, component: null, loading: false, items: null })
    const [data, setData] = useState<any>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [isAdd, setIsAdd] = useState<boolean>(false)
    const [isDelete, setIsDelete] = useState<boolean>(false)
    const [isEdit, setIsEdit] = useState<boolean>(false);
    const [locationType, setLocationType] = useState<string>("")
    const [userAddress, setUserAddress] = useState<UserAddress>({
        user_id: userDetails()?.user_id,
        flat_house_no: '',
        address1: '',
        address2: '',
        street: '',
        landmark: '',
        city: '',
        state: '',
        country: '',
        zip: '',
        location: locationType?.toLowerCase(),
    });

    const getUserAddress = async () => {
        setLoading(true)
        let params = {
            user_id: userAddress.user_id
        }
        await getAllAddress(params).then((res: any) => {
            if (res && res.status) {
                setData(res.data.data)
                setLoading(false)
            } else {
                console.log(res?.response)
                setLoading(false)
                setData([])
                // toast('Session Expired')
            }
        })
    }

    const addAddress = async () => {
        setIsAdd(true)
        let params = {
            ...userAddress,
            location: locationType.toLowerCase()
        }

        await addUserAddress(params)
            .then((res: any) => {
                if (res && res.status) {
                    console.log(res.data.data)
                    setIsOpenOverlay({ show: false })
                    setUserAddress({
                        user_id: userAddress.user_id,
                        flat_house_no: '',
                        address1: '',
                        address2: '',
                        street: '',
                        landmark: '',
                        city: '',
                        state: '',
                        country: '',
                        zip: '',
                        location: '',
                    })
                    setIsAdd(false)
                    getUserAddress();
                } else {
                    console.log(res.data)
                    setIsOpenOverlay({ show: false })
                    setIsAdd(false)
                }
            })
    }

    const onEdit = (item: any) => {
        console.log(item)
        setLocationType(item.location)
        setUserAddress({
            user_id: userAddress.user_id,
            flat_house_no: item.flat_house_no,
            address1: item.address1,
            address2: item.address2,
            street: item.street,
            landmark: item.landmark,
            city: item.city,
            state: item.state,
            country: item.country,
            zip: item.zip,
            location: item.location

        })
        setIsOpenOverlay({ show: true, component: 'edit', loading: false, items: item })
    }

    const onEdited = async () => {
        setIsEdit(true)

        let params = {
            address_id: isOpenOverlay?.items?.id,
            ...userAddress,
            location: locationType.toLowerCase(),
        }

        await editUserAddress(params).then((res: any) => {
            if (res && res.status) {
                console.log(res.data.data)
                setIsOpenOverlay({ show: false })
                setIsEdit(false)
                toast(res.data.message)
                getUserAddress();
                setUserAddress({
                    user_id: userAddress.user_id,
                    flat_house_no: '',
                    address1: '',
                    address2: '',
                    street: '',
                    landmark: '',
                    city: '',
                    state: '',
                    country: '',
                    zip: '',
                    location: '',
                })
            } else {
                // console.log(res.data)
                toast(res?.response?.data?.message)
                setIsOpenOverlay({ show: false })
                setIsEdit(false)
            }
        })
    }

    const onDelete = (item: any) => {
        console.log(item)
        setIsOpenOverlay({ show: true, component: 'delete', loading: false, items: item })
    }

    const onDeleted = async () => {
        setIsDelete(true)

        let params = {
            address_id: isOpenOverlay.items.id,
            user_id: userAddress.user_id
        }

        await deleteUserAddress(params).then((res: any) => {
            if (res && res.status) {
                console.log(res.data.data)
                setIsOpenOverlay({ show: false })
                setIsDelete(false)
                getUserAddress();
                toast(res.data.message)
            } else {
                toast(res?.response?.data?.message)
                getUserAddress();
                setIsOpenOverlay({ show: false })
                setIsDelete(false)
            }
        })
    }

    useEffect(() => {
        getUserAddress()
    }, [])

    return (
        <Main>
            <AddressMeta />
            <Header />
            <Container>
                <Castle>
                    <img width='200px' src={Line} alt="" />
                    <CastleText>Your Address</CastleText>
                    <img width='200px' src={Line} alt="" />
                </Castle>
                <Hspace ht={40} />
                {loading && <AddressShimmer />}
                {!loading && data &&
                    <AddressContainer>
                        <Card className="add" bdrStyl='dotted'
                            onClick={() => setIsOpenOverlay({ show: true, component: 'new-add', loading: false })}>
                            <AddressText>
                                <NewBoxAlign>
                                    <AddText>ADD A NEW ADDRESS</AddText>
                                    <Hspace ht={15} />
                                    <AddButton onClick={() => { }}>ADD NEW</AddButton>
                                </NewBoxAlign>
                            </AddressText>
                        </Card>

                        {data.map((item: any, index: number) => (
                            <AddressCard
                                key={index.toString()}
                                data={item}
                                onEditClicked={() => onEdit(item)}
                                onDeleteCliked={() => onDelete(item)} />
                        ))}
                    </AddressContainer>
                }
            </Container>

            {isOpenOverlay.show &&
                <>
                    <Overlay>
                        <EditContainer>
                            {isOpenOverlay.component == 'new-add' &&
                                <EditForm
                                    type='add'
                                    userAddress={userAddress}
                                    setUserAddress={setUserAddress}
                                    locationType={locationType}
                                    setLocationType={setLocationType}
                                    onSubmit={addAddress}
                                    onDelete={onDeleted}
                                    isAdding={isAdd}
                                    isDeleting={isDelete}
                                    onClose={() => setIsOpenOverlay({ show: false, component: 'new-add', loading: false })}
                                />
                            }
                            {isOpenOverlay.component == 'edit' &&
                                <EditForm
                                    type='edit'
                                    userAddress={userAddress}
                                    setUserAddress={setUserAddress}
                                    locationType={locationType}
                                    setLocationType={setLocationType}
                                    onSubmit={onEdited}
                                    onDelete={onDeleted}
                                    isEditing={isEdit}
                                    isDeleting={isDelete}
                                    onClose={() => setIsOpenOverlay({ show: false, component: 'edit', loading: false })}
                                />
                            }
                            {isOpenOverlay.component == 'delete' &&
                                <AddressDelte
                                    onCancel={() => setIsOpenOverlay({ show: false, component: 'delete', loading: false })}
                                    onSubmit={onDeleted}
                                    isLoading={isDelete}
                                    onClose={() => setIsOpenOverlay({ show: false, component: 'delete', loading: false })} />
                            }
                        </EditContainer>
                    </Overlay>
                </>
            }
            <Toast />
            <Footer />
        </Main>

    )
}

export default Address