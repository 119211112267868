export const inline = { marginLeft: 60, display: "inline" };
export const jobs_array = [
  {
    id: 0,
    text: (
      <>
        <br />
        <div style={inline}></div>1. Handmade Excellence: Every item we offer is
        meticulously crafted through a labor-intensive handmade process,
        ensuring the utmost attention to detail and a personal touch in each
        creation.
      </>
    ),
  },
  {
    id: 1,
    text: (
      <>
        <br />
        <div style={inline}></div>2. Pure Organic Ingredients: We're dedicated
        to your well-being and the environment. Our products are made using only
        the purest organic materials, free from harmful chemicals and synthetic
        additives.
      </>
    ),
  },
  {
    id: 2,
    text: (
      <>
        <br />
        <div style={inline}></div>3. Sustainability First: At HMD INDIA, sustainability is more than a buzzword—it's a way of life. We've
        integrated eco-friendly practices into every aspect of our production to
        minimize our ecological footprint.
      </>
    ),
  },
  {
    id: 3,
    text: (
      <>
        <br />
        <div style={inline}></div>4. No Machinery, Only Artisans: You won't find
        machinery on our production floor. Instead, our skilled artisans rely on
        time-honored techniques and traditional craftsmanship to bring each
        piece to life.
      </>
    ),
  },
  {
    id: 4,
    text: (
      <>
        <br />
        <div style={inline}></div>5. Custom Creations: We understand that you're
        unique, and so are your needs. We offer personalized options to cater to
        your individual tastes and preferences.
      </>
    ),
  },
  {
    id: 5,
    text: (
      <>
        <br />
        <div style={inline}></div>6. Community Support: We're not just a
        business; we're a community. By choosing our products, you're supporting
        local artisans and their families, helping to preserve traditional
        skills.
      </>
    ),
  },
  {
    id: 6,
    text: (
      <>
        <br />
        <div style={inline}></div>7. Quality Assurance: Our rigorous quality
        control ensures that every item leaving our workshop is nothing short of
        perfect. We stand by the excellence of our products.
      </>
    ),
  },
];
