import styled from "styled-components";


export const Overlay = styled.div`
position: fixed;
top: 0;
left: 0;
width: 100%;
height: 100%;
background-color: rgb(0 0 0 / 15%);
z-index: 54; 
display: flex;
align-items: center;
justify-content: center;
max-height: 100vh;
overflow: auto;
`;

export const Content = styled.div`
/* Add your content styles here */
`;

export const CloseButton = styled.div`
position: absolute;
top: 10px;
right: 25px;
cursor: pointer;
//   background-color: white;
padding: 7px 13px;
border: 3px solid white;
border-radius: 20px;
svg {
path {
    stroke: white;
    stroke-width: 3px;
}
}
`;
