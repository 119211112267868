import { ReactNode } from "react";
import { Button } from "./primary-button-components"


type Props = {
    name: string;
    onClick: (e?: MouseEvent) => void;
    align?: string;
    justify?: string;
    padding?: number;
    wd?: number | any;
    shadow?: boolean;
    children?: any;
    backColor?: string;
    fSize?: number
    isDisabled?: boolean

}
const PrimaryBtn = ({ name, onClick, align, justify, padding, wd, shadow, children, backColor, fSize, isDisabled }: Props) => {
    const onClicked = () => {
        if (!isDisabled) {
            onClick()
        } else {
            // console.log("disabled")
        }
    }
    return (
        <Button wid={wd} pad={padding} onClick={onClicked} isDisabled={isDisabled}
            align={align} justify={justify} shadow={shadow} backClr={backColor} fSz={fSize}>
            {name}
            {children}
        </Button>
    )
}

export default PrimaryBtn