export const AddressBoxShimmer = () => {
    return <div className='shine' style={{ height: "150px", width: "100%" }}></div>
}

export const CrouselBoxShimmer = () => {
    return <div className='shine' style={{ height: "120px", width: "100%" }}></div>
}

export const CouponShimmer: any = () => {
    return Array.from({ length: 4 }).map((_, index) => {
        return <div key={index.toString()} className='shine' style={{ height: "120px", width: "100%" }}></div>
    })
}