import { greenTheme, themeBackground, whiteColor } from "../../styles";
import styled from "styled-components";

export const Container = styled.div`
  background: ${themeBackground};
  padding: 7px 8%;
  position: relative;
`;

export const Row = styled.div`
  position: relative;
  display: flex;
  flex-direction: revert;
  align-items: center;
  justify-content: space-between;


  &.j-start {
    justify-content: flex-start;
    gap: 50px;
  }

  &.g10 {
    gap: 10px;
  }

  input[type="radio" i] {
    height: 20px;
}
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 0px 20px;
`;

export const Item = styled.div`
  color: #fff;
  opacity: 1;
  z-index: 51;
  // padding-left: 10px;
  font-size: 18px;
  display: block;
  position: relative;
  float: left;
  width: 100%;
  padding: 10px 13px;
  cursor: pointer;
  &:hover {
    background-color: ${greenTheme};
    // padding: 13px;
  }
`;

export const EmptyDiv = styled.div`
  &.closebtn {
    position: absolute;
    top: 0;
    right: 30px;
    font-size: 36px;
    margin-left: 50px;
    color: #fff;
  }

  &.open-btn {
    font-size: 30px;
    cursor: pointer;
    /* position: fixed; */
    left: 10px;
    top: 10px;
    z-index: 2;
    color: #fff;
  }

  &:hover {
    cursor: pointer;
  }
`;

export const SideNav = styled.div`
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 52;
  top: 0;
  left: 0;
  background: linear-gradient(to left, rgb(76, 75, 75), rgb(77, 146, 25));
  overflow-x: hidden;
  // transition: 0.5s;
  transition: all 0.5s ease;
  padding-top: 60px;
  ${({ wd }: { wd?: number }) => {
    return `width: ${wd}px;`;
  }}

  @media screen and (max-height: 450px) {
    padding-top: 15px;
    font-size: 10px;
  }
`;

export const Img = styled.img`
  height: 70px;
  cursor: pointer;
  margin-left: 10px;
  @media only screen and (max-width: 768px) {
    &.hide {
      display: none;
    }
    &.show {
      display: ;
    }
  }

  @media only screen and (min-width: 768px) {
    &.show {
      display: none;
    }
  }
`;

export const LoginButton = styled.div`
  background-color: #ff0000;
  color: #fff;
  padding: 6px 20px 10px;
  border-radius: 5px;
  box-shadow: 2px 3px 4px -2px #000;
  font-weight: 600;

  &:hover {
    cursor: pointer;
  }
`;

export const LoginContainer = styled.div`
  display: flex;
  flex-direction: Column;
  align-items: center;
  //   justify-content: space-between;
  overflow: auto;
  background-color: ${whiteColor};
  max-height: 95vh;
  padding: 10px;
  max-width: 500px;
  width: 100%;
  padding: 20px;
`;
