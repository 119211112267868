import { useCartContext } from "../../context"
import React, { useCallback, useEffect, useState } from "react"
import { EmptyDiiv, Hline, Hspace, themeBackground } from "../../styles"
import Primary from "../Buttons/primary"
import Icon from "../icon"
import {
    CartConatiner,
    Payment,
    ItemConteiner,
    Row,
    CartItemText,
    IconDiv, Count,
    Price,
    Ashrafiya,
    Checkbox,
    ChkInput,
    Label,
    Text,
    BottomCart,
    AnnaInfo,
    Sign,
    InfoText,
    Input,
    AnnaBox,
    AnnaUsed,
    AnnaInput,
    Clear
} from './cart-components'
import Overlay from "../overlay"
import CheckoutCoupon from "../checkout-coupon"
import { isLoggedIn, userDetails } from "../../utils"
import { getAnnas, getCupons } from "../../@services"
import { useLocation } from "react-router-dom"
import { toast } from "react-toastify"
import { red } from "@mui/material/colors"

type Props = {
    border?: boolean;
    onProceed: () => void;
    isCheckout?: boolean;
    cartItems?: any;
    setCartItems?: any;
    selectedCoupon?: any,
    setSelecetdCoupon?: any,
    tax?: number;
    totatCost?: number;
    paymentMode?: string;
    deliveryFee?: number;
}
const Cart = ({ border, onProceed, isCheckout, selectedCoupon, setSelecetdCoupon, tax, totatCost, paymentMode, deliveryFee }: Props) => {
    const { pathname } = useLocation()
    const { cartItems, setCartItems, cartProducts, setCartProducts, totalAmount, cartAmount, setCartAmount, annaValue, setAnnaValue } = useCartContext()
    const [isOpenOverlay, setIsOpenOverlay] = useState<any>({ show: false, component: null, loading: false, items: null })
    const [annaLoad, setAnnaLoad] = useState<boolean>(false);
    const [annas, setAnnas] = useState<any>();
    const [coupons, setCoupons] = useState<any>([]);
    const [isCouponLoad, setIsCouponLoad] = useState<boolean>(false);
    const [useAnna, setUseAnna] = useState<string>("")
    const [pickAnna, setPickAnna] = useState<any>('');

    const getTotalAnnas = async () => {
        setAnnaLoad(true)
        let param: any = {
            user_id: userDetails()?.user_id
        }
        await getAnnas(param).then((res: any) => {
            if (res && res.status) {
                setAnnas(res?.data?.data)
                setAnnaLoad(false)
            } else {
                setAnnaLoad(false)
            }
        })

    }

    const incrementItem = (productId) => {
        setCartProducts((prevCart) => {
            const updatedCart = prevCart.map((item) =>
                item.id === productId && item.count < item.quantity
                    ? { ...item, count: item.count + 1 }
                    : item
            );
            localStorage.setItem('cartItems', JSON.stringify(updatedCart));
            if (!isCheckout) {
                setSelecetdCoupon([]);
                clearAnnaValue();
            }
            return updatedCart;
        });
        calcCartAmount()
    };

    const decrementItem = (productId) => {
        setCartProducts((prevCart) => {
            if (prevCart.some((item) => item.id === productId && item.count <= 1)) {
                const filteredItem = prevCart.filter((item) => item.id !== productId)
                setCartItems(filteredItem);
                setCartProducts(filteredItem)
            }

            if (cartProducts?.length == 1 && cartProducts[0].count == 1) {
                return localStorage.removeItem('cartItems')
            }

            localStorage.setItem('cartItems', JSON.stringify(prevCart));
            if (!isCheckout) {
                setSelecetdCoupon([]);
                clearAnnaValue();
            }
            return prevCart.map((item) =>
                item.id === productId && item.count > 1
                    ? { ...item, count: item.count - 1 }
                    : item
            );
        });
    };

    const getAllCupons = async () => {
        setIsCouponLoad(true);
        await getCupons().then((res: any) => {
            if (res && res?.status) {
                setCoupons(res?.data?.data);
                setIsCouponLoad(false);
            } else {
                console.log(res?.response?.data?.message)
                // toast(res?.response?.data?.message);
            }
        })
    }

    useEffect(() => {
        const updatedProducts = cartItems?.map((cartItem: any) => {
            const existingProduct = cartProducts?.find((product: any) => product.id === cartItem.id);
            const count = existingProduct ? existingProduct.count : 1;
            return { ...cartItem, count };
        });
        localStorage.setItem("cartItems", JSON.stringify(updatedProducts))
        if (!isCheckout) {
            setSelecetdCoupon([]);
        }
        setCartProducts(updatedProducts);
    }, [cartItems]);

    const calcCartAmount = () => {
        if (selectedCoupon?.length) {
            if (selectedCoupon[0]?.min_order <= totalAmount) {
                let calcDiscount: number = (selectedCoupon[0]?.type == 'percent' || selectedCoupon[0]?.type == 'Percent') ?
                    (parseFloat(selectedCoupon[0]?.discount_value) / 100 * totalAmount) :
                    parseFloat(selectedCoupon[0]?.discount_value)
                console.log("🚀  calcDiscount:", calcDiscount)

                let checkMaxDiscount: number = calcDiscount >= selectedCoupon[0]?.max_discount ?
                    totalAmount - selectedCoupon[0]?.max_discount :
                    totalAmount - calcDiscount;
                setCartAmount(checkMaxDiscount - annaValue)
            }
        } else {
            setCartAmount(totalAmount - annaValue)
        }
    }

    const handleAnna = (e: any) => {
        let checkAnna = parseInt(pickAnna);
        if (checkAnna <= 0) {
            setPickAnna('')
            toast('Enter annas amount')
            return;
        }
        if (checkAnna >= 10 && checkAnna <= 50) {
            setAnnaValue(checkAnna)
        } else {
            setPickAnna(0)
            toast('Max Rs.50 & Min Rs.10 can be used')
        }
    }
    const clearAnnaValue = () => {
        setPickAnna("");
        setAnnaValue(0)
    }

    const toggleCoupons = () => {
        if (useAnna == `Yes`) {
            toast('Already using anna');
            return;
        }
        setIsOpenOverlay({ show: true, component: 'coupon', loading: false })
    }

    const removeCoupon = () => {
        setSelecetdCoupon(null);
        setUseAnna('');
    }

    useEffect(() => {
        let finalAMount = totalAmount - annaValue;
        setCartAmount(finalAMount)
    }, [annaValue])

    useEffect(() => {
        if (selectedCoupon?.length) {
            calcCartAmount()
        } else {
            setCartAmount(totalAmount - annaValue)
        }
    }, [cartItems, cartProducts, selectedCoupon, annaValue])

    useEffect(() => {
        if (pathname.includes('checkout') && isLoggedIn()) {
            getTotalAnnas()
        }
    }, [])


    useEffect(() => {
        if (isOpenOverlay?.show && isOpenOverlay.component == 'coupon' && !coupons?.length)
            getAllCupons();
    }, [isOpenOverlay])

    useEffect(() => {
        if (!isCheckout && paymentMode === "Online") {
            setUseAnna('')
        } else if (!isCheckout && paymentMode === "Cash") {
            setUseAnna('No')
            setSelecetdCoupon([]);
            setAnnaValue(0)
        }
    }, [paymentMode])


    const CartProds = () => {
        return cartProducts.map((item: any, index: number) => {
            return (
                <React.Fragment key={index.toString()}>
                    <Row>
                        <Row className="gap10">
                            <Icon name={item?.type == 'Veg' ? 'veg' : 'nonVeg'} />
                            <CartItemText>{item?.name}</CartItemText>
                        </Row>
                        <EmptyDiiv>
                            <Row className="sku">~Net weight: {item?.sku_name} {item?.units?.name}</Row>
                            <Hspace />
                            <Row className="gap5 noJustify">
                                <IconDiv onClick={() => decrementItem(item?.id)}><Icon name="minus" /></IconDiv>
                                <Count>{item?.count}</Count>
                                <IconDiv onClick={() => incrementItem(item?.id)}><Icon name="plus" /></IconDiv>
                                <Price>₹{item?.amount * item?.count}</Price>
                            </Row>
                        </EmptyDiiv>
                    </Row>
                </React.Fragment>
            )
        })
    }

    return (
        <>
            <CartConatiner className="cart" bdr={border}>
                <div>Cart {cartItems?.length} item</div>
                <Hspace ht={20} />
                <ItemConteiner>
                    {cartProducts && cartProducts?.length ? <CartProds /> : null}
                </ItemConteiner>
                {isCheckout &&
                    <>
                        <Hspace ht={30} />
                        <Hline />
                        <Payment>Pay Amount <div>{totalAmount}</div></Payment>
                        <Hspace ht={20} />
                        <center><Primary name="" wd={200} padding={7} onClick={onProceed}>Proceed To Checkout</Primary></center>
                    </>
                }
            </CartConatiner>

            {!isCheckout && <BottomCart>
                <Payment>Cart Amount <div>₹{totalAmount}</div>
                </Payment>
                <AnnaUsed className="m-tp">*(Inclusive of all taxes)</AnnaUsed>
                <EmptyDiiv>
                    <AnnaUsed className="m-tp info">*Anna & Coupon not available for Cash method.</AnnaUsed>
                    <Row className="gap10">
                        <Ashrafiya onClick={() => { }} active={paymentMode === "Cash"}>
                            {annaLoad ? "Loading..." : annas?.result?.total_annas ? `${annas?.result?.total_annas} annas` : `0 anna`}
                        </Ashrafiya>
                        <Ashrafiya className="hand" active={useAnna == 'Yes' || paymentMode === "Cash"} onClick={toggleCoupons}>
                            {selectedCoupon?.length ? "Applied" : "Apply"} Coupon
                        </Ashrafiya>
                    </Row>
                    <AnnaInfo>*Note: 16 annas = 1 Rupee</AnnaInfo>

                    <Hspace ht={8} />
                    {useAnna == '' && selectedCoupon?.length === 0 && annas?.result?.total_annas > 160 &&
                        <Row>
                            <EmptyDiiv>
                                <InfoText>Do you want to use Annas?</InfoText>
                                <Hspace ht={2} />
                                <InfoText className="sm">*Min: Rs. 10 & Max Rs. 50</InfoText>
                            </EmptyDiiv>
                            <Row className="gap5">
                                <Sign className="tick" onClick={() => setUseAnna("Yes")}><Icon name="tick" /></Sign>
                                <Sign onClick={() => setUseAnna("No")}><Icon name="smallCross" /></Sign>
                            </Row>
                        </Row>
                    }
                    {useAnna == 'Yes' && annas?.result?.total_annas > 160 &&
                        <AnnaBox>
                            <AnnaInput>Rs <Input value={pickAnna} maxLength={2} onChange={(e) => setPickAnna(e.target.value)} disabled={annaValue >= 10} /> x<span>16</span></AnnaInput>
                            {annaValue >= 10 && <Clear onClick={clearAnnaValue}>clear</Clear>}
                            {annaValue <= 0 && <Row>
                                <Sign className="tick" disable={pickAnna <= 0} onClick={handleAnna}><Icon name="tick" /></Sign>
                                <Sign onClick={() => setUseAnna("No")}><Icon name="smallCross" /></Sign>
                            </Row>}
                            {annaValue >= 10 && <AnnaUsed>{annaValue * 16} anna used</AnnaUsed>}
                        </AnnaBox>
                    }
                </EmptyDiiv>
                {selectedCoupon?.length ?
                    <Row>
                        <Row className="noJustify gap10">
                            <Checkbox className="round" onClick={() =>
                                setIsOpenOverlay({ show: true, component: 'coupon', loading: false })
                            }>
                                <ChkInput type="checkbox" id="d-checkbox" checked readOnly />
                                <Label htmlFor="d-checkbox"></Label>
                            </Checkbox>
                            <Text className="coupon">{selectedCoupon[0]?.coupon_code} Applied</Text>
                        </Row>
                        <Text className="remove" onClick={removeCoupon}>Remove</Text>
                    </Row> : null
                }
                <Payment>Tax <div>₹{tax}</div></Payment>
                {(selectedCoupon || annaValue) && paymentMode === "Online" ?
                    <Payment>You have saved <div>{selectedCoupon ? Math.abs(totalAmount - cartAmount).toFixed(2) : annaValue}</div></Payment>
                    : null}
                <Payment>Subtotal <div>₹{cartAmount}</div></Payment>
                <Payment>Delivery Fee <Text>{deliveryFee === 0 ? "Free" : `₹${deliveryFee}`}</Text></Payment>
                {deliveryFee !== 0 ? <Text className="f-sz">Add items worth ₹{(parseInt(process.env.REACT_APP_MIN_ORDER_VALUE as string) - cartAmount) + 1} for FREE delivery</Text> : null}
                <Hline />
                <Payment>Pay Amount <div>₹{totatCost}</div></Payment>
            </BottomCart>}
            {
                isOpenOverlay.show &&
                <Overlay>
                    {isOpenOverlay.component == 'coupon' &&
                        <CheckoutCoupon
                            onClose={() => setIsOpenOverlay({ show: false, component: 'coupon', loading: false })}
                            coupons={coupons}
                            setCoupons={setCoupons}
                            isCouponLoad={isCouponLoad}
                            setIsCouponLoad={setIsCouponLoad}
                            selectedCoupon={selectedCoupon}
                            setSelecetdCoupon={setSelecetdCoupon}
                        />
                    }
                </Overlay>
            }
        </>
    )
}

export default Cart