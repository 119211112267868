import { Castle, CastleText, Column, Container, EmptyDiv, ItemContainer, ItemHead, Main, Row, Span } from "./terms-components"
import Line from '../../assets/line.png'
import { Footer, Header, Icon } from "../../components"
import { useState } from "react"
import { data, inline } from "./data"
import { Hspace } from "../../styles"
import { TermsMeta } from "../../utils";



const Terms = () => {
    const [expansed, setExpanded] = useState<boolean>(false)
    const [expandId, setExpandId] = useState<number>();
    return (
        <Main>
            <TermsMeta />
            <Header />
            <Container>
                <Castle>
                    <img width='200px' src={Line} alt="" />
                    <CastleText>TERMS & CONDITIONS</CastleText>
                    <img width='200px' src={Line} alt="" />
                </Castle>
                <Hspace ht={20} />
                <Column>
                    <EmptyDiv className="line">
                        <EmptyDiv style={inline}>HMD INDIA's Products is the owner and operator of this website. The terms "we," "us," and "our" refer to HMD INDIA throughout the site. HMD INDIA provides you, the user, with this website, including all information, tools, and services available from this site, conditioned on your acceptance of all terms, conditions, policies, and notices stated here.</EmptyDiv>
                        <br />
                        <EmptyDiv style={inline}>By visiting our site and/or purchasing something from us, you engage in our "Service" and agree to be bound by the following terms and conditions ("Terms of Service", "Terms"), as well as any additional terms and conditions and policies referenced herein and/or accessible via hyperlink. These Terms of Service apply to all users of the site, including but not limited to browsers, vendors, customers, merchants, and/or content contributors.</EmptyDiv>
                        <br />
                        <EmptyDiv style={inline}>Please carefully read these Terms of Service before accessing or using our website. You agree to be bound by these Terms of Service by accessing or using any part of the site. You may not access the website or use any services if you do not agree to all of the terms and conditions of this agreement. Acceptance is expressly limited to these Terms of Service if these Terms of Service are considered an offer.
                            Digit Pay Technology hosts our store. They provide an online e-commerce platform through which we can sell our products and services to you.</EmptyDiv>
                    </EmptyDiv>

                    {data.map((item: any, i: number) => {
                        return (
                            <ItemContainer key={item.id.toString()} expanded={expansed && item.id == expandId} onClick={() => {
                                setExpanded(prev => !prev);
                                setExpandId(i)
                            }}>
                                <Row className="item-head" actv={expansed && item.id == expandId}>
                                    <ItemHead>{i + 1}. {item?.title}</ItemHead>
                                    <Span><Icon name="backArrow" /></Span>
                                </Row>
                                <EmptyDiv>{item.text}</EmptyDiv>
                            </ItemContainer>
                        )
                    })}
                </Column>
            </Container>

            <Footer />
        </Main>
    )
}

export default Terms