import { brown, grey, lightGrey, redish } from "../../styles";
import styled from "styled-components";

export const FormCOntainer = styled.div`
  // transform: translate(-50%, 3%);
  position: relative;
  background: #ffffff;
  border-radius: 5px;
  box-shadow: 0px 4px 8px 2px #111;
  padding: 8px;
  max-width: 500px;
  width: 100%;
  margin: 0px 20px;
  transition: all 0.5s ease;

  &.expanded {
    transform: scale(1.05);
  }

  @media only screen and (max-width: 767px) {
    max-width: 400px;
  }
`;

export const EditTextContainer = styled.div`
  max-width: 200px;
  /* width: 100%; */
  font-size: 22px;
  font-weight: 500;
  text-align: center;
  img {
    vertical-align: top;
    width: 100%;
  }
`;

export const Center = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  &.gap15 {
    gap: 15px;
  }
`;
export const Close = styled.div`
  font-weight: bold;
  font-size: 20px;
  position: absolute;
  background-color: black;
  padding: 4px 8px;
  border-radius: 35px;
  color: white;
  top: -5px;
  right: -5px;
  border: 2px solid ${brown};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &:hover {
    color: ${redish};
  }
`;

export const InnerContainer = styled.div`
  border: 3px solid rgb(181 33 33);
  padding: 8px;
  width: 100%;
`;

export const Inner = styled.div`
  border: 3px solid rgb(181 33 33);
  padding: 0px 20px;

  @media only screen and (max-width: 767px) {
    padding: 0px 6px;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: Column;
  align-items: center;
  overflow: auto;
  max-height: 95vh;
  padding: 10px;
  max-width: 800px;
  width: 100%;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  &.flex-1 {
    flex: 1;
  }

  &.flex-2 {
    flex: 2;
  }

  &.gap10 {
    gap: 10px;
  }

  &.gap30 {
    gap: 30px;
  }

  &.flex-end {
    justify-content: flex-end;
  }
`;

export const Text = styled.div`
  font-weight: 500;
  font-size: 0.875rem;
  color: ${brown};

  &.brown {
    color: ${brown};
    cursor: pointer;
  }

  &.cursor {
    cursor: pointer;
  }
`;

export const Input = styled.input`
  background-color: #eee !important;
  outline-width: thin;
  // border: transparent;
  border: 1px solid ${grey};
  padding: 10px 8px;
  border-radius: 4px;
  color: rgb(102 92 92);
  font-size: 16px;

  &:active,
  &:hover {
    border: 1px solid ${lightGrey};
  }
  &:focus {
    outline: none;
  }
`;
export const PasswordDiv = styled.div`
  position: relative;
`;
export const Span = styled.span`
  position: absolute;
  right: 9px;
  top: 10px;
`;

interface PasswordInputProps {
  showPassword: boolean;
}

export const PasswordInput = styled(Input).attrs(
  (props: PasswordInputProps) => ({
    type: props.showPassword ? "text" : "password",
  })
)`
  width: 100%;
`;
export const OtpContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;

  &.flex-end {
    align-items: flex-end;
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  // justify-content: space-between;

  &.flex - 1 {
    flex: 1;
  }

  &.flex - 2 {
    flex: 2;
  }

  &.gap10 {
    gap: 10px;
  }

  &.gap30 {
    gap: 30px;
  }

  &.gap5 {
    gap: 5px;
  }

  &.resend {
    justify-content: unset;
  }

  &.j-sb {
      justify-content: space-between;
  }

  @media only screen and(min-width: 990px) {
    &.show {
      display: none;
    }
  }

  @media only screen and(max-width: 458px) {
    &.small {
      flex-direction: column;
      align-items: flex-start;
      // justify-content: flex-start;
    }
  }
`;

export const EmptyDiv = styled.div``;

export const Img = styled.img``;

export const Error = styled.div`
  color: ${redish};
  font-family: Nunito Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  max-width: 600px;
  overflow-wrap: break-word;
`;

export const Label = styled.label`
  width: 100%;
  text-align: left;
  font-size: 12px;
  margin-bottom: 5px;
  color: ${lightGrey};
`;

export const Seperator = styled.div`
  display: flex;
  flex-direction: column;
`;
