import { FormCOntainer, InnerContainer, Close, Inner, Column, Center, EditTextContainer, ButtonContainer } from "./address-delete-components"
import EditBottom from '../../assets/svgs/addressBottom.svg';
import EditTop from '../../assets/svgs/addressTop.svg';
import EditLine from '../../assets/svgs/editLine.svg';
import { darkBorder, Hspace } from "../../styles";
import Primary from "../Buttons/primary";
import Icon from "../icon";


type Props = {
    onClose: () => void;
    onSubmit: any;
    onCancel: any;
    isLoading?: boolean
    children?: any;
}

const DeletePopup = ({ onClose, onSubmit, onCancel, isLoading, children }: Props) => {
    return (
        <FormCOntainer>
            {children ? children :
                <>
                    <Close onClick={onClose}><Icon name="cross" /></Close>
                    <InnerContainer>
                        <Inner>
                            <Column>
                                <Center className='gap15'>
                                    <img width='150px' src={EditTop} alt="" />
                                    <EditTextContainer>
                                        <div>Delete Address</div>
                                        <img src={EditLine} alt='edit svg' />
                                    </EditTextContainer>
                                </Center>
                                <ButtonContainer>
                                    <Primary backColor={`${darkBorder}`} wd={160} padding={5} fSize={17} name='' onClick={onSubmit}>{isLoading ? "Loading.." : "YES, DELETE"}</Primary>
                                    <Primary name='' padding={5} fSize={17} onClick={onCancel}>CANCEL</Primary>
                                </ButtonContainer>
                                {/* <Hspace ht={20} /> */}
                                <Center>
                                    <img width='150px' src={EditBottom} alt="svg" />
                                </Center>
                            </Column>
                        </Inner>
                    </InnerContainer>
                </>}
        </FormCOntainer>
    )
}

export default DeletePopup