import styled from "styled-components";
const AddressGrid = styled.div`
display: grid;
grid-template-columns: repeat(3, 1fr);
grid-column-gap: 20px;
grid-row-gap: 20px;

@media only screen and (max-width: 991px) {
    grid-template-columns: repeat(2, 1fr);
}

@media only screen and (max-width: 767px) {
    grid-template-columns: repeat(1, 1fr);
}
`
export default function AddressShimmer (){
    const numberOfThumbnails = 6; 

    return (
        <AddressGrid>
            {Array.from({ length: numberOfThumbnails }).map((_, index) => {
                return (
                    <div  key={index.toString()} className='shine' style={{ height: "250px", width: "100%" }}></div>
                )
            })}
        </AddressGrid>
    )
};