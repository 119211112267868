import { mediumBorde, whiteColor } from "../../styles";
import styled from "styled-components";

export const Main = styled.div`
  overflow: auto;
  max-height: calc(100dvh - 0px);
`;
export const Container = styled.div`
  margin: 40px 100px;
  min-height: calc(100vh - 225px);
  height: 100%;
  @media only screen and (max-width: 458px) {
    margin: 20px 20px;
  }
`;

export const CastleText = styled.div`
  font-size: 25px;
  font-weight: 500;
`;

export const Castle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const AddressContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  background-color: ${whiteColor};
  padding: 20px 25px;

  grid-row-gap: 20px;
  grid-column-gap: 20px;

  @media only screen and (max-width: 991px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media only screen and (max-width: 767px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const EditContainer = styled.div`
  display: flex;
  flex-direction: Column;
  align-items: center;
  //   justify-content: space-between;
  overflow: auto;
  //   background-color: #f9f9ff;
  max-height: 95vh;
  padding: 10px;
  max-width: 800px;
  width: 100%;
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;
  border-radius: 8px;

  ${({ bdrStyl }: { bdrStyl?: string }) => {
    if (bdrStyl) {
      return `border: 1px ${bdrStyl} ${mediumBorde};`;
    } else {
      return `border: 1px solid ${mediumBorde};`;
    }
  }}

  &.add {
    align-items: center;
    justify-content: center;
  }
`;

export const AddressText = styled.div`
  margin-bottom: 1rem;
  font-style: normal;
  line-height: 25px;
`;

export const NewBoxAlign = styled.div`
  text-align: center;
`;

export const AddText = styled.div`
  color: rgb(67, 67, 67);
  font-size: 0.875rem;
  letter-spacing: 0.1875rem;
  font-weight: 500;
  text-transform: uppercase;
  margin: 0.875rem 0px 0;
`;

export const AddButton = styled.div`
  display: inline-block;
  text-transform: uppercase;
  font-size: 0.875rem;
  font-weight: 500;
  cursor: pointer;
  padding: 0.375rem 1.125rem;
  border-width: 0.0625rem;
  border-style: dashed;
  border-color: rgb(45, 40, 46);
  border-image: initial;
  color: #777;
`;
