//@ts-nocheck
import { Hline, Hspace, Vline } from '../../styles'
import { Icon, PrimaryButn } from '..'
import { Row, Column, Text, FormContainer, Input, FormLeft, FormRight, Main, OtpContainer, Divider, OR, FromDivider, DeliveryContainer, Address, Checkbox, ChkInput, Label, Seperator, Error, PasswordDiv, PasswordInput, Span, InfoIcon, InfoText } from './checkout-form-components'
import { useState } from 'react'
import { toast } from 'react-toastify'
import Toast from '../toast'
import { forgotPassword, register, resendRegisterOtp, userlogin, verifyRegisterOtp, onBoard } from '../../@services'

export const CheckoutForm = () => {
    const [registerParms, setRegisterParams] = useState<any>({ name: '', email: '', mobile: '', password: '' })
    const [loginParams, setLoginParams] = useState<any>({ email: '', password: '' })
    const [loginLoad, setLoginLoad] = useState(false);
    const [registerLoad, setRegisterLoad] = useState<boolean>(false);
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [mode, setMode] = useState<string>('first');
    const [otp, setOpt] = useState<string>("");
    const [resend, setResend] = useState<boolean>(false)
    const [load, setLoad] = useState<boolean>(false)


    const emailRegex = /^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/;
    const passwordRegex = /^(?!.*\s)(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[~`!@#$%^&*()--+={}\[\]|\\:;"'<>,.?/_₹]).{10,16}$/;
    const mobileRegex = /^[0-9]{10}$/;

    const OnBoard = async () => {
        if (!isRegister()) {
            if (registerParms.name.length < 5) {
                toast('Name must contain more than 5 letters');
                return;
            }
            toast('Please fill the details')
            return;
        }
        setRegisterLoad(true)
        let params = {
            user_id: parseInt(localStorage.getItem("userId")),
            name: registerParms.name,
            email_id: registerParms.email,
            // mobile: registerParms.mobile,
            password: registerParms.password,
            mobile: registerParms.mobile
        };

        await onBoard(params).then((res: any) => {
            if (res && res.status) {
                console.log(res.data);
                // setMode('login');
                setRegisterLoad(false)
                setRegisterParams({ name: '', email: '', mobile: '', password: '', retypePassword: '' })
                localStorage.setItem('hmd_user_details', JSON.stringify(res.data.data.data.user_data));
                localStorage.setItem('hmd_access_token', res.data.data.data.access_token);
                setTimeout(() => {
                    window.location.reload()
                }, 2000)
            } else {
                toast(res?.response?.data?.message)
                setRegisterLoad(false)
            }
        })
    };


    const Register = async () => {
        if (!validateMobile()) {
            return;
        }
        setRegisterLoad(true)
        let res = await register(registerParms.mobile)
        if (res?.status == 200) {
            console.log(res.data.data)
            setRegisterLoad(false)
            localStorage.setItem("userId", res?.data?.data?.user_id)
            if (res?.data?.data?.is_mobile_verify === 1) {
                setMode('register')
                localStorage.setItem("userId", res?.data?.data?.user_id)
                toast(res?.data?.message)
                return;
            }
            toast(res?.data?.message)
            setMode('registerOtp')
            await new Promise((resolve) => setTimeout(resolve, 10000));
            setResend(true)
        } else {
            toast(res?.response?.data?.message)
            setRegisterLoad(false)
        }
    };

    const Login = async () => {
        if (!isLogin()) {
            toast('Please fill the details')
            return;
        }
        setLoginLoad(true)
        let params = {
            email_id: loginParams.email,
            password: loginParams.password
        }

        await userlogin(params).then((res: any) => {
            if (res && res.status) {
                localStorage.setItem('hmd_user_details', JSON.stringify(res.data.data.user_data));
                localStorage.setItem('hmd_access_token', res.data.data.access_token);
                console.log("🚀 ~ file: checkout-form.tsx:103 ~ awaituserlogin ~ res.data.data:", res.data.data)
                setLoginLoad(false)
                toast(res?.data?.message)
                // setLogin(false);
                setTimeout(() => {
                    window.location.reload()
                }, 2000)
                setLoginParams({ email: '', password: '' })
            } else {
                toast(res?.response?.data?.message)
                setLoginLoad(false)
            }
        })
    }

    const SendRegisterOtp = async () => {
        setRegisterLoad(true)
        let params: any = {
            six_digit_code: otp,
            mobile: parseInt(registerParms.mobile)
        }
        await verifyRegisterOtp(params).then((res: any) => {
            if (res && res.status == 200) {
                // console.log(res.data);
                localStorage.setItem("userId", res?.data?.data?.user_id)
                setMode('register');
                setRegisterLoad(false)
            } else {
                // console.log("res?.data", res)
                toast(res?.data?.message)
                toast(res?.response?.data?.message)
                setRegisterLoad(false)
            }
        })
    }

    const ResendRegisterOtp = async () => {
        setLoad(true)
        let res = await resendRegisterOtp(registerParms.mobile)
        if (res?.status == 200) {
            setResend(false)
            setOpt('')
            setLoad(false)
            toast(res?.data?.message)
            // setMode('registerOtp')
            await new Promise((resolve) => setTimeout(resolve, 10000));
            setResend(true)
        } else {
            toast(res?.data?.message)
            toast(res?.response?.data?.message)
            setLoad(false)
        }
    }

    const handleLoginChange = (e) => {
        const { name, value } = e.target;

        setRegisterParams({ name: '', email: '', mobile: '', password: '' })
        setLoginParams((prevState: object) => ({
            ...prevState,
            [name]: value
        }))
    }

    const handleRegisterChange = (e) => {
        const { name, value } = e.target;
        setLoginParams({ email: '', password: '' })

        setRegisterParams((prevState: object) => ({
            ...prevState,
            [name]: value
        }))
    }

    const isLogin = () => {
        if (emailRegex.test(loginParams.email) &&
            passwordRegex.test(loginParams.password)) {
            return true
        } else return false
    }

    const isForgot = () => {
        if (emailRegex.test(loginParams.email)) {
            return true
        } else return false;
    }

    const isRegister = () => {
        if (emailRegex.test(registerParms.email) &&
            passwordRegex.test(registerParms.password) &&
            mobileRegex.test(registerParms.mobile)) {
            return true
        } else return false;
    }

    const validateRegisterOtp = () => {
        if (mobileRegex.test(registerParms.mobile) && otp?.length == 6) {
            return true
        } else return false
    }


    const validateMobile = () => {
        if (mobileRegex.test(registerParms.mobile)) {
            return true
        } else return false
    }

    const handleOtpChnage = (e) => {
        setOpt(e.target.value);
    }

    const handleClickScroll = () => {
        const element = document.getElementById('login');
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <FromDivider className="pad-top" >
            <Column className="flex-1" id='login'>
                <div>Login via registered email</div>
                <Seperator>
                    <Label>Email*</Label>
                    <Input name='email' value={loginParams?.email} placeholder='Ex: you@yourdomain.com' onChange={handleLoginChange} />
                    {loginParams.email.includes('@') && !emailRegex.test(loginParams.email) ?
                        <Error className="red">Email must be valid.</Error> : ""}
                </Seperator>
                <Seperator>
                    <Label>Password*</Label>
                    <PasswordDiv>
                        <PasswordInput showPassword={showPassword} name='password' value={loginParams?.password} placeholder='Ex: Abcd@1234' onChange={handleLoginChange} />
                        <Span onClick={() => setShowPassword(!showPassword)}>
                            <Icon name={!showPassword && loginParams?.password ? "eye" : 'eyeStrike'} />
                        </Span>
                    </PasswordDiv>
                    {loginParams.password && !passwordRegex.test(loginParams.password) ?
                        <Error className="red">Password must contain a digit from 1 to 9, a lowercase, a uppercase, a special character, must be 8-16 characters long.</Error> : ""}
                </Seperator>
                <Hspace ht={10} />
                <OtpContainer className='center'>
                    <Column>
                    </Column>
                    <PrimaryButn name={loginLoad ? "Loading..." : "LOGIN"} isDisabled={!isLogin()} onClick={Login} />
                </OtpContainer>
            </Column>

            <Divider className="none">
                <Vline ht={"calc(25vh)"} />
                <OR>OR</OR>
                <Vline ht={"calc(25vh)"} />
            </Divider>
            <Row className="show">
                <Hline /><OR>OR</OR><Hline />
            </Row>

            <Column className="flex-1">
                <div>Register</div>
                {mode == 'register' &&
                    <>
                        <Seperator>
                            <Label>Name*</Label>
                            <Input name='name' value={registerParms?.name} placeholder='Ex: John Smith' onChange={handleRegisterChange} />
                        </Seperator>
                        <Seperator>
                            <Label>Email*</Label>
                            <Input name='email' value={registerParms?.email} placeholder='Ex: you@yourdomain.com' onChange={handleRegisterChange} />
                            {registerParms.email.includes('@') && !emailRegex.test(registerParms.email) ?
                                <Error className="red">Email must be valid.</Error> : ""}
                        </Seperator>
                        <Seperator>
                            <Label>Password*</Label>
                            <PasswordDiv>
                                <PasswordInput showPassword={showPassword} name='password' value={registerParms?.password} placeholder='Ex: Abcd@1234' onChange={handleRegisterChange} />
                                <Span onClick={() => setShowPassword(!showPassword)}>
                                    <Icon name={!showPassword && registerParms.password ? "eye" : 'eyeStrike'} />
                                </Span>
                            </PasswordDiv>
                            {registerParms.password && !passwordRegex.test(registerParms.password) ?
                                <Error className="red">Password must contain a digit from 1 to 9, a lowercase, a uppercase, a special character, must be 8-16 characters long.</Error> : ""}
                        </Seperator>
                    </>}
                {(mode == 'first' || mode == "registerOtp") &&
                    <Seperator>
                                                <Label>Mobile Number* {" "}<InfoIcon><Icon name='info' /><InfoText>You will receive update on whatsapp</InfoText></InfoIcon></Label>
                        <Input name='mobile' value={registerParms?.mobile} placeholder='Ex: 788######88' onChange={handleRegisterChange} />
                        {registerParms.mobile && !mobileRegex.test(registerParms.mobile) ?
                            <Error className="red">Mobile number must be valid.</Error> : ""}
                    </Seperator>
                }
                {(mode == "otp" || mode == "registerOtp") &&
                    <Seperator>
                        <Label>OTP</Label>
                        <Input name="otp" maxLength={6} value={otp} placeholder='eg: 123456' onChange={handleOtpChnage} />
                        {otp?.length < 6 ?
                            <Error className="red">OTP should contain 6 digits.</Error> : ""}
                    </Seperator>
                }
                {mode == "registerOtp" && <Row className="j-sb">
                    <Text>Didn't receive OTP?</Text>
                    <PrimaryButn padding={2} wd={80} isDisabled={!resend} name={load ? "Loading..." : "Resend"} onClick={ResendRegisterOtp} />
                </Row>}
                <Hspace ht={10} />
                {/* <PrimaryButn isDisabled={!isRegister()} name={registerLoad ? "Loading..." : 'Register'} onClick={Register} /> */}
                {mode == 'first' && <PrimaryButn name={registerLoad ? "Loading..." : "SEND OTP"} isDisabled={!validateMobile()} onClick={Register} />}
                {mode == 'registerOtp' && <PrimaryButn name={registerLoad ? "Loading..." : "SUBMIT"} isDisabled={!validateRegisterOtp()} onClick={SendRegisterOtp} />}
                {mode == 'register' && <PrimaryButn name={registerLoad ? "Loading..." : "Register"} isDisabled={!isRegister()} onClick={OnBoard} />}
            </Column>
        </FromDivider>
    )
}

export default CheckoutForm